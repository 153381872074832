import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

interface Props {
  text: string;
  className?: string;
  loading?: boolean;
}

export const NothingToDisplay: FC<Props> = observer(
  ({ text, className, loading, children }) => {
    return (
      <div className={`flex h-full items-center ${className}`}>
        <div className="flex w-full flex-col items-center gap-5">
          <div className="text-lg sm:text-2xl">
            {loading ? 'Loading..' : text}
          </div>
          {!loading && <div>{children}</div>}
        </div>
      </div>
    );
  },
);
