import { addHours, format } from 'date-fns';
import { TimeInterval } from '../../types/TimeInterval';

export const getIntervalLabel = (
  dateTime: string,
  interval: TimeInterval,
): string => {
  const date = new Date(dateTime);

  switch (interval) {
    case TimeInterval.Day:
      return `${format(date, 'HH:mm')}-${format(addHours(date, 1), 'HH:mm')}`;
    case TimeInterval.Month:
      return format(date, 'yyyy-MM-dd');
    case TimeInterval.Year:
      return format(date, 'MMMM');
  }
};
