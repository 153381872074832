import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerInvoiceFilterPicker } from '../../components/finance/filter';
import { NothingToDisplay, PageContainer, Pagination, SlideInPopup } from '../../components/general';
import { Button } from '../../components/general/buttons';
import { ActiveFilters } from '../../components/general/Filter';
import {
  Table,
  TableHeaderCell,
  TableRow,
} from '../../components/general/Table';
import { useStore } from '../../hooks';
import { ListIcon } from '../../icons';
import { SimpleCustomerInvoiceInstance } from '../../models/finance';
import { numberToMoney } from '../../utils/numberToMoney';

export const LedgerPage = observer(() => {
  const { customerInvoiceStore, uiStore } = useStore();
  const navigate = useNavigate();

  const {
    pagedInvoices: invoices,
    pageInfo,
    currentPage,
  } = customerInvoiceStore;

  const setCurrentPage = (page: number) => {
    const searchParams = new URLSearchParams(/*query*/);

    if (page === 1) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', String(page));
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const isAscending = false;
  const tableHeaders = [
    {
      title: 'Type',
      className: '',
    },
    {
      title: 'Invoice #',
      className: '',
    },
    {
      title: 'Relation #',
      className: '',
    },
    {
      title: 'Amount',
      className: '',
    },
    {
      title: 'Customer #',
      className: '',
    }
  ];

  const getTableRowItems = (invoice: SimpleCustomerInvoiceInstance) => {
    return [
      {
        value: invoice.amount >= 0 ? 'Invoice' : 'Credit',
      },
      {
        value: invoice.documentNumber,
      },
      {
        value: invoice.relationOrgNo ?? '',
      },
      {
        value: numberToMoney(invoice.amount, invoice.currency),
      },
      {
        value: invoice.customerNumber,
      }
    ];
  };

  useEffect(() => {
    customerInvoiceStore.handleCustomerInvoiceSearchChange(location.search);
  }, [location.search]);

  return (
    <PageContainer
      title="Ledger"
      titleIcon={<ListIcon className="w-12" />}
      useBackground={false}
    >
      {invoices && invoices.length !== 0 ? (

        <>
          <ActiveFilters
            filters={customerInvoiceStore.invoiceFilters}
            onFilterClick={uiStore.toggleIsFilterPickerOpen}
          />
          {<SlideInPopup
            isOpen={uiStore.isFilterPickerOpen}
            onToggle={uiStore.toggleIsFilterPickerOpen}
            title="Filters"
          >
            <CustomerInvoiceFilterPicker />
          </SlideInPopup>}
          <Table
            headerItems={tableHeaders}
            renderHeaderCell={(item, index) => (
              <TableHeaderCell
                key={index}
                className={item.className}
                title={item.title || ''}
                isAscending={isAscending}
              />
            )}
            items={invoices as SimpleCustomerInvoiceInstance[]}
            renderRow={(item, index) => (
              <TableRow
                key={index}
                items={getTableRowItems(item)}
                index={index}
              />
            )}
          />
          {pageInfo && pageInfo.totalPages > 1 && (
            <Pagination
              className="mt-5"
              page={currentPage}
              numPages={pageInfo.totalPages}
              onChange={setCurrentPage}
            />
          )}
        </>) : (
        <NothingToDisplay
          loading={invoices === null}
          className={`mt-5 sm:mt-20 ${invoices === null && 'animate-pulse'}`}
          text={'There are currently no ledger data to display'}
        >
          <Button onClick={() => navigate('/integrations/create')}>
            {"Let's get started!"}
          </Button>
        </NothingToDisplay>
      )}
    </PageContainer>
  );
});
