import { isValid } from 'date-fns';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusIcon, ThickCrossIcon } from '../../../icons';
import { Button } from '../buttons/Button';
import { DatePicker } from '../DatePicker';
import { InputWithError } from '../InputWithError';
import { Select } from '../Select';

export enum FilterInputType {
  Date = 'date',
  Select = 'select',
  Text = 'text',
}

export interface FilterObj {
  name: string;
  value: string;
}

export interface FilterTypeObj extends FilterObj {
  inputType?: FilterInputType;
}

export interface Filter {
  filterType: FilterTypeObj | null;
  filterValue: FilterObj | null;
}

interface FilterPickerState {
  search: string;
  setSearch: (search: string) => void;
  activeFilters: Filter[];
  addFilter: (filter: Filter) => void;
  updateFilterType: (index: number, type: FilterTypeObj | null) => void;
  updateFilterValue: (index: number, value: FilterObj) => void;
  removeFilter: (index: number) => void;
  clearFilters: () => void;
  applyFilters: () => void;
}

interface FilterPickerProps {
  filters: Filter[];
  filterTypes: string[];
  getFilterValueItems(filter: string): FilterObj[];
  filterTypeItems: FilterTypeObj[];
}

export const FilterPicker: FC<FilterPickerProps> = observer(
  ({ filterTypes, filters, getFilterValueItems, filterTypeItems }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const state = useLocalObservable<FilterPickerState>(() => ({
      search: location.search,
      setSearch(search) {
        state.search = search;
      },
      activeFilters: [],
      addFilter(filter) {
        state.activeFilters.push(filter);
      },
      updateFilterType(index, type) {
        const filter = state.activeFilters[index];
        filter.filterType = type;
        filter.filterValue = null;
      },
      updateFilterValue(index, value) {
        state.activeFilters[index].filterValue = value;
      },
      removeFilter(index) {
        state.activeFilters.splice(index, 1);
      },
      clearFilters() {
        state.activeFilters = [];
      },
      applyFilters() {
        const searchParams = new URLSearchParams(state.search);
        searchParams.delete('page');

        filterTypes.forEach((filter) => {
          if (searchParams.has(filter)) {
            searchParams.delete(filter);
          }
        });

        state.activeFilters.forEach((filter) => {
          if (filter.filterType && filter.filterValue) {
            searchParams.append(
              filter.filterType.value,
              filter.filterValue.value,
            );
          }
        });

        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      },
    }));

    useLayoutEffect(() => {
      state.clearFilters();
      state.setSearch(location.search);

      if (filters && filters?.length < 1) {
        state.addFilter({ filterType: null, filterValue: null });
        return;
      }

      filters?.forEach((filter) => {
        state.addFilter(filter);
      });
    }, [filters, location.search]);

    return (
      <div className="w-xs text-labels sm:w-sm md:w-md">
        <FilterRows
          filters={state.activeFilters}
          filterTypeItems={filterTypeItems}
          getFilterValueItems={getFilterValueItems}
          onSelectedTypeChange={state.updateFilterType}
          onSelectedValueChange={state.updateFilterValue}
          onRemove={state.removeFilter}
        />
        <Button
          className="mt-2 w-full"
          onClick={() =>
            state.addFilter({ filterType: null, filterValue: null })
          }
        >
          <PlusIcon className="w-5" />
        </Button>
        <Button className="mt-8 w-full bg-success" onClick={state.applyFilters}>
          Apply filters
        </Button>
      </div>
    );
  },
);

interface FilterRowsProps {
  filters: Filter[];
  filterTypeItems: FilterTypeObj[];
  onSelectedTypeChange: (
    index: number,
    filterType: FilterTypeObj | null,
  ) => void;
  getFilterValueItems: (filter: string) => FilterObj[];
  onSelectedValueChange: (index: number, filterValue: FilterObj) => void;
  onRemove?: (index: number) => void;
}

const getCurrentDateRange = (datesString: string | undefined) => {
  if (!datesString || typeof datesString !== 'string') return null;

  const dates = datesString.split('/');

  const [startDateString, endDateString] = dates;
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  if (!isValid(startDate) || !isValid(endDate)) return null;

  return [new Date(startDate), new Date(endDate)];
};

const FilterRows: FC<FilterRowsProps> = observer(
  ({
    filters,
    filterTypeItems,
    onSelectedTypeChange,
    getFilterValueItems,
    onSelectedValueChange,
    onRemove,
  }) => {
    const handleDateChange = (index: number, dates: [Date, Date]) => {
      const [startDate, endDate] = dates;

      if (!startDate || !endDate) return null;

      const datesString = `${startDate.toLocaleDateString(
        'sv-SE',
      )}/${endDate.toLocaleDateString('sv-SE')}`;

      onSelectedValueChange(index, {
        name: FilterInputType.Date,
        value: datesString,
      });
    };

    return (
      <div className="space-y-2">
        {filters.map((filter, index) => {
          const currentDates = getCurrentDateRange(filter.filterValue?.value);
          const typeOfInput = filter.filterType?.inputType;

          return (
            <div
              key={`${index}-${filter.filterType?.name}-${filter.filterType?.value}`}
              className="relative flex items-center space-x-2"
            >
              <div className="flex-1">
                <Select
                  placeholder="Select filter"
                  name="filterType"
                  items={filterTypeItems.map((item) => ({
                    id: item.value,
                    name: item.name,
                  }))}
                  value={filter.filterType?.name || ''}
                  onChange={(type) => {
                    onSelectedTypeChange(
                      index,
                      filterTypeItems.find((item) => item.value === type.id) ||
                        null,
                    );
                  }}
                />
              </div>
              <div className="rounded-md border border-transparent bg-blocks p-3 text-sm">
                is
              </div>
              <div className="flex-1">
                {typeOfInput === FilterInputType.Date ? (
                  <DatePicker
                    className="w-[inherit]"
                    onChange={(dates) => handleDateChange(index, dates)}
                    startDate={currentDates ? currentDates[0] : null}
                    endDate={currentDates ? currentDates[1] : null}
                  />
                ) : typeOfInput === FilterInputType.Text ? (
                  <InputWithError
                    className="w-full rounded-md bg-blocks p-3"
                    defaultValue={filter.filterValue?.value}
                    onBlur={(event) =>
                      event.target.value &&
                      onSelectedValueChange(index, {
                        name: filter.filterType?.name || '',
                        value: event.target.value,
                      })
                    }
                  />
                ) : (
                  <Select
                    disabled={!filter.filterType}
                    placeholder="Select"
                    name="filterValue"
                    items={getFilterValueItems(
                      filter.filterType?.value || '',
                    ).map((item) => ({
                      id: item.value,
                      name: item.name,
                    }))}
                    value={filter.filterValue?.name}
                    onChange={(value) =>
                      onSelectedValueChange(index, {
                        name: value.name,
                        value: value.id,
                      })
                    }
                  />
                )}
              </div>
              {onRemove && (
                <button
                  className="absolute -right-7 text-titles"
                  onClick={() => onRemove(index)}
                >
                  <ThickCrossIcon className="w-5" />
                </button>
              )}
            </div>
          );
        })}
      </div>
    );
  },
);
