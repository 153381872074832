import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { CloseIcon, PauseIcon, PlayIcon, RetryIcon } from '../../icons';

const statusColorClassMapper = {
  Pending: 'text-warning border-warning',
  Revoked: 'text-error border-error',
  Declined: 'text-error border-error',
  Active: 'text-success border-success',
  Paused: 'text-error border-error',
  Archived: 'text-error border-error', 
};

type RelationStatusButton = {
  status: string;
  onClick: () => void;
  className?: string;
};

const getStatusIcons = (status: string) => {
  switch (status) {
    case 'Pending': return (
      <>
        <RetryIcon className="h-6 text-warning" />
        <CloseIcon className="h-8 text-error" />
      </>);
    case 'Revoked':
    case 'Declined':
    case 'Archived': return (
      <>
        <RetryIcon className="h-6 text-warning" />
        <CloseIcon className="h-8 text-error" />
      </>);
    case 'Active': return (
      <>
        <PauseIcon className="h-6 w-6 text-warning" />
        <CloseIcon className="-ml-[2px] h-8 text-error" />
      </>);
    case 'Paused': return (
      <>
        <PlayIcon className="h-5 w-5 text-success" />
        <CloseIcon className="-ml-[1px] h-8 text-error" />
      </>);
    return null;
  }
};

export const RelationStatusButton: FC<RelationStatusButton> = observer(
  ({ status, onClick, className }) => {
    return (
      <button
        id="relation-status-button"
        className={`m-2 grid min-h-[40px] w-40 grid-cols-2 items-center rounded-lg border ${statusColorClassMapper[status]} ${className}`}
        onClick={onClick}
      >
        <div className="">{status}</div>
        <div className="flex items-center justify-self-end">
          {getStatusIcons(status)}
        </div>
      </button>
    );
  },
);
