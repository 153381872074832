import { MostActiveClientsData } from '../types';
import { sleep } from '../utils/sleep';

export const fetchMostActiveClientsData_demo = async (
  start: string,
  end: string,
): Promise<MostActiveClientsData> => {
  await sleep();

  return {
    volume: [
      { name: 'Customer 1', count: 505 },
      { name: 'Customer 2', count: 355 },
      { name: 'Customer 3', count: 225 },
      { name: 'Customer 4', count: 221 },
      { name: 'Customer 5', count: 198 },
    ],
    money: [
      { name: 'Customer 1', count: 505000 },
      { name: 'Customer 2', count: 355000 },
      { name: 'Customer 3', count: 225000 },
      { name: 'Customer 4', count: 221000 },
      { name: 'Customer 5', count: 198000 },
    ],
  };
};
