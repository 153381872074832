import React, { FC, memo, SVGProps } from 'react';

export const UploadImageIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.528 5.05945V8.11796H24.9403V12.7057H21.8817V8.11796H17.294V5.05945H21.8817V0.47168H24.9403V5.05945H29.528ZM16.5294 15.7642C17.1379 15.764 17.7215 15.5221 18.1517 15.0916C18.5819 14.6611 18.8234 14.0774 18.8232 13.4688C18.823 12.8602 18.5811 12.2767 18.1506 11.8465C17.7201 11.4163 17.1364 11.1747 16.5278 11.1749C16.2265 11.175 15.9281 11.2345 15.6498 11.3499C15.3714 11.4653 15.1185 11.6344 14.9055 11.8476C14.6925 12.0607 14.5235 12.3137 14.4083 12.5922C14.2931 12.8706 14.2338 13.169 14.2339 13.4704C14.234 13.7717 14.2935 14.0701 14.4089 14.3484C14.5243 14.6268 14.6934 14.8797 14.9066 15.0927C15.1197 15.3057 15.3727 15.4746 15.6512 15.5899C15.9296 15.7051 16.228 15.7643 16.5294 15.7642ZM21.8817 20.7098L21.0972 19.8382C20.8104 19.5188 20.4595 19.2634 20.0675 19.0885C19.6754 18.9137 19.251 18.8233 18.8217 18.8233C18.3924 18.8233 17.968 18.9137 17.5759 19.0885C17.1839 19.2634 16.833 19.5188 16.5462 19.8382L15.543 20.9561L8.11845 12.7057L3.53068 17.8027V8.11796H14.2355V5.05945H3.53068C2.71951 5.05945 1.94157 5.38168 1.36798 5.95526C0.794403 6.52885 0.472168 7.30679 0.472168 8.11796V26.469C0.472168 27.2802 0.794403 28.0581 1.36798 28.6317C1.94157 29.2053 2.71951 29.5275 3.53068 29.5275H21.8817C22.6929 29.5275 23.4709 29.2053 24.0444 28.6317C24.618 28.0581 24.9403 27.2802 24.9403 26.469V15.7642H21.8817V20.7098Z"
      fill="currentcolor"
    />
  </svg>
));
