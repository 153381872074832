export enum DocumentFlowEntryStatus {
  Failed = 'failed',
  InProgress = 'inprogress',
  Delivered = 'delivered',
}

export type DocumentFlowEntry = {
  createdAt: string;
  pipelineStatus: DocumentFlowEntryStatus;
};

export type DocumentFlowData = {
  currentPeriod: number;
  prevPeriod: number;
  documents: DocumentFlowEntry[];
};
