import { IntegrationEntityStatus, IntegrationType } from './types';
import { CustomerInvoiceType } from './types/finance';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const INTEGRATIONS_API_URL = process.env.INTEGRATIONS_API_URL;
export const FINANCE_API_URL = process.env.FINANCE_API_URL;
export const IDENTITYSERVER_URL = process.env.IDENTITYSERVER_URL;
export const FORTNOX_CLIENT_ID = process.env.FORTNOX_CLIENT_ID;
export const FORTNOX_REDIRECT_URL = process.env.FORTNOX_REDIRECT_URL;
export const FORTNOX_SCOPE = process.env.FORTNOX_SCOPE;

export const INVOICES_PAGE_SIZE = 20;
export const REQUEST_DEBOUNCE_WAIT = 0; // TODO: Investigate more, previous value 300 made everything seem laggy/slow

export const INTEGRATION_TYPE_TEXTS = {
  [IntegrationType.CustomerInvoice]: 'Customer invoice',
  [IntegrationType.SupplierInvoice]: 'Supplier invoice',
  [IntegrationType.Payment]: 'Payment',
};

export const INTEGRATION_ENTITY_STATUS_TEXTS = {
  [IntegrationEntityStatus.Ready]: 'Ready',
  [IntegrationEntityStatus.Running]: 'Running',
  [IntegrationEntityStatus.Successful]: 'Successful',
  [IntegrationEntityStatus.Error]: 'Error',
};

export const CUSTOMER_INVOICE_TYPE_TEXTS = {
  [CustomerInvoiceType.Debit]: 'Invoice',
  [CustomerInvoiceType.Credit]: 'Credit',
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
