import React, { FC } from 'react';

export const Card: FC<{
  icon: JSX.Element;
  title: string;
  actions?: JSX.Element;
}> = ({ icon, title, actions, children }) => (
  <div className={'flex h-full flex-grow flex-col rounded-md bg-blocks p-7'}>
    <div className="flex items-center text-titles">
      <span className="flex-shrink-0">{icon}</span>
      <span className="ml-2 text-xl font-bold">{title}</span>
    </div>
    {actions && <span className="ml-auto text-xl font-bold">{actions}</span>}
    <div className="flex flex-grow flex-col">{children}</div>
  </div>
);
