import { applySnapshot, flow, getRoot, types as t } from 'mobx-state-tree';
import {
  CreateIntegrationCommandInstance,
  Integration,
  IntegrationInstance,
  RootStore,
} from '.';
import { INTEGRATIONS_API_URL } from '../constants';
import { withRequest } from '../extensions';
import { JsonPatchType } from '../types';

const IntegrationArray = t.array(Integration);

export const IntegrationStore = t
  .model('IntegrationStore', {
    integrations: t.maybeNull(IntegrationArray),
  })
  .views((self) => {
    return {
      get activeIntegrations() {
        return self.integrations?.filter(
          (x) => x.state !== 'Archived' && x.internalState !== 'Archived',
        );
      },
    };
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchIntegrations: flow(function* () {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + 'api/integrations/',
        });

        if (self.integrations === null) {
          self.integrations = IntegrationArray.create();
        }

        self.integrations.replace(data);
      }),
      createIntegration: flow(function* (
        createIntegrationCommand: CreateIntegrationCommandInstance,
      ) {
        const { data } = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/integrations/',
          data: { ...createIntegrationCommand },
        });

        self.integrations!.push(data);
      }),
      editIntegration: flow(function* (
        integration: IntegrationInstance,
        updateableFields: JsonPatchType[],
      ) {
        try {
          yield request({
            method: 'PATCH',
            url: INTEGRATIONS_API_URL + `api/integrations/${integration.id}`,
            data: updateableFields,
          });
        } catch (error) {
          const { uiStore } = getRoot<typeof RootStore>(self);
          uiStore.setError('Something went wrong!', 'Please try again');
        }
      }),
      reset() {
        applySnapshot(self, {
          integrations: null,
        });
      },
    };
  });
