import { flow, getRoot, Instance, types as t } from 'mobx-state-tree';
import { IntegrationTrigger } from './IntegrationTrigger';
import { IntegrationPipelineAction } from './IntegrationPipelineAction';
import { IntegrationTriggerAction } from './IntegrationTriggerAction';
import { INTEGRATIONS_API_URL } from '../constants';
import { withRequest } from '../extensions';
import { IntegrationState, IsoDate } from '../types';

import { RootStore } from './RootStore';

export const SftpSourceSystemConfig = t.model({
  typeDescriminator: t.maybeNull(t.integer),
  downloadPath: t.string,
  processedPath: t.string,
});

export const SftpTargetSystemConfig = t.model({
  typeDescriminator: t.maybeNull(t.integer),
  uploadPath: t.string,
});

export const CreateIntegrationCommand = t.model({
  name: t.string,
  notifyEmailAddress: t.string,
  sourceSystemType: t.string,
  sourceSystemId: t.string,
  targetSystemType: t.string,
  targetSystemId: t.string,
  entityType: t.string,
  disabled: t.boolean,
  sourceSystemInputs: t.maybeNull(t.frozen()),
  targetSystemInputs: t.maybeNull(t.frozen()),
});

export type CreateIntegrationCommandInstance = Instance<
  typeof CreateIntegrationCommand
>;

export const Integration = t
  .model({
    id: t.string,
    createdAt: IsoDate,
    createdBy: t.string,
    modifiedAt: IsoDate,
    modifiedBy: t.string,
    name: t.string,
    notifyEmailAddress: t.maybeNull(t.string),
    triggers: t.array(t.frozen(IntegrationTrigger)),
    triggerAction: t.maybeNull(IntegrationTriggerAction),
    pipelineActions: t.array(IntegrationPipelineAction),
    status: t.string,
    state: t.string,
    internalState: t.string,
    executeStartTimestamp: t.maybeNull(IsoDate),
    executeStopTimestamp: t.maybeNull(IsoDate),
    // metadata: t.array(t.frozen()),
    metadata: t.maybeNull(
      t.frozen(
        t.union(
          t.array(t.frozen())
        )
      )
    ),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    return {
      toggleState: flow(function* () {
        if (self.state != 'Enabled' && self.state != 'Disabled') {
          return;
        }

        const oldState = self.state;
        self.state = self.state == 'Enabled' ? 'Disabled' : 'Enabled';

        try {
          yield request({
            method: 'PATCH',
            url: INTEGRATIONS_API_URL + `api/integrations/${self.id}`,
            data: [
              {
                op: 'replace',
                path: '/state',
                value: `${self.state}`,
              },
            ],
          });
        } catch (error) {
          self.state = oldState;
          const { uiStore } = getRoot<typeof RootStore>(self);
          uiStore.setError('Something went wrong!', 'Please try again');
        }
      }),
      isActive: () => {
        return (
          self.state === IntegrationState.Enabled.toString() &&
          self.internalState === IntegrationState.Enabled.toString()
        );
      },
      isArchived: () => {
        return (
          self.state === IntegrationState.Archived.toString() ||
          self.internalState === IntegrationState.Archived.toString()
        );
      },
    };
  });

export type IntegrationInstance = Instance<typeof Integration>;

export type PatchableIntegrationInstance = Instance<
  Pick<IntegrationInstance, 'name' | 'notifyEmailAddress' | 'state' | 'metadata'>
>;
