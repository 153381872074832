import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../hooks/useStore';
import { BjornLundenIcon, ExternalSystemIcon } from '../../../icons';
import { ExternalSystemType } from '../../../types';
import { ExternalSystemIconMapper, FadeInDiv, InputWithError, Label } from '../../general';
import { Button } from '../../general/buttons';

type BjornlundenSetupProps = {
  systemId?: string;
  onFormSubmit?: (id: string) => void;
};

type FormData = {
  clientId: string;
  clientSecret: string;
  companyPublicKey: string;
};

export const BjornlundenSetup: FC<BjornlundenSetupProps> = observer(
  ({ systemId, onFormSubmit }) => {
    const { externalSystemStore } = useStore();
    const state = useLocalObservable(() => ({
      isSubmiting: false,
      setIsSubmitting(isSubmiting: boolean) {
        state.isSubmiting = isSubmiting;
      },
      hasFormError: false,
      setHasFormError(hasFormError: boolean) {
        state.hasFormError = hasFormError;
      },
    }));

    const isEditMode: boolean = systemId !== undefined;

    useEffect(() => {
      if (isEditMode) {
        state.setIsSubmitting(true);
        try {
          const systemSettings =
            externalSystemStore.getBjornlundenSystemSetting(systemId!);
          systemSettings.then((data: any) => {
            if (data) setFormValues(data);
          });
        } catch (error) {
          state.setHasFormError(true);
        } finally {
          state.setIsSubmitting(false);
        }
      }
    }, []);

    const setFormValues = (data: any) => {
      setValue('clientId', data.clientId);
      setValue('companyPublicKey', data.companyPublicKey);
    };

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<FormData>();

    const onSubmit = handleSubmit(
      async ({ clientId, clientSecret, companyPublicKey }) => {
        if (state.isSubmiting) return;

        state.setIsSubmitting(true);
        state.setHasFormError(false);
        isEditMode
          ? updateCurrentSystem(
            systemId,
            clientId,
            clientSecret,
            companyPublicKey,
          )
          : addNewSystem(clientId, clientSecret, companyPublicKey);
      },
    );

    const addNewSystem = async (clientId, clientSecret, companyPublicKey) => {
      try {
        const id = await externalSystemStore.addBjornlundenSystem({
          clientId,
          clientSecret,
          companyPublicKey,
        });
        onFormSubmit && onFormSubmit(id);
      } catch (error) {
        state.setHasFormError(true);
      } finally {
        state.setIsSubmitting(false);
      }
    };

    const updateCurrentSystem = async (
      id,
      clientId,
      clientSecret,
      companyPublicKey,
    ) => {
      try {
        await externalSystemStore.updateBjornlundenSystem(id, {
          clientId,
          clientSecret,
          companyPublicKey,
        });
        if (onFormSubmit) onFormSubmit(id);
      } catch (error) {
        state.setHasFormError(true);
      } finally {
        state.setIsSubmitting(false);
      }
    };

    return (
      <FadeInDiv>
        <div className='mb-4 flex flex-col items-center justify-center'>
          <BjornLundenIcon className='h-12' />
          Björn Lundén
        </div>

        <form onSubmit={onSubmit}>
          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Client Id
            </Label>
            <InputWithError
              type="text"
              placeholder="Client Id"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('clientId', {
                required: true,
                pattern: {
                  value:
                    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                  message:
                    'Client Id must have xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx form',
                },
              })}
              error={errors.clientId?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Company Id
            </Label>
            <InputWithError
              type="text"
              placeholder="Company Id"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('companyPublicKey', {
                required: true,
                pattern: {
                  value:
                    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                  message:
                    'Company Id must have xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx form',
                },
              })}
              error={errors.companyPublicKey?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Client Secret
            </Label>
            <InputWithError
              type="clientSecret"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              placeholder="**********"
              {...register('clientSecret', {
                required: true,
                pattern: {
                  value:
                    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                  message:
                    'Client secret must have xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx form',
                },
              })}
              error={errors.clientSecret?.message}
            />
          </div>

          <div>{state.isSubmiting && <>Submitting..</>}</div>
          <div className="mb-5">
            <Button
              onClick={onSubmit}
              className="mt-10 w-full bg-titles text-xl font-bold"
              isLoading={state.isSubmiting}
            >
              <span className="font-semibold">
                {isEditMode ? 'Update' : 'Add'}
              </span>
            </Button>
          </div>
        </form>
      </FadeInDiv>
    );
  },
);
