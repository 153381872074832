import React, { FC, memo, SVGProps } from 'react';

export const OrganizationIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (

  <svg viewBox="0 0 22 23" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
    <path d="M5.98901 11.989H16.0112V13.8712H16.989V11.0112H11.489V9.05566H10.5112V11.0112H5.01123V13.8712H5.98901V11.989Z" fill="currentColor"/>
    <path d="M8.5555 14.5557H2.44439C2.12024 14.5557 1.80936 14.6844 1.58015 14.9136C1.35094 15.1429 1.22217 15.4537 1.22217 15.7779V19.4446C1.22217 19.7687 1.35094 20.0796 1.58015 20.3088C1.80936 20.538 2.12024 20.6668 2.44439 20.6668H8.5555C8.87965 20.6668 9.19053 20.538 9.41974 20.3088C9.64895 20.0796 9.77772 19.7687 9.77772 19.4446V15.7779C9.77772 15.4537 9.64895 15.1429 9.41974 14.9136C9.19053 14.6844 8.87965 14.5557 8.5555 14.5557ZM2.44439 19.4446V15.7779H8.5555V19.4446H2.44439Z" fill="currentColor"/>
    <path d="M19.5555 14.5557H13.4444C13.1202 14.5557 12.8094 14.6844 12.5801 14.9136C12.3509 15.1429 12.2222 15.4537 12.2222 15.7779V19.4446C12.2222 19.7687 12.3509 20.0796 12.5801 20.3088C12.8094 20.538 13.1202 20.6668 13.4444 20.6668H19.5555C19.8797 20.6668 20.1905 20.538 20.4197 20.3088C20.649 20.0796 20.7777 19.7687 20.7777 19.4446V15.7779C20.7777 15.4537 20.649 15.1429 20.4197 14.9136C20.1905 14.6844 19.8797 14.5557 19.5555 14.5557ZM13.4444 19.4446V15.7779H19.5555V19.4446H13.4444Z" fill="currentColor"/>
    <path d="M7.94439 8.44412H14.0555C14.3797 8.44412 14.6905 8.31535 14.9197 8.08614C15.149 7.85693 15.2777 7.54605 15.2777 7.2219V3.55523C15.2777 3.23108 15.149 2.9202 14.9197 2.69099C14.6905 2.46178 14.3797 2.33301 14.0555 2.33301H7.94439C7.62024 2.33301 7.30936 2.46178 7.08015 2.69099C6.85094 2.9202 6.72217 3.23108 6.72217 3.55523V7.2219C6.72217 7.54605 6.85094 7.85693 7.08015 8.08614C7.30936 8.31535 7.62024 8.44412 7.94439 8.44412ZM7.94439 3.55523H14.0555V7.2219H7.94439V3.55523Z" fill="currentColor"/>
  </svg>
));
