import debounce from 'lodash.debounce';
import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { FINANCE_API_URL, REQUEST_DEBOUNCE_WAIT } from '../../constants';
import { withRequest } from '../../extensions';
import { CustomerInvoiceFilter } from '../../types/finance';
import { PageInstance, Paging } from '../Paging';
import { CustomerInvoiceFilterModel } from './CustomerInvoiceFilter';
import { SimpleCustomerInvoice } from './SimpleCustomerInvoice';

export const CustomerInvoiceStore = t
  .model('CustomerInvoiceStore', {
    invoiceFilters: t.array(CustomerInvoiceFilterModel),
    invoiceSearchParameters: '',
    pagedInvoices: t.maybeNull(t.array(SimpleCustomerInvoice)),
    pageInfo: t.maybeNull(Paging),
  })
  .views((self) => {
    return {
      get invoiceSearchQuery() {
        return new URLSearchParams(self.invoiceSearchParameters);
      }
    }
  })
  .views((self) => {
    return {
      get currentPage() {
        const pageParam = self.invoiceSearchQuery.get('page');
        if (!pageParam) {
          return 1;
        }
        return Math.max(Number(pageParam), 1);
      },
      get invoiceSearchParams() {
        const searchParams = new URLSearchParams();

        self.invoiceFilters.forEach((filter) => {
          const value =
            typeof filter.value === 'string'
              ? filter.value
              : filter.value?.id.toString();

          if (!value) return;

            searchParams.append(filter.name, value);
        });

        return searchParams;
      }

    };
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    return {
      fetchCustomerInvoices: flow(function* () {
        const params = new URLSearchParams();
        params.append('page', String(self.currentPage));
        params.append('pageSize', String(20));
        for (const [key, value] of self.invoiceSearchParams.entries()) {
          params.append(key, value);
        }

        const { data } = yield request({
          method: 'GET',
          url: `${FINANCE_API_URL}api/CustomerInvoices`,
          params,
        });

        const pageResult: PageInstance = {
          currentPage: data.currentPage,
          totalPages: data.totalPages,
          pageSize: data.pageSize,
          totalItems: data.totalItems,
        };

        self.pagedInvoices = data.items;
        self.pageInfo = pageResult;
      }),
      reset() {
        applySnapshot(self, {
          invoiceSearchParameters: '',
          pagedInvoices: null,
          pageInfo: null
        });
      }
    };
  })
  .actions((self) => {
    return {
      setCustomerInvoiceFilters() {
        self.invoiceFilters.clear();

        for (const [key, value] of self.invoiceSearchQuery) {
          if (!Object.values(CustomerInvoiceFilter).includes(key as CustomerInvoiceFilter)) {
            continue;
          }

          self.invoiceFilters.push(
            CustomerInvoiceFilterModel.create({ name: key as CustomerInvoiceFilter, value }),
          );
        }
      },
    };
  })
  .actions((self) => {
    // We debounce invoices requests that result from the user
    // navigating as to not overwhelm the network when navigating
    // back and forth quickly.
    const debouncedFetchCustomerInvoices = debounce(
      self.fetchCustomerInvoices,
      REQUEST_DEBOUNCE_WAIT,
    );

    return {
      handleCustomerInvoiceSearchChange(search: string) {
        self.invoiceSearchParameters = search;
        self.setCustomerInvoiceFilters();
        debouncedFetchCustomerInvoices();
      },
    };
  });