import { applySnapshot, flow, getRoot, types as t } from 'mobx-state-tree';
import { IDENTITYSERVER_URL } from '../constants';
import { withRequest } from '../extensions';
import { RootStore } from './RootStore';
import { TenantInfoInstance } from './TenantInfo';

export const TenantStore = t
  .model('TenantStore', {
    emailAddress: t.maybeNull(t.string),
    name: t.maybeNull(t.string),
    logoUrl: t.maybeNull(t.string),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchCurrentTenant: flow(function* () {
        const { data } = yield request({
          method: 'GET',
          url: IDENTITYSERVER_URL + 'tenants/current',
        });
        const response = data as TenantInfoInstance;

        self.name = response.name;
        self.emailAddress = response.emailAddress;
        self.logoUrl = response.logoUrl;
      }),
      updateTenant: flow(function* (email: string, imageBlob: Blob) {
        const formData = new FormData();

        if (imageBlob) {
          formData.append('logo', imageBlob, 'tenant_logo.png');
        }
        if (email) {
          formData.append('emailAddress', email);
        }

        try {
          const { data } = yield request({
            method: 'POST',
            url: IDENTITYSERVER_URL + 'tenants/update',
            data: formData,
          });

          self.emailAddress = data.result.emailAddress;
          self.logoUrl = data.result.logoUrl;
        } catch (error) {
          const { uiStore } = getRoot<typeof RootStore>(self);
          uiStore.setError('Something went wrong!', 'Please try again');
        }
      }),
      reset() {
        applySnapshot(self, {});
      },
    };
  })
  .views((self) => ({
    get currentTenantInfo() {
      return {
        name: self.name,
        emailAddress: self.emailAddress,
        logoUrl: self.logoUrl,
      };
    },
  }));
