import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FadeInDiv, Header } from './src/components/general';
import { useStore } from './src/hooks/useStore';
import {
  LoggedInPage,
  LoginPage,
  NewPasswordPage,
  RecoverPasswordPage,
  SignUpPage,
  SignUpWithInvitationPage,
} from './src/pages/general';

export default observer(function App() {
  const { authStore, settingsStore } = useStore();
  // Apply dark mode setting from local storage
  settingsStore.darkMode
    ? settingsStore.setDarkMode()
    : settingsStore.removeDarkMode();

  return (
    <div id="app" className="h-screen w-screen bg-background text-labels">
      {!authStore.isLoggedIn ? (
        <FadeInDiv className="relative flex h-screen w-screen flex-1 overflow-hidden ">
          <div className="flex flex-1 flex-col overflow-y-auto">
            <Header />
            <div className="mx-auto mt-32 flex h-fit px-4">
              <Routes>
                <Route
                  path="/forgot-password"
                  element={<RecoverPasswordPage />}
                />
                <Route path="/new-password" element={<NewPasswordPage />} />
                <Route path="/register" element={<SignUpPage />} />
                <Route
                  path="/activate-invitation"
                  element={<SignUpWithInvitationPage />}
                />
                <Route path="*" element={<LoginPage />} />
              </Routes>
            </div>
          </div>
        </FadeInDiv>
      ) : (
        <LoggedInPage />
      )}
    </div>
  );
});
