import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from './buttons';
import { InputWithError } from './InputWithError';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export interface AboutOrganizationFormData {
  tenantName: string;
  organizationNumber: string;
}

interface AboutOrganizationFormProps {
  onSubmit: (data: AboutOrganizationFormData) => void;
  isLoading: boolean;
}

export const AboutOrganizationForm: FC<AboutOrganizationFormProps> = observer(
  ({ onSubmit, isLoading }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<AboutOrganizationFormData>();
    const { t } = useTranslation();

    return (
      <form
        className="flex w-full flex-1 flex-col gap-5"
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <InputWithError
          required
          label="Company"
          placeholder="Company name"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('tenantName', {
            required: 'Fill in this field',
          })}
          error={errors.tenantName?.message}
        />
        <InputWithError
          required
          label="Organization number"
          placeholder="Organization number"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('organizationNumber', {
            required: 'Fill in this field',
          })}
          error={errors.organizationNumber?.message}
        />
        <Button className="mt-6 w-full" disabled={isLoading}>
          {t('Sign up')}
        </Button>
      </form>
    );
  },
);
