import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CheckmarkIcon } from '../../icons';
import { Button } from './buttons';
import { FormError } from './FormError';
import { InputWithError } from './InputWithError';
import { Label } from './Label';

export interface LoginCredentials {
  email: string;
  password: string;
  repeatPassword: string;
  agreedToTermsAndPolicies: boolean;
}

interface LoginCredentialsFormProps {
  onSubmit: (data: LoginCredentials) => void;
  isLoading: boolean;
  defaultEmail?: { value: string; readonly: boolean };
}

const passwordValidation = {
  hasSpecialChar: (value) =>
    /(?=.*[!@#$%^&*])/.test(value) ||
    'Must have at least one non alphanumeric character',
  hasUppercaseLetter: (value) =>
    /(?=.*[A-Z])/.test(value) || 'Must have at least one uppercase letter',
  hasDigit: (value) =>
    /(?=.*[0-9])/.test(value) || 'Must have at least one numeric character',
};

export const LoginCredentialsForm: FC<LoginCredentialsFormProps> = observer(
  ({ onSubmit, isLoading, defaultEmail }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm<LoginCredentials>();

    useEffect(() => {
      if (!defaultEmail) return;
      setValue('email', defaultEmail.value);
    }, [defaultEmail]);

    return (
      <form
        className="flex w-full flex-col gap-5"
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <InputWithError
          required
          label="Email address"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('email', {
            required: 'Fill in this field',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Ange en giltig e-post',
            },
          })}
          disabled={defaultEmail?.readonly}
          error={errors.email?.message}
        />
        <InputWithError
          required
          label="Password"
          type="password"
          autoComplete="password"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('password', {
            required: 'Fill in this field',
            minLength: {
              value: 6,
              message: 'Passwords must be at least 6 characters',
            },
            validate: passwordValidation,
          })}
          error={errors.password?.message}
        />
        <InputWithError
          required
          label="Repeat password"
          type="password"
          autoComplete="repeatPassword"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('repeatPassword', {
            required: 'Fill in this field',
            validate: (value) =>
              value === getValues('password') || 'The passwords do not match',
          })}
          error={errors.repeatPassword?.message}
        />
        <Button className="mt-6" disabled={isLoading}>
          Continue
        </Button>
        <div>
          <div className="relative inline-flex items-center">
            <Label
              htmlFor="termsAndPolicies"
              className="flex cursor-pointer items-center"
            >
              <input
                type="checkbox"
                id="termsAndPolicies"
                className="peer relative mr-2 h-6 w-6 appearance-none rounded-md bg-blocks"
                {...register('agreedToTermsAndPolicies', {
                  required: 'You have to agree to the terms and policies',
                })}
              />
              <CheckmarkIcon className="z-5 text-sky invisible absolute -top-0 h-6 w-6 peer-checked:visible" />
              <span className="text-sm font-light text-text">
                I agree with the terms and policies.
              </span>
            </Label>
          </div>
          {errors.agreedToTermsAndPolicies?.message && (
            <FormError>{errors.agreedToTermsAndPolicies?.message}</FormError>
          )}
        </div>
      </form>
    );
  },
);
