import { flow, types as t, applySnapshot } from 'mobx-state-tree';
import { INTEGRATIONS_API_URL } from '../constants';
import { withRequest } from '../extensions';
import { EntityLog } from './LogEntry';

const LogArray = t.array(EntityLog);

export const LogStore = t
  .model('LogStore', {
    logs: t.maybeNull(LogArray),
    selectedLog: t.maybeNull(EntityLog),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchLogs: flow(function* () {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + 'api/entitylogs/',
        });
        if (self.logs === null) {
          self.logs = LogArray.create();
        }
        self.logs.replace(data);
      }),
      reset() {
        applySnapshot(self, {
          logs: null,
        });
      },
    };
  });
