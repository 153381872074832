import { startOfToday } from 'date-fns';
import { endOfToday } from 'date-fns/esm';
import React, { useEffect, useRef, useState } from 'react';
import { fetchDocumentFlowData_demo } from '../../api/fetchDocumentFlowData_demo';
import { fetchMostActiveClientsData_demo } from '../../api/fetchMostActiveClientsData_demo';
import { RelationsStateCard } from '../../components/finance/Dashboard';
import { PageContainer } from '../../components/general';
import { BasicTextLoader } from '../../components/general/BasicTextLoader';
import {
  DocumentFlowCard,
  MostActiveClientsCard,
  TotalsCard,
  WentWrongCard,
} from '../../components/general/Dashboard';
import { IntegrationsStateCard } from '../../components/integrationHub/Dashboard';
import { INTEGRATIONS_API_URL } from '../../constants';
import { useStore } from '../../hooks';
import { DashboardIcon } from '../../icons';

import {
  CustomerInvoiceStatistics,
  DocumentFlowData,
  MostActiveClientsData,
  NameCount,
  TimeInterval,
} from '../../types';
import { HarvestModules } from '../../types/auth';

const IS_DEMO = false;

export const DashboardPage = () => {
  const { authStore } = useStore();
  const hasFinance = authStore.modules.includes(HarvestModules.Finance);
  const hasIntegrations = authStore.modules.includes(
    HarvestModules.Integrations,
  );

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [mostActiveClientsData, setMostActiveClientsData] =
    useState<MostActiveClientsData>();
  const [documentFlowInterval, setDocumentFlowInterval] =
    useState<TimeInterval>(TimeInterval.Day);
  const documentFlowToken = useRef({});
  const [documentFlowData, setDocumentFlowData] = useState<DocumentFlowData>();

  const handleDocumentFlowIntervalChange = async (interval: TimeInterval) => {
    const token = {};
    documentFlowToken.current = token;

    const data = IS_DEMO
      ? await fetchDocumentFlowData_demo(interval)
      : await fetchDocumentFlowData(interval);
    // This way we prevent using "old" data resulting from tab spamming
    if (documentFlowToken.current !== token) return;

    setDocumentFlowData(data);
    setDocumentFlowInterval(interval);
  };

  const fetchDocumentFlowData = async (
    timeInterval: TimeInterval,
  ): Promise<DocumentFlowData> => {
    const promise = authStore.authRequest({
      url: INTEGRATIONS_API_URL + 'api/statistics/documentflow',
      params: {
        period: timeInterval,
      },
    });

    const { data } = await promise;

    return data;
  };

  const fetchCustomerInvoiceStatistics = async (): Promise<
    CustomerInvoiceStatistics[]
  > => {
    const promise = authStore.authRequest({
      url: INTEGRATIONS_API_URL + 'api/statistics/customerinvoices',
    });
    const { data } = await promise;
    return await data;
  };

  const fetchMostActiveClientsData =
    async (): Promise<MostActiveClientsData> => {
      const customerInvoiceStatistics = await fetchCustomerInvoiceStatistics();

      return {
        volume: customerInvoiceStatistics
          .sort((customer1, customer2) =>
            customer1.volume > customer2.volume ? -1 : 1,
          )
          .slice(0, 5)
          .map(
            (customer) =>
              ({
                name: customer.customerNumber,
                count: customer.volume,
              } as NameCount),
          ),
        money: customerInvoiceStatistics
          .sort((customer1, customer2) =>
            customer1.amount > customer2.amount ? -1 : 1,
          )
          .slice(0, 5)
          .map(
            (customer) =>
              ({
                name: customer.customerNumber,
                count: customer.amount,
              } as NameCount),
          ),
      };
    };

  useEffect(() => {
    const todayStart = startOfToday().toISOString();
    const todayEnd = endOfToday().toISOString();

    const fetchData = async () => {
      if (hasIntegrations) {
        const documentFlowData = IS_DEMO
          ? await fetchDocumentFlowData_demo(TimeInterval.Day)
          : await fetchDocumentFlowData(TimeInterval.Day);

        setDocumentFlowData(documentFlowData);
      }

      if (hasIntegrations || hasFinance) {
        const mostActiveClientsData = IS_DEMO
          ? await fetchMostActiveClientsData_demo(todayStart, todayEnd)
          : await fetchMostActiveClientsData();

        setMostActiveClientsData(mostActiveClientsData);
      }
    };

    setIsFetching(true);
    fetchData();
    setIsFetching(false);
  }, []);

  // but this way we make TS happy and can except all data to be set below.
  if (isFetching) {
    return (
      <PageContainer
        className="flex content-center items-center justify-center"
        useBackground={false}
        title="Dashboard"
        titleIcon={<DashboardIcon className="w-12" />}
      >
        <BasicTextLoader className="mt-40 text-xl" text="Loading dashboard.." />
      </PageContainer>
    );
  }

  return (
    <PageContainer
      useBackground={false}
      title="Dashboard"
      titleIcon={<DashboardIcon className="w-12" />}
    >
      <div className="mx-auto grid w-full grid-cols-1 gap-7 lg:grid-cols-2 xl:grid-cols-3">
        {hasIntegrations && (
          <>
            <WentWrongCard />
            {documentFlowData && (
              <DocumentFlowCard
                interval={documentFlowInterval}
                onIntervalChange={handleDocumentFlowIntervalChange}
                data={documentFlowData}
              />
            )}
            <TotalsCard />
          </>
        )}

        {hasFinance && <RelationsStateCard />}
        {!hasFinance && hasIntegrations && <IntegrationsStateCard />}
        {mostActiveClientsData && (
          <MostActiveClientsCard {...mostActiveClientsData} />
        )}
      </div>
    </PageContainer>
  );
};
