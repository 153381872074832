import { startOfDay, startOfHour, startOfMonth } from 'date-fns';
import { TimeInterval } from '../types/TimeInterval';

export const roundDateTime = (
  dateTime: string,
  interval: TimeInterval,
): string => {
  const date = new Date(dateTime);

  switch (interval) {
    case TimeInterval.Day:
      return startOfHour(date).toString();
    case TimeInterval.Month:
      return startOfDay(date).toString();
    case TimeInterval.Year:
      return startOfMonth(date).toString();
  }
};
