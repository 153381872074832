import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NothingToDisplay, PageContainer } from '../../../components/general';
import {
  AddButton,
  Button,
  ExternalSystemButton
} from '../../../components/general/buttons';
import { IntegrationBox } from '../../../components/integrationHub';
import { useStore } from '../../../hooks/useStore';
import { IntegrationIcon } from '../../../icons';
import { IntegrationInstance } from '../../../models';

export const IntegrationsOverviewPage = observer(() => {
  const { integrationStore, externalSystemStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        integrationStore.fetchIntegrations(),
        externalSystemStore.fetchSystems(),
        externalSystemStore.fetchExternalSystemInvitations(),
      ]);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const unArchivedIntegrations = integrationStore.activeIntegrations;
  const pendingInvites = externalSystemStore.pendingExternalSystemInvitations;
  const hasData =
    (unArchivedIntegrations && unArchivedIntegrations.length > 0) ||
    pendingInvites.length > 0;


  return (
    <PageContainer
      title="Integrations"
      useBackground={hasData}
      titleIcon={<IntegrationIcon className="w-12" />}
    >
      {hasData ? (
        <div className="flex w-full flex-col rounded-lg">
          <div className="grid grid-cols-[repeat(auto-fill,_max(64px))] gap-x-10 gap-y-5">
            <AddButton
              onClick={() => navigate('/integrations/create')}
              description={'Add New'}
            />

            {pendingInvites.map((invitation) => (
              <ExternalSystemButton
                showStatus={false}
                onClick={() =>
                  navigate(`/integrations/invitations/${invitation.id}`)
                }
                logoUrl={invitation.externalTenantLogoUrl || undefined}
                key={invitation.id}
                description={invitation.externalSystemName}
                externalSystemName={invitation.externalSystemName}
              />
            ))}
          </div>
          <div className="flex flex-wrap items-center gap-x-12">
            {unArchivedIntegrations?.map((integration: IntegrationInstance) => {
              return (
                <div key={integration.id}>
                  <IntegrationBox integration={integration} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NothingToDisplay
          loading={isLoading}
          className={`mt-5 sm:mt-20 ${isLoading && 'animate-pulse'}`}
          text={'There are currently no integrations to display'}
        >
          <Button onClick={() => navigate('/integrations/create')}>
            {"Let's get started!"}
          </Button>
        </NothingToDisplay>
      )}
    </PageContainer>
  );
});
