import React from 'react';

interface TableProps<TRowItem, THeaderCell> {
  items: TRowItem[];
  renderRow: (item: TRowItem, index: number) => JSX.Element;
  headerItems: THeaderCell[];
  renderHeaderCell: (item: THeaderCell, index: number) => JSX.Element;
  className?: string;
}

export const Table = <TRowItem, THeaderCell>({
  items,
  renderRow,
  headerItems,
  renderHeaderCell,
  className,
}: TableProps<TRowItem, THeaderCell>) => {
  return (
    <table className={`w-full rounded-md ${className}`}>
      <thead>
        <tr className="text-left">{headerItems.map(renderHeaderCell)}</tr>
      </thead>
      <tbody>{items.map(renderRow)}</tbody>
    </table>
  );
};
