import { isValid } from 'date-fns';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, FocusEvent } from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { ChevronDownIcon } from '../../icons';

interface DatePickerProps {
  onChange: (dates: [Date, Date]) => void;
  startDate: Date | null;
  endDate: Date | null;
  className?: string;
}

interface DatePickerState {
  startDate: Date | null;
  endDate: Date | null;
  setDates: (dates: [Date, Date]) => void;
  handleOnBlur: (event: FocusEvent<HTMLInputElement, Element>) => void;
}

export const DatePicker: FC<DatePickerProps> = observer(
  ({ onChange, startDate, endDate, className }) => {
    const state = useLocalObservable<DatePickerState>(() => ({
      startDate: startDate,
      endDate: endDate,
      setDates(dates: [Date, Date]) {
        const [startDate, endDate] = dates;
        state.startDate = startDate;
        state.endDate = endDate;

        if (isValid(startDate) && isValid(endDate)) {
          onChange([state.startDate!, state.endDate!]);
        }
      },
      handleOnBlur(event) {
        const { value } = event.target;
        if (!value) return;

        const dates = value.split('-');
        const [startDateString, endDateString] = dates;
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        if (!isValid(startDate) || !isValid(endDate)) return;

        state.setDates([startDate, endDate]);
      },
    }));

    return (
      <ReactDatePicker
        className={`truncate rounded-lg bg-blocks p-3 text-sm ${className}`}
        onChange={state.setDates}
        startDate={state.startDate}
        endDate={state.endDate}
        selectsRange
        showPopperArrow={false}
        onBlur={state.handleOnBlur}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <div className="flex items-center justify-between p-2">
            <button onClick={decreaseMonth}>
              <ChevronDownIcon className="w-7 rotate-90" />
            </button>
            <div className="text-sm font-bold">
              {date.toLocaleString('en-us', { month: 'long', year: 'numeric' })}
            </div>
            <button onClick={increaseMonth}>
              <ChevronDownIcon className="w-7 -rotate-90" />
            </button>
          </div>
        )}
      />
    );
  },
);
