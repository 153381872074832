import React, { FC, memo, SVGProps } from 'react';
import { useStore } from '../hooks';


export const UserIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => {

  const { tenantStore } = useStore();
  const logoUrl = tenantStore.logoUrl;
  
  return (
    <svg
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <rect
        x="1.01302"
        y="1.01302"
        width="31.974"
        height="31.974"
        rx="15.987"
        fill="url(#pattern1)"
      />
      <rect
        x="1.01302"
        y="1.01302"
        width="31.974"
        height="31.974"
        rx="15.987"
        stroke="currentColor"
        strokeWidth="2.02604"
      />
      {logoUrl ? <defs>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_352_867"
            transform="translate(0 -0.230978) scale(0.0013587)"
          />
        </pattern>
        <image
          id="image0_352_867"
          width="736"
          height="1076"
          href={logoUrl!}
        />
      </defs> :
        <text x="11.5" y="23" fontSize={16} fill="currentColor">{tenantStore.name ? tenantStore.name[0] : ''}</text>
      }
    </svg>
  )
});
