import React, { FC, memo, SVGProps } from 'react';

export const ListIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.14051 14.1094C6.81681 14.1094 6.55469 13.8368 6.55469 13.5002C6.55469 13.1635 6.81681 12.8909 7.14051 12.8909H18.8592C19.1829 12.8909 19.445 13.1635 19.445 13.5002C19.445 13.8368 19.1829 14.1094 18.8592 14.1094H7.14051ZM7.14051 18.9846C6.81681 18.9846 6.55469 18.7117 6.55469 18.3751C6.55469 18.0387 6.81681 17.7658 7.14051 17.7658H18.8592C19.1829 17.7658 19.445 18.0387 19.445 18.3751C19.445 18.7117 19.1829 18.9846 18.8592 18.9846H7.14051ZM7.14051 9.23466C6.81681 9.23466 6.55469 8.96177 6.55469 8.62541C6.55469 8.28876 6.81681 8.01587 7.14051 8.01587H18.8592C19.1829 8.01587 19.445 8.28876 19.445 8.62541C19.445 8.96177 19.1829 9.23466 18.8592 9.23466H7.14051Z"
      fill="currentColor"
    />
    <rect
      x="0.6"
      y="0.6"
      width="24.8"
      height="24.8"
      rx="5.4"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </svg>
));
