import axios, { AxiosRequestConfig } from 'axios';
import { applySnapshot, flow, getRoot, types as t } from 'mobx-state-tree';
import { IDENTITYSERVER_URL } from '../constants';
import { RootStore } from './RootStore';
import jwt_decode from 'jwt-decode';
import { HarvestJwtPayload, HarvestModules } from '../types/auth';

export const AuthStore = t
  .model('AuthStore', {
    access_token: t.maybeNull(t.string),
    refresh_token: t.maybeNull(t.string),
    modules: t.array(
      t.enumeration<HarvestModules>(Object.values(HarvestModules)),
    ),
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.access_token !== null;
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<typeof RootStore>(self);

    let refreshTokensPromise: null | Promise<any> = null;
    const refreshTokens = flow(function* () {
      // No catch clause because we want the caller to handle the error.
      try {
        const res = yield axios({
          method: 'POST',
          url: IDENTITYSERVER_URL + 'refresh-token/',
          data: {
            refreshtoken: self.refresh_token,
          },
        });

        self.access_token = res.data.accessToken;
        self.refresh_token = res.data.refreshToken;

        const decoded = jwt_decode<HarvestJwtPayload>(res.data.accessToken);
        if (decoded.module) {
          decoded.module instanceof Array
            ? self.modules.push(...decoded.module)
            : self.modules.push(decoded.module);
        }
      } finally {
        refreshTokensPromise = null;
      }
    });

    return {
      // Multiple requests could fail at the same time because of an old
      // access_token, so we want to make sure only one token refresh
      // request is sent.
      refreshTokens() {
        if (refreshTokensPromise) return refreshTokensPromise;

        refreshTokensPromise = refreshTokens();
        return refreshTokensPromise;
      },

      logIn: flow(function* (email, password) {
        const { data } = yield axios({
          method: 'POST',
          url: IDENTITYSERVER_URL + 'login/',
          data: {
            email,
            password,
          },
        });

        self.access_token = data.accessToken;
        self.refresh_token = data.refreshToken;

        const decoded = jwt_decode<HarvestJwtPayload>(data.accessToken);
        if (decoded.module) {
          decoded.module instanceof Array
            ? self.modules.push(...decoded.module)
            : self.modules.push(decoded.module);
        }

        rootStore.init();
      }),
      logOut() {
        rootStore.reset();
      },
      recoverPassword: flow(function* (email) {
        return yield axios({
          method: 'POST',
          url: IDENTITYSERVER_URL + 'reset',
          data: {
            baseUrl: 'http://localhost:3000/new-password',
            email,
          },
        });
      }),
      setNewPassword: flow(function* (resetCode, email, password) {
        yield axios({
          method: 'POST',
          url: IDENTITYSERVER_URL + 'new-password',
          data: {
            resetCode,
            email,
            password,
          },
        });
      }),
      reset() {
        applySnapshot(self, {
          access_token: null,
          refresh_token: null,
        });
      },
    };
  })
  .actions((self) => {
    const { uiStore } = getRoot<typeof RootStore>(self);

    return {
      authRequest: flow(function* (conf: AxiosRequestConfig) {
        const authConf = {
          ...conf,
          headers: {
            ...conf.headers,
            Authorization: `Bearer ${self.access_token}`,
          },
        };

        try {
          return yield axios(authConf);
        } catch (error: any) {
          const { status } = error.response;

          // Show the general error message unless it's an authorization or bad request error.
          if (![400, 401].includes(status)) {
            if (status === 403)
              uiStore.setError('Permission Error', 'Insufficient permissions');

            uiStore.setError('Something went wrong!', 'Please try again');
          }

          // Throw the error to the caller if it's not an authorization error.
          if (status !== 401) {
            throw error;
          }

          try {
            yield self.refreshTokens();
          } catch (err) {
            self.logOut();
            throw err;
          }

          authConf.headers.Authorization = `Bearer ${self.access_token}`;

          return yield axios(authConf);
        }
      }),
    };
  });
