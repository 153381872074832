import React, { FC, memo, SVGProps } from 'react';

export const PlayIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg 
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.85687 9.11486L0.142578 17.5V0.72973L9.85687 9.11486Z" fill="currentColor"/>
  </svg>

));
