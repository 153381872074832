import Downshift from 'downshift';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { forwardRef, useEffect } from 'react';

export type IdNameObj = { id: string; name: string; selected?: boolean };

type SelectProps = {
  combobox?: boolean;
  name: string;
  label?: string;
  required?: boolean;
  placeholder: string;
  items: IdNameObj[];
  noMatchText?: string;
  value?: string;
  onChange?: (selection: IdNameObj) => void;
  disabled?: boolean;
};

type SelectState = {
  value: string;
  setValue: (value: string) => void;
  lowercasedInputValue: string;
  filteredItems: IdNameObj[];
};

export const Select = observer(
  forwardRef<HTMLSelectElement, SelectProps>(
    (
      {
        combobox = false,
        name,
        label,
        required = false,
        placeholder,
        items,
        noMatchText = '',
        value,
        onChange,
        disabled = false,
      },
      ref,
    ) => {
      const state = useLocalObservable<SelectState>(() => ({
        value: value || '',
        setValue(value) {
          state.value = value;
        },
        get lowercasedInputValue(): string {
          return state.value.toLowerCase().replace(' ', '') || '';
        },
        get filteredItems(): IdNameObj[] {
          // When the component is not a combobox (i.e. it's a regular select) we don't
          // want to filter the items by the input.
          return !combobox
            ? items
            : items.filter((item) =>
                item.name
                  .toLowerCase()
                  .replace(' ', '')
                  .includes(state.lowercasedInputValue),
              );
        },
      }));

      useEffect(() => {
        state.setValue(value || '');
      }, [value]);

      return (
        <Downshift
          onChange={(selection) => {
            state.setValue(selection.name);
            onChange && onChange(selection);
          }}
          itemToString={(item) => (item ? item.name : '')}
          inputValue={state.value}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen,
            inputValue,
            highlightedIndex,
            getRootProps,
            clearSelection,
          }) => {
            return (
              <div>
                {label && (
                  <label
                    {...getLabelProps({
                      className: 'inline-block font-semibold mb-2',
                    })}
                  >
                    {label} {required && <span className="text-error">*</span>}
                  </label>
                )}
                <div
                  {...getRootProps({ className: 'relative' } as any, {
                    suppressRefError: true,
                  })}
                >
                  {combobox ? (
                    <input
                      {...getInputProps({
                        name,
                        required,
                        className:
                          'block w-full text-sm py-3 pl-3 pr-10 border border-solid border-gray-200 rounded-md focus:outline-none focus:border-white',
                        type: 'text',
                        placeholder,
                        autoComplete: 'off',
                        onChange: (e) => {
                          state.setValue(e.target.value);
                          if (e.target.value === '') {
                            clearSelection();
                          }
                        },
                      })}
                    />
                  ) : (
                    <>
                      {/* We use a real select as button for our custom select so we can take advantage
                      of the native focus/error handling of the browser. */}
                      <select
                        ref={ref}
                        {...getToggleButtonProps({
                          name,
                          required,
                          value: inputValue!,
                          className: `block relative bg-blocks w-full text-sm p-3 border border-solid border-blocks rounded-md appearance-none text-left focus:outline-none focus:border-white truncate ${
                            inputValue ? '' : 'text-gray-400'
                          }`,
                          disabled: disabled,
                          onMouseDown: (e) => e.preventDefault(),
                        })}
                      >
                        <option value="">{placeholder}</option>
                        {/* We still need to render the options so the select "button" content is rendered properly. */}
                        {items.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  <button
                    {...getToggleButtonProps({
                      className:
                        'absolute top-0 right-0 focus:outline-none h-full px-2',
                      type: 'button',
                      tabIndex: -1,
                    })}
                  ></button>
                </div>
                <div className="relative">
                  <ul
                    {...getMenuProps({
                      className:
                        'absolute w-full bg-blocks max-h-56 overflow-y-auto rounded-sm shadow-md mt-1 z-10',
                    })}
                  >
                    {isOpen ? (
                      state.filteredItems.length !== 0 ? (
                        state.filteredItems.map((item, index) => (
                          <li
                            key={item.id}
                            {...getItemProps({
                              className: `text-sm px-3 py-2 border-b border-solid border-background cursor-pointer ${
                                highlightedIndex === index
                                  ? 'bg-background'
                                  : ''
                              }`,
                              item,
                              index,
                            })}
                          >
                            {item.name}
                          </li>
                        ))
                      ) : (
                        <li className="border-b border-solid border-error px-3 py-2 text-sm">
                          {noMatchText}
                        </li>
                      )
                    ) : null}
                  </ul>
                </div>
              </div>
            );
          }}
        </Downshift>
      );
    },
  ),
);

// export const Select: FC<SelectProps> = observer(referenceComponent);
