import React, { FC, memo, SVGProps } from 'react';

export const ExternalSystemIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.80323 15.8224C1.68544 15.9652 1.63783 16.1532 1.67353 16.3351L2.0495 18.2012L0.750694 19.5C0.508999 19.7417 0.509159 20.1334 0.750538 20.375L5.12547 24.75C5.36716 24.9914 5.75884 24.9915 6.00053 24.7498L7.29934 23.451L9.16544 23.827C9.36685 23.8654 9.57436 23.8013 9.71917 23.6562L14.9691 18.4063C15.2106 18.1648 15.2106 17.773 14.9691 17.5314L13.8753 16.4377L15.4065 14.9065C15.5251 14.791 15.5927 14.6328 15.5939 14.4671C15.595 14.3015 15.5298 14.1424 15.4126 14.0252C15.2956 13.9081 15.1363 13.8428 14.9709 13.844C14.8052 13.8453 14.6468 13.9127 14.5314 14.0314L13.0003 15.5626L9.93789 12.5002L11.4691 10.9691C11.5878 10.8537 11.6552 10.6953 11.6565 10.5296C11.6577 10.3642 11.5924 10.2049 11.4753 10.0879C11.3581 9.97068 11.199 9.90553 11.0334 9.90659C10.8677 9.90781 10.7095 9.97541 10.594 10.094L9.06281 11.6252L7.96908 10.5314C7.72754 10.2899 7.33571 10.2899 7.09417 10.5314L1.84426 15.7813C1.83007 15.7943 1.81634 15.808 1.80321 15.8224L1.80323 15.8224ZM10.9906 6.63504C10.7863 6.88177 10.8041 7.24356 11.0315 7.46905L18.0314 14.4689C18.2729 14.7105 18.6647 14.7105 18.9063 14.4689L24.1562 9.21902C24.3011 9.07132 24.3626 8.8609 24.3202 8.65858L23.951 6.7992L25.2498 5.50039C25.4915 5.25869 25.4913 4.86702 25.2499 4.62532L20.875 0.250398C20.6333 0.00900775 20.2416 0.00886412 19.9999 0.250555L18.7011 1.54936L16.835 1.17339C16.6336 1.13494 16.4261 1.19903 16.2813 1.34414L11.0314 6.59405C11.0172 6.60702 11.0035 6.62075 10.9905 6.63494L10.9906 6.63504ZM2.95173 16.424L7.53173 11.844L13.6566 17.9689L9.07663 22.5489L7.21052 22.1729C7.00911 22.1345 6.80175 22.1987 6.65695 22.3438L5.56322 23.4376L2.0632 19.9376L3.15693 18.8438C3.30204 18.699 3.36628 18.4917 3.32782 18.2902L2.95173 16.424ZM12.3442 7.0316L16.9242 2.4516L18.7834 2.82069C18.9859 2.86326 19.1961 2.80162 19.3438 2.65666L20.4376 1.56293L23.9376 5.06295L22.8438 6.15668C22.6987 6.30149 22.6345 6.50885 22.6729 6.71026L23.0489 8.57636L18.4689 13.1564L12.3442 7.0316Z"
      fill="currentColor"
    />
  </svg>
));
