import axios from 'axios';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  AboutOrganizationForm,
  AboutOrganizationFormData,
  PopupModal,
} from '../../components/general';
import {
  LoginCredentials,
  LoginCredentialsForm,
} from '../../components/general/LoginCredentialsForm';
import { IDENTITYSERVER_URL } from '../../constants';
import { LoginIcon, UserPlusIcon } from '../../icons';
import { Buffer } from 'buffer';
import {
  AboutUserForm,
  AboutUserFormData,
} from '../../components/general/AboutForm';
import { useTranslation } from 'react-i18next';

enum SignUpStep {
  LoginCredentials = 'loginCredentials',
  AboutUser = 'aboutUser',
  AboutOrganization = 'aboutOrganization',
}

const SIGN_UP_STEP_TITLES = {
  [SignUpStep.LoginCredentials]: 'Sign Up',
  [SignUpStep.AboutUser]: 'More about you',
  [SignUpStep.AboutOrganization]: 'More about your organization',
};

interface SignUpPageState {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  signUpStep: SignUpStep;
  setSignUpStep: (step: SignUpStep) => void;
  loginCredentials: LoginCredentials | null;
  setLoginCredentials: (data: LoginCredentials) => void;
  setUserInformation: (data: AboutUserFormData) => void;
  userData: AboutUserFormData | null;
  handleSubmit: (data: AboutOrganizationFormData) => void;
}

export const SignUpPage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);
  const redirectBase64 = searchParams.get('redirect');
  const redirect = redirectBase64
    ? Buffer.from(redirectBase64, 'base64').toString('utf8')
    : null;

  const state = useLocalObservable<SignUpPageState>(() => ({
    isLoading: false,
    setIsLoading(isLoading) {
      state.isLoading = isLoading;
    },
    signUpStep: SignUpStep.LoginCredentials,
    setSignUpStep(step) {
      state.signUpStep = step;
    },
    loginCredentials: null,
    setLoginCredentials(data) {
      state.loginCredentials = data;
      state.setSignUpStep(SignUpStep.AboutUser);
    },
    setUserInformation(data) {
      state.userData = data;
      state.setSignUpStep(SignUpStep.AboutOrganization);
    },
    userData: null,
    async handleSubmit(aboutOrganizationFormData) {
      const data = {
        loginCredentials: { ...state.loginCredentials },
        organizationInfo: { ...aboutOrganizationFormData },
        userInfo: { ...state.userData },
      };

      try {
        state.setIsLoading(true);
        await axios({
          method: 'POST',
          url: `${IDENTITYSERVER_URL}register`,
          data,
        });
        navigate(redirect ?? '/');
      } finally {
        state.setIsLoading(false);
      }
    },
  }));

  return (
    <div className="flex flex-col items-center gap-4">
      <PopupModal
        canClose={true}
        className="max-w-md sm:w-md"
        title={SIGN_UP_STEP_TITLES[state.signUpStep]}
        titleIcon={<UserPlusIcon className="w-6" />}
      >
        {state.signUpStep === SignUpStep.LoginCredentials && (
          <LoginCredentialsForm
            onSubmit={state.setLoginCredentials}
            isLoading={state.isLoading}
          />
        )}
        {state.signUpStep === SignUpStep.AboutUser && (
          <AboutUserForm
            onSubmit={state.setUserInformation}
            isLoading={state.isLoading}
            submitText={t('Continue')}
          />
        )}
        {state.signUpStep === SignUpStep.AboutOrganization && (
          <AboutOrganizationForm
            onSubmit={state.handleSubmit}
            isLoading={state.isLoading}
          />
        )}
      </PopupModal>
      <Link to="/" className="flex items-center gap-1 font-light text-text">
        <LoginIcon className="w-8" />
        Already have an account? Sign in.
      </Link>
    </div>
  );
});
