import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useStore } from '../../hooks/useStore';
import { ExternalSystemIcon } from '../../icons';
import { SystemDetailsInstance } from '../../models';
import {
  ExternalSystemDirection,
  ExternalSystemType,
  IntegrationEntityType,
} from '../../types';
import { PopupModal } from '../general';
import { ExternalSystemButton } from '../general/buttons';
import { IdNameObj, Select } from '../general/Select';
import {
  BjornlundenSetup,
  FortnoxSetup,
  HarvestFinanceSetup,
  SftpSetup,
  TwentyFourSevenOfficeSetup,
} from './ExternalSystemSetups';

interface ManageSystemProps {
  systemId?: string;
  externalSystemType: ExternalSystemType;
  showTypeSelector?: boolean;
  open: boolean;
  onClose: () => void;
  entityType?: IntegrationEntityType;
  externalSystemDirection?: ExternalSystemDirection;
  onAddedSystem: (id: string) => void;
  parentState?: object;
}

interface ManageSystemState {
  externalSystemType: ExternalSystemType;
  integrationEntityType?: IntegrationEntityType;
  setExternalSystemType: (externalSystemType: ExternalSystemType) => void;
  setIntegrationEntityType: (
    IntegrationEntityType: IntegrationEntityType,
  ) => void;
}

export const ManageSystemPopup: FC<ManageSystemProps> = observer(
  ({
    systemId,
    externalSystemType,
    showTypeSelector = true,
    onClose,
    open,
    entityType,
    externalSystemDirection,
    onAddedSystem,
    parentState,
  }) => {
    const { externalSystemStore } = useStore();
    const { availableSystems } = externalSystemStore;

    const state = useLocalObservable<ManageSystemState>(() => ({
      externalSystemType: externalSystemType,
      setExternalSystemType(externalSystemType: ExternalSystemType) {
        state.externalSystemType = externalSystemType;
      },
      integrationEntityType: undefined,
      setIntegrationEntityType(IntegrationEntityType: IntegrationEntityType) {
        state.integrationEntityType = IntegrationEntityType;
      },
    }));

    useEffect(() => {
      externalSystemStore.getAvailableSystems();
      state.setIntegrationEntityType(entityType!);
    }, []);

    const res: IdNameObj[] = [];
    Object.keys(IntegrationEntityType).map((data) =>
      res.push({
        id: data,
        name: data,
      }),
    );

    let possibleSystems: Array<SystemDetailsInstance> = [];
    if (availableSystems != null && externalSystemDirection != null) {
      if (externalSystemDirection === ExternalSystemDirection.Reader) {
        availableSystems.readerSystems.forEach((reader) => {
          if (
            reader.supportedEntityTypes?.includes(
              state.integrationEntityType?.toString() ?? '',
            )
          ) {
            possibleSystems.push(reader);
          }
        });
      } else if (externalSystemDirection === ExternalSystemDirection.Writer) {
        availableSystems.writerSystems.forEach((writer) => {
          if (
            writer.supportedEntityTypes?.includes(
              state.integrationEntityType?.toString() ?? '',
            )
          ) {
            possibleSystems.push(writer);
          }
        });
      } else {
        possibleSystems.push(...availableSystems.readerSystems);
        possibleSystems.push(...availableSystems.writerSystems);

        // Remove duplicates
        possibleSystems = possibleSystems.filter(
          (system, index) =>
            index ===
            possibleSystems.findIndex(
              (x) => x.systemType === system.systemType,
            ),
        );
      }
    }

    if (!open) return null;

    let title = systemId ? 'Update' : 'Add';
    title +=
      externalSystemDirection === ExternalSystemDirection.Reader
        ? ' source'
        : '';
    title +=
      externalSystemDirection === ExternalSystemDirection.Writer
        ? ' destination'
        : '';
    title +=
      externalSystemDirection === ExternalSystemDirection.Any
        ? ' source or destination'
        : '';
    title += ' detais';

    return (
      <PopupModal
        className="w-[442px] flex-col"
        titleIcon={<ExternalSystemIcon className="h-6" />}
        title={`${title}`}
        onClose={() => {
          state.setExternalSystemType(ExternalSystemType.None);
          onClose();
        }}
        canClose={true}
      >
        {state.externalSystemType == ExternalSystemType.None && (
          <div className="">
            {showTypeSelector && (
              <div className="mb-7 w-full bg-popup">
                <Select
                  name="externalSystem"
                  label="Type"
                  value={state.integrationEntityType}
                  placeholder="Source Type"
                  noMatchText="No systems found"
                  required
                  items={res}
                  onChange={(e) =>
                    state.setIntegrationEntityType(
                      e.name as IntegrationEntityType,
                    )
                  }
                />
              </div>
            )}
            <div className="flex w-full flex-wrap items-start justify-start gap-4">
              {possibleSystems.map((x, index) => {
                return (
                  <ExternalSystemButton
                    key={index}
                    externalSystemType={x.systemType as ExternalSystemType}
                    externalSystemName={x.systemType || ''}
                    description={x.systemType || ''}
                    showStatus={false}
                    onClick={() =>
                      state.setExternalSystemType(
                        x.systemType as ExternalSystemType,
                      )
                    }
                  />
                );
              })}
            </div>
          </div>
        )}
        {state.externalSystemType == ExternalSystemType.Fortnox && (
          <FortnoxSetup
            parentState={parentState}
            onFormSubmit={onAddedSystem}
            systemId={systemId}
          />
        )}
        {state.externalSystemType == ExternalSystemType.SFTP && (
          <SftpSetup onFormSubmit={onAddedSystem} systemId={systemId} />
        )}
        {state.externalSystemType ==
          ExternalSystemType.TwentyFourSevenOffice && (
          <TwentyFourSevenOfficeSetup
            onFormSubmit={onAddedSystem}
            systemId={systemId}
          />
        )}
        {state.externalSystemType == ExternalSystemType.Bjornlunden && (
          <BjornlundenSetup onFormSubmit={onAddedSystem} systemId={systemId} />
        )}
        {state.externalSystemType == ExternalSystemType.HarvestFinance && (
          <HarvestFinanceSetup
            onFormSubmit={onAddedSystem}
            systemId={systemId}
          />
        )}
      </PopupModal>
    );
  },
);
