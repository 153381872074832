import React, { FC, memo, SVGProps } from 'react';

export const ThickCrossIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    className="w-4"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M4.707 4.707l7.07 7.071M11.776 4.708l-7.07 7.07"
    ></path>
  </svg>
));
