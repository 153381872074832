import { INTEGRATIONS_API_URL } from '../constants';
import { IntegrationsStateData } from '../types/integrationHub';

export const fetchIntegrationsStateData = async (
  authStore,
): Promise<IntegrationsStateData> => {
  const promise = authStore.authRequest({
    url: INTEGRATIONS_API_URL + 'api/statistics/integrationsStatus',
  });
  const { data } = await promise;

  return {
    failed: data.failed,
    disabled: data.disabled,
    active: data.active,
  };
};
