import React, { FC, SVGProps, memo } from 'react';

export const FolderOpenIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 17"
    className="w-4"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.213 7.437C.506 7.672 0 8.309 0 9.057v5.726c0 .949.815 1.718 1.82 1.718h13.343c1.005 0 1.82-.77 1.82-1.718v-8.57c0-.949-.815-1.718-1.82-1.718H12.13v-2.26c0-.949-.814-1.717-1.82-1.717H3.034c-1.005 0-1.82.768-1.82 1.717v5.202zm8.878-1.796h5.072c.335 0 .607.256.607.572v8.57c0 .316-.272.572-.607.572H1.82c-.335 0-.607-.256-.607-.572V9.057c0-.316.272-.573.607-.573h5.678c.867 0 1.613-.576 1.784-1.379l.214-1.004a.599.599 0 01.595-.46zm.827-1.146v-2.26c0-.316-.272-.572-.607-.572H3.033c-.335 0-.607.256-.607.573v5.103h5.072a.599.599 0 00.595-.46l.214-1.005c.17-.802.918-1.378 1.784-1.378h.827zM4.225 6.194h2.75c.335 0 .607-.257.607-.573 0-.316-.272-.572-.607-.572h-2.75c-.335 0-.606.256-.606.572 0 .316.271.573.606.573zm0-2.29h4.873c.335 0 .606-.257.606-.573 0-.316-.271-.573-.606-.573H4.225c-.335 0-.606.257-.606.573 0 .316.271.572.606.572z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h17v16H0z"></path>
      </clipPath>
    </defs>
  </svg>
));
