import React, { FC, memo, SVGProps } from 'react';

export const PauseIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="13.5" y="7.5" width="4" height="25" rx="2" fill="currentColor" />
    <rect x="22.5" y="7.5" width="4" height="25" rx="2" fill="currentColor" />
  </svg>
));
