import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

export type ExpandedSelectOption = {
    value: string,
    isSelected?: boolean
}

type ExpandedSelectProps = {
    options?: Array<ExpandedSelectOption>,
    className?: string,
    onSelectionChanged: (option) => void
};

export const ExpandedSelect: FC<ExpandedSelectProps> = observer(
    ({ options, onSelectionChanged }) => {

        return (
            <div>
                <div className='flex flex-row text-background hover:cursor-default selection:bg-none items-stretch'>
                    {options?.map((option) => {
                        return (
                            <button key={option.value} onClick={() => onSelectionChanged(option)} className={` first:rounded-l-md last:rounded-r-md w-full flex justify-center hover:bg-opacity-80 px-4 py-2 ${option.isSelected ? 'bg-titles': 'bg-labels'}`}>
                                {option.value}
                            </button>
                        )
                    })}
                </div>
            </div>
        );
    },
);
