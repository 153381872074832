import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { Card } from './Card';
import { TrendNumbers } from './TrendNumbers';
import { IntervalPillTabs } from './IntervalPillTabs';
import { ChartLegends } from './ChartLegends';
import { TimeInterval, TotalsData } from '../../../types';
import { NoteIcon } from '../../../icons';
import { useStore } from '../../../hooks';
import {
  getTrendTexts,
  getTrendData,
} from '../../../utils/Dashboard/TrendUtils';
import { transformPeriodData } from '../../../utils/Dashboard/transformPeriodData';
import moment from 'moment';
import { INTEGRATIONS_API_URL } from '../../../constants';
import { formatInterval } from '../../../utils/Dashboard/formatInterval';
import { TrendData } from '../../../types/TrendData';
import { fetchTotalsData_demo } from '../../../api/fetchTotalsData_demo';

const IS_DEMO = false;

export const TotalsCard: FC = () => {
  const {
    settingsStore: { darkMode },
    authStore: { authRequest },
    integrationEntityStore,
  } = useStore();
  const { t } = useTranslation();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  const [totalsDataInterval, setTotalsDataInterval] = useState<TimeInterval>(
    TimeInterval.Day,
  );
  const totalsDataToken = useRef({});
  const [totalsData, setTotalsData] = useState<TotalsData>();
  const [customerInvoicesTrend, setInvoiceTrend] = useState<TrendData>();
  const [creditMemoTrend, setCreditMemoTrend] = useState<TrendData>();
  const [labels, setLabels] = useState<[string, string]>();

  const handleTotalsDataIntervalChange = async (interval: TimeInterval) => {
    const token = {};
    totalsDataToken.current = token;

    IS_DEMO
      ? await fetchTotalsData_demo(interval, setTotalsData)
      : await fetchTotalsData(interval);

    // This way we prevent using "old" data resulting from tab spamming
    if (totalsDataToken.current !== token) return;

    setTotalsDataInterval(interval);
    setLabels(getTrendTexts(interval, t));
  };

  const fetchTotalsData = async (timeInterval: TimeInterval) => {
    const promise = authRequest({
      url: INTEGRATIONS_API_URL + 'api/statistics/totals',
      params: {
        period: timeInterval,
        fromDate: moment().format(),
      },
    });

    const { data } = await promise;

    setTotalsData(data);
  };

  useEffect(() => {
    IS_DEMO
      ? fetchTotalsData_demo(totalsDataInterval, setTotalsData)
      : fetchTotalsData(totalsDataInterval);
  }, []);

  useEffect(() => {
    if (!totalsData) return;

    setInvoiceTrend(
      getTrendData(
        'customerInvoice',
        totalsData.currentPeriodData.periodToDate,
        totalsData.previousPeriodData.periodToDate,
      ),
    );
    setCreditMemoTrend(
      getTrendData(
        'customerCreditMemo',
        totalsData.currentPeriodData.periodToDate,
        totalsData.previousPeriodData.periodToDate,
      ),
    );
  }, [totalsData]);

  useLayoutEffect(() => {
    if (!totalsData) return;

    const ctx = canvasRef.current!.getContext('2d')!;
    const transformedData = transformPeriodData(
      totalsData.currentPeriodData.period,
      totalsDataInterval,
      darkMode || false,
      t,
    );

    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: transformedData.datasets,
        labels:
          totalsDataInterval === TimeInterval.Year
            ? transformedData.labels.map((label) => t(label))
            : transformedData.labels,
      },
      options: {
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: { display: false },
            stacked: true,
            display: true,
            ticks: {
              color: darkMode ? 'rgb(184, 220, 255)' : 'rgb(37, 37, 58)',
              maxRotation: 0,
              minRotation: 0,
              callback: (tickvalue, index, ticks) =>
                formatInterval(t, totalsDataInterval, tickvalue),
            },
          },
          y: {
            ticks: {
              color: darkMode ? 'rgb(184, 220, 255)' : 'rgb(37, 37, 58)',
            },
            grid: { display: false },
            stacked: true,
            display: true,
          },
        },
      },
    });

    return () => chartRef.current!.destroy();
  }, [totalsData]);

  useLayoutEffect(() => {
    if (!totalsData || !chartRef.current) return;

    const chart = chartRef.current;
    const transformedData = transformPeriodData(
      totalsData.currentPeriodData.period,
      totalsDataInterval,
      darkMode || false,
      t,
    );

    chart.data.datasets = transformedData.datasets;
    chart.data.labels =
      totalsDataInterval === TimeInterval.Year
        ? transformedData.labels.map((label) => t(label))
        : transformedData.labels;

    const scaleXTicks = chart.options.scales && chart.options.scales['x'];
    if (scaleXTicks?.ticks) {
      scaleXTicks.ticks.callback = (tickValue, index, ticks) =>
        formatInterval(t, totalsDataInterval, tickValue);
    }

    chart.update();
  }, [totalsData, totalsDataInterval, darkMode]);

  const hasData =
    integrationEntityStore.pagedInvoiceEntities &&
    integrationEntityStore.pagedInvoiceEntities.length > 0;

  return (
    <>
      <div hidden={!hasData}>
        <Card
          icon={<NoteIcon />}
          title={t('Totals')}
          actions={
            <IntervalPillTabs
              value={totalsDataInterval}
              onChange={handleTotalsDataIntervalChange}
            />
          }
        >
          <div className="">
            <div className="mt-6 flex  flex-grow flex-col">
              <div className="mt-6 flex">
                <TrendNumbers
                  title={t('Invoices')}
                  currentTimeLabel={labels && labels[0]}
                  prevTimeLabel={labels && labels[1]}
                  trendData={customerInvoicesTrend}
                />

                <div className="ml-9" />

                <TrendNumbers
                  title={t('Credit memos')}
                  currentTimeLabel={labels && labels[0]}
                  prevTimeLabel={labels && labels[1]}
                  trendData={creditMemoTrend}
                />
              </div>

              <div className="my-auto mt-10 flex justify-center ">
                <div className="w-full">
                  <canvas ref={canvasRef} />
                </div>
              </div>

              <div className="mt-7" />

              <ChartLegends
                legends={[
                  { label: t('Invoices'), bg: 'bg-light-rose' },
                  { label: t('Credit memos'), bg: 'bg-titles' },
                  { label: t('Other'), bg: 'bg-background' },
                ]}
              />
            </div>
          </div>
        </Card>
      </div>
      <div className={`${hasData && 'hidden'}`}>
        <Card icon={<NoteIcon />} title={t('Totals')}>
          <div className="mt-10 flex flex-grow flex-col justify-between text-text">
            Thre is currently no document totals data to display.
          </div>
        </Card>
      </div>
    </>
  );
};
