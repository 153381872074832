import React, { FC, memo, SVGProps } from 'react';

export const NoteIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.64051 13.6092C6.31681 13.6092 6.05469 13.3366 6.05469 12.9999C6.05469 12.6633 6.31681 12.3907 6.64051 12.3907H18.3592C18.6829 12.3907 18.945 12.6633 18.945 12.9999C18.945 13.3366 18.6829 13.6092 18.3592 13.6092H6.64051ZM6.64051 18.4844C6.31681 18.4844 6.05469 18.2115 6.05469 17.8748C6.05469 17.5385 6.31681 17.2656 6.64051 17.2656H18.3592C18.6829 17.2656 18.945 17.5385 18.945 17.8748C18.945 18.2115 18.6829 18.4844 18.3592 18.4844H6.64051ZM6.64051 8.73442C6.31681 8.73442 6.05469 8.46153 6.05469 8.12516C6.05469 7.78851 6.31681 7.51562 6.64051 7.51562H18.3592C18.6829 7.51562 18.945 7.78851 18.945 8.12516C18.945 8.46153 18.6829 8.73442 18.3592 8.73442H6.64051Z"
      fill="currentColor"
    />
    <path
      d="M1.46649 1.21882C1.30382 1.21882 1.17194 1.3571 1.17194 1.52515V24.4745C1.17194 24.6437 1.3049 24.7808 1.46649 24.7808H23.5332C23.6959 24.7808 23.8277 24.6426 23.8277 24.4745V1.52515C23.8277 1.35597 23.6948 1.21882 23.5332 1.21882H1.46649ZM1.17194 0H23.8281C24.4755 0 25 0.545496 25 1.21882V24.7812C25 25.4545 24.4755 26 23.8281 26H1.17194C0.524537 26 0 25.4545 0 24.7812V1.21882C0 0.545518 0.524516 0 1.17194 0V0Z"
      fill="currentColor"
    />
  </svg>
));
