import React, { FC, memo, SVGProps } from 'react';

export const SystemsIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 460 450"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio='xMidYMid meet'
    {...props}
  >
<g>
	<path fill="currentColor" d="M156.766,143.002h37.377v25.939h-13.97c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h104.709c4.143,0,7.5-3.357,7.5-7.5
		s-3.357-7.5-7.5-7.5h-13.97v-25.939h37.377c14.728,0,26.709-11.981,26.709-26.709V26.737c0-14.728-11.981-26.709-26.709-26.709
		H156.766c-14.728,0-26.709,11.981-26.709,26.709v89.556C130.057,131.021,142.039,143.002,156.766,143.002z M255.913,168.941h-46.77
		v-25.939h46.77V168.941z M145.057,26.737c0-6.456,5.252-11.709,11.709-11.709H308.29c6.456,0,11.709,5.253,11.709,11.709v89.556
		c0,6.456-5.253,11.709-11.709,11.709H156.766c-6.457,0-11.709-5.253-11.709-11.709V26.737z"/>
	<path fill="currentColor" d="M178.233,281.115H26.709C11.981,281.115,0,293.097,0,307.824v89.556c0,14.728,11.981,26.709,26.709,26.709h37.377v25.939
		h-13.97c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h104.708c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-13.969v-25.939
		h37.377c14.728,0,26.709-11.981,26.709-26.709v-89.556C204.942,293.097,192.96,281.115,178.233,281.115z M125.856,450.028h-46.77
		v-25.939h46.77V450.028z M189.942,397.38c0,6.456-5.252,11.709-11.709,11.709H26.709c-6.457,0-11.709-5.253-11.709-11.709v-89.556
		c0-6.456,5.252-11.709,11.709-11.709h151.524c6.457,0,11.709,5.253,11.709,11.709V397.38z"/>
	<path fill="currentColor" d="M438.348,281.115H286.823c-14.728,0-26.709,11.981-26.709,26.709v89.556c0,14.728,11.981,26.709,26.709,26.709H324.2
		v25.939h-13.969c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h104.708c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-13.969
		v-25.939h37.377c14.728,0,26.709-11.981,26.709-26.709v-89.556C465.057,293.097,453.075,281.115,438.348,281.115z M385.971,450.028
		H339.2v-25.939h46.771V450.028z M450.057,397.38c0,6.456-5.253,11.709-11.709,11.709H286.823c-6.456,0-11.709-5.253-11.709-11.709
		v-89.556c0-6.456,5.253-11.709,11.709-11.709h151.524c6.456,0,11.709,5.253,11.709,11.709V397.38z"/>
	<path fill="currentColor" d="M355.086,258.615c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-26.087c0-4.143-3.357-7.5-7.5-7.5H240.028v-18.587
		c0-4.143-3.357-7.5-7.5-7.5c-4.142,0-7.5,3.357-7.5,7.5v18.587H102.471c-4.142,0-7.5,3.357-7.5,7.5v26.087
		c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-18.587h245.115V258.615z"/>
</g>
  </svg>
));
