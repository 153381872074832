import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { InputWithError, Label, PopupModal } from '../../components/general';
import { Button } from '../../components/general/buttons';
import { useStore } from '../../hooks';
import { LoginIcon, NewPasswordIcon } from '../../icons';

type FormData = {
  password: string;
  confirm_password: string;
};

export const NewPasswordPage = observer(() => {
  const rootStore = useStore();
  const { authStore } = rootStore;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    state.setResetCode(searchParams.get('token') ?? '');
    state.setEmail(searchParams.get('email') ?? '');
  }, []);

  const state = useLocalObservable(() => ({
    isSubmiting: false,
    setIsSubmitting(isSubmiting: boolean) {
      state.isSubmiting = isSubmiting;
    },
    hasFormError: false,
    setHasFormError(hasFormError: boolean) {
      state.hasFormError = hasFormError;
    },
    resetCode: '',
    setResetCode(resetCode: string) {
      state.resetCode = resetCode;
    },
    email: '',
    setEmail(email: string) {
      state.email = email;
    },
  }));

  const { register, handleSubmit, watch } = useForm<FormData>();
  const onSubmit = handleSubmit(async ({ password }) => {
    if (state.isSubmiting) return;
    state.setIsSubmitting(true);
    state.setHasFormError(false);
    try {
      await authStore.setNewPassword(state.resetCode, state.email, password);
    } catch (error) {
      state.setHasFormError(true);
    } finally {
      state.setIsSubmitting(false);
    }
  });

  return (
    <span>
      <PopupModal
        title="Ange Nytt Lösenord"
        titleIcon={<NewPasswordIcon className="mr-2 h-7 w-7" />}
      >
        <div className="flex w-full items-center justify-center">
          <div className="w-full max-w-md">
            {state.hasFormError && (
              <div
                className="mb-7 rounded-md border border-solid border-pink-500 p-3 text-pink-500"
                style={{ backgroundColor: '#EC48990F' }}
              >
                Ett fel inträffade, försök gärna igen.
              </div>
            )}
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-light-rose">
                  Nytt Lösenord
                </Label>
                <InputWithError
                  type="password"
                  className="text-sky placeholder:text-sky w-full rounded-md bg-blocks py-3 pl-3"
                  placeholder="**********"
                  {...register('password', { required: true })}
                />
              </div>
              <div className="mb-5">
                <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-light-rose">
                  Upprepa Lösenordet
                </Label>
                <InputWithError
                  type="password"
                  className="text-sky placeholder:text-sky w-full rounded-md bg-blocks py-3 pl-3"
                  placeholder="**********"
                  {...register('confirm_password', {
                    required: true,
                    validate: (val: string) => {
                      if (watch('password') != val) {
                        return 'Lösenorden matchar inte!';
                      }
                    },
                  })}
                />
              </div>
              <div className="mt-6 text-center">
                <Button
                  isLoading={state.isSubmiting}
                  className="w-full font-extrabold text-popup"
                >
                  Spara Lösenord
                </Button>
              </div>
            </form>
          </div>
        </div>
      </PopupModal>
      <div className="text-sky mt-4 flex justify-center">
        <Link className="flex" to="/">
          <LoginIcon className="mr-2 h-6 w-6" />
          Logga in?
        </Link>
      </div>
    </span>
  );
});
