import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC } from 'react';
import { SourceSystemConfig } from '../../types';
import { SftpFolderSelect } from './SftpFolderSelect';

interface FtpTargetSystemConfig extends SourceSystemConfig {
  uploadPath: string;
}

interface SftpTargetOptionsProps {
  onSave: (options: FtpTargetSystemConfig) => void;
  ftpSystemId?: string;
  defaultData?: any;
}

type LocalState = {
  uploadPath?: string;
  setUploadPath: (path?: string) => void;
};

export const SftpTargetOptions: FC<SftpTargetOptionsProps> = observer(
  ({ ftpSystemId, onSave, defaultData }) => {
    const state = useLocalObservable<LocalState>(() => ({
      uploadPath: undefined,
      setUploadPath(path?: string) {
        state.uploadPath = path;
      },
    }));

    return (
      <>
        <div className="flex flex-col space-y-8">
          <SftpFolderSelect
            title={'Upload location:'}
            ftpSystemId={ftpSystemId}
            defaultPath={defaultData?.uploadPath}
            onFolderSelected={(path) => {
              state.setUploadPath(path);
            }}
          />
          <button
            onClick={() => {
              onSave({
                typeDiscriminator: 0,
                uploadPath: state.uploadPath,
              } as FtpTargetSystemConfig);
            }}
            className="mt-5 flex w-24 items-center justify-center rounded-sm bg-blue-600 p-1 font-semibold text-white transition-colors hover:bg-blue-800 md:mt-7"
          >
            Save
          </button>
        </div>
      </>
    );
  },
);
