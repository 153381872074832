import React, { ButtonHTMLAttributes, forwardRef, useRef } from 'react';
import { PlusIcon } from '../../../icons';
import { SquareButton } from './SquareButton';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  description?: string;
  onClick: () => void;
  className?: string;
}

export const AddButton = forwardRef<HTMLDivElement, ButtonProps>(
  ({ description, onClick, className }, ref) => {
    const localRef = useRef(null);

    return (
      <SquareButton
        ref={ref || localRef}
        className={`bg-titles ${className}`}
        onClick={onClick}
        description={description}
      >
        <PlusIcon className="w-7 text-background" />
      </SquareButton>
    );
  },
);
