import { FINANCE_API_URL } from '../constants';
import { RelationStateData } from '../types/finance/RelationStateData';

export const fetchRelationsStateData = async (
  authStore,
): Promise<RelationStateData> => {
  const promise = authStore.authRequest({
    url: FINANCE_API_URL + 'api/statistics/relationsstatus',
  });
  const { data } = await promise;

  let rejected = 0;
  let active = 0;
  let pending = 0;
  let paused = 0;

  data.map((x) => {
    if (x.status === 'Active') {
      active = x.count;
    } else if (x.status === 'Pending') {
      pending = x.count;
    } else if (x.status === 'Declined') {
      rejected = x.count;
    } else if (x.status === 'Paused') {
      paused = x.count;
    }
  });

  return {
    rejected: rejected,
    pending: pending,
    paused: paused,
    active: active,
  };
};
