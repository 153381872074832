import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import {
  FORTNOX_CLIENT_ID,
  FORTNOX_REDIRECT_URL,
  FORTNOX_SCOPE,
} from '../../../constants';
import { FortnoxIcon } from '../../../icons';
import { Button } from '../../general/buttons';

type FortnoxSetupProps = {
  systemId?: string;
  onFormSubmit?: (id: string) => void;
  parentState?: object;
};

export const FortnoxSetup: FC<FortnoxSetupProps> = observer(
  ({ systemId, parentState }) => {
    const state = useLocalObservable(() => ({
      isSubmiting: false,
      setIsSubmitting(isSubmiting: boolean) {
        state.isSubmiting = isSubmiting;
      },
    }));

    const isEditMode: boolean = systemId !== undefined;

    const { handleSubmit } = useForm<FormData>();

    const onSubmit = handleSubmit(async () => {
      if (state.isSubmiting) return;

      state.setIsSubmitting(true);

      const data = {
        uuid: self.crypto.randomUUID(),
        parentState: parentState,
      };

      localStorage.setItem('OAuthSystemRegister', JSON.stringify(data));
      window.open(
        `https://apps.fortnox.se/oauth-v1/auth?client_id=${FORTNOX_CLIENT_ID}&redirect_uri=${FORTNOX_REDIRECT_URL}&scope=${FORTNOX_SCOPE}&state=${data.uuid}&access_type=offline&response_type=code&account_type=service`,
        '_self',
      );
    });

    return (
      <form onSubmit={onSubmit} className="w-full">
        <div className='mb-4 flex flex-col items-center justify-center'>
          <FortnoxIcon className='h-12' />
          Fortnox
        </div>
        <Button
          className="inline-flex w-full items-center"
          isLoading={state.isSubmiting}
        >
          {isEditMode ? 'Update' : 'Activate'}
        </Button>
      </form>
    );
  },
);
