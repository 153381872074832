import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FadeInDiv, PopupModal } from '../../components/general';
import { ExternalSystemButton } from '../../components/general/buttons';
import { useStore } from '../../hooks';
import { UserPlusIcon } from '../../icons';

export const ExternalSystemInvitationPage = observer(() => {
  const { externalSystemStore, tenantStore } = useStore();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const invitation = externalSystemStore.externalSystemInvitations.find(
    (invitation) => invitation.id === id,
  );

  const handleAccept = async () => {
    invitation?.accept().then(() => navigate('/integrations'));
  };

  const handleDecline = async () => {
    invitation?.decline().then(() => navigate('/integrations'));
  };

  if (!invitation) return null;

  return (
    <FadeInDiv className="flex h-full w-full justify-center">
      <PopupModal
        className="mt-32 w-[27.625rem] justify-start"
        open
        titleIcon={<UserPlusIcon className="w-6" />}
        title={`You have an invitation from ${invitation.externalSystemName}`}
      >
        <div className="flex flex-col items-center justify-center space-y-1">
             <ExternalSystemButton
                showStatus={false}
                disabled
                truncate={false}
                logoUrl={invitation.externalTenantLogoUrl || undefined}
                key={invitation.id}
                description={invitation.externalSystemName}
                externalSystemName={invitation.externalSystemName}
              />
        </div>
        <div className="mt-14 flex flex-col gap-3">
          <button
            className="rounded-lg bg-success p-2 text-xl font-bold text-popup"
            onClick={handleAccept}
          >
            Accept invitation
          </button>
          <button
            className="rounded-lg bg-error p-2 text-xl font-bold text-popup"
            onClick={handleDecline}
          >
            Decline invitation
          </button>
        </div>
      </PopupModal>
    </FadeInDiv>
  );
});
