import React, { FC, memo, SVGProps } from 'react';

export const ClientsIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.942 19.1184C17.9918 19.4232 19.0795 19.5774 20.1727 19.5762C21.9306 19.5788 23.6658 19.178 25.2445 18.4046C25.2912 17.3015 24.9769 16.2133 24.3495 15.3048C23.722 14.3963 22.8155 13.7172 21.7673 13.3703C20.7191 13.0234 19.5865 13.0277 18.5409 13.3825C17.4954 13.7373 16.594 14.4232 15.9734 15.3364M16.942 19.1184V19.1147C16.942 17.7449 16.59 16.4564 15.9734 15.3364M16.942 19.1184V19.2489C14.5735 20.6754 11.8599 21.427 9.0949 21.4223C6.22608 21.4223 3.54187 20.6285 1.25026 19.2489L1.24902 19.1147C1.24808 17.3726 1.82699 15.6797 2.89448 14.3029C3.96198 12.9261 5.45733 11.9438 7.1448 11.5108C8.83227 11.0778 10.6159 11.2187 12.2144 11.9114C13.8129 12.6041 15.1354 13.8091 15.9734 15.3364M13.2498 4.6537C13.2498 5.75533 12.8122 6.81184 12.0332 7.59081C11.2543 8.36978 10.1978 8.8074 9.09613 8.8074C7.9945 8.8074 6.93799 8.36978 6.15902 7.59081C5.38005 6.81184 4.94243 5.75533 4.94243 4.6537C4.94243 3.55207 5.38005 2.49556 6.15902 1.71659C6.93799 0.937621 7.9945 0.5 9.09613 0.5C10.1978 0.5 11.2543 0.937621 12.0332 1.71659C12.8122 2.49556 13.2498 3.55207 13.2498 4.6537ZM23.4033 7.42283C23.4033 8.27965 23.0629 9.10138 22.4571 9.70725C21.8512 10.3131 21.0295 10.6535 20.1727 10.6535C19.3158 10.6535 18.4941 10.3131 17.8882 9.70725C17.2824 9.10138 16.942 8.27965 16.942 7.42283C16.942 6.56601 17.2824 5.74428 17.8882 5.13841C18.4941 4.53255 19.3158 4.19218 20.1727 4.19218C21.0295 4.19218 21.8512 4.53255 22.4571 5.13841C23.0629 5.74428 23.4033 6.56601 23.4033 7.42283Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
