import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ExternalSystemType } from '../../../types';
import { InputWithError, Label } from '../../general';
import { Button } from '../../general/buttons';

/**********************************************/
// Create account at: testoauth.com
// Register redirecturl: http://localhost:3000/addsystem/callback
// Add Client Id to url
/**********************************************/

type FormData = {
  notifyEmail: string;
};

export const TestOauthSetup = observer(() => {
  const state = useLocalObservable(() => ({
    isSubmiting: false,
    setIsSubmitting(isSubmiting: boolean) {
      state.isSubmiting = isSubmiting;
    },

    hasFormError: false,
    setHasFormError(hasFormError: boolean) {
      state.hasFormError = hasFormError;
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async ({ notifyEmail }) => {
    if (state.isSubmiting) return;

    state.setIsSubmitting(true);
    state.setHasFormError(false);

    const data = {
      uuid: self.crypto.randomUUID(),
      system: ExternalSystemType.TestOauth,
      notifyEmail: notifyEmail,
    };

    localStorage.setItem('OAuthSystemRegister', JSON.stringify(data));

    window.open(
      `https://testoauth.com/oauth/authorize?response_type=code&client_id=mMt9FJ0SodpxSc7AxbcmMa1N4slxrNbH&redirect_uri=http://localhost:3000/addsystem/callback&scope=scope123&state=${data.uuid}`,
      '_self',
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="mb-5">
        <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
          Notify email
        </Label>
        <InputWithError
          type="text"
          placeholder="Enter email to notify"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('notifyEmail', {
            required: 'Fill in this field',
          })}
          error={errors.notifyEmail?.message}
        />
      </div>

      <Button
        className="mt-10 w-full bg-background text-xl font-bold"
        isLoading={state.isSubmiting}
      >
        Aktivera Test Oauth
      </Button>
    </form>
  );
});
