import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../hooks/useStore';
import { HarvestLogoIcon } from '../../../icons';
import { FadeInDiv } from '../../general';
import { Button } from '../../general/buttons';

type HarvestFinanceSetupProps = {
  systemId?: string;
  onFormSubmit?: (id: string) => void;
};

export const HarvestFinanceSetup: FC<HarvestFinanceSetupProps> = observer(
  ({ systemId, onFormSubmit }) => {
    const { externalSystemStore } = useStore();
    const state = useLocalObservable(() => ({
      isSubmiting: false,
      setIsSubmitting(isSubmiting: boolean) {
        state.isSubmiting = isSubmiting;
      },
    }));

    const isEditMode: boolean = systemId !== undefined;

    useEffect(() => {
      if (isEditMode) {
        state.setIsSubmitting(false);
      }
    }, []);

    const {
      handleSubmit,
      formState: { errors },
    } = useForm<FormData>();

    const onSubmit = handleSubmit(async () => {
      if (state.isSubmiting) return;

      state.setIsSubmitting(true);
      isEditMode ? updateCurrentSystem(systemId) : addNewSystem();
    });

    const addNewSystem = async () => {
      try {
        const id = await externalSystemStore.AddHarvestFinanceSystem();
        onFormSubmit && onFormSubmit(id);
      } finally {
        state.setIsSubmitting(false);
      }
    };

    const updateCurrentSystem = async (id) => {
      state.setIsSubmitting(false);
    };

    return (
      <FadeInDiv>
        <div className='mb-4 flex flex-col items-center justify-center'>
          <HarvestLogoIcon className='h-12' />
          Harvest
        </div>
        <form onSubmit={onSubmit}>
          <div>{state.isSubmiting && <>Submitting..</>}</div>
          <div>
            <Button
              onClick={onSubmit}
              className="w-full bg-titles text-xl font-bold"
              isLoading={state.isSubmiting}
            >
              <span className="font-semibold">
                {isEditMode ? 'Update' : 'Add'}
              </span>
            </Button>
          </div>
        </form>
      </FadeInDiv>
    );
  },
);
