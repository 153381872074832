import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useStore } from '../../hooks';
import { SftpFolderBrowser } from '../integrationHub';
import { Label } from './Label';

interface SftpTargetConfigurationProps {
  systemId: string;
  uploadPath?: string;
  onUploadPathChange: (path: string) => void;
  onValidation: (success: boolean) => void;
}

interface SftpTargetConfigurationState {
  uploadPath: string;
  setUploadPath: (path: string) => void;
  uploadPathIsSuccessful?: boolean;
  setUploadPathIsSuccessful: (success: boolean) => void;
  isValidating: boolean;
  setIsValidating: (isValidating: boolean) => void;
}

export const SftpTargetConfiguration: FC<SftpTargetConfigurationProps> =
  observer(({ systemId, uploadPath, onUploadPathChange, onValidation }) => {
    const state = useLocalObservable<SftpTargetConfigurationState>(() => ({
      uploadPath: uploadPath || '',
      uploadPathIsSuccessful: undefined,
      isValidating: false,
      setUploadPathIsSuccessful(isSuccess) {
        state.uploadPathIsSuccessful = isSuccess;
        onValidation(isSuccess);
      },
      setUploadPath(path) {
        state.uploadPath = path;
      },
      setIsValidating: (isValidating: boolean) =>
        (state.isValidating = isValidating),
    }));

    const { externalSystemStore } = useStore();

    useEffect(() => {
      if (uploadPath) {
        testAccessForUploadPath(uploadPath);
      }
    }, []);

    const testAccessForUploadPath = async (path: string) => {
      try {
        if (!path) {
          return;
        }

        state.setIsValidating(true);

        externalSystemStore
          .testSftpFolderAccess(systemId, path)
          .then((result) => {
            state.setUploadPathIsSuccessful(result);
          });
      } catch (error) {
        state.setUploadPathIsSuccessful(false);
      }

      state.setIsValidating(false);
    };

    return (
      <div>
        <div>
          <Label
            className="mb-2 inline-block whitespace-nowrap text-xl font-semibold text-labels"
            required
          >
            Destination folder
          </Label>
          <SftpFolderBrowser
            statusColor={
              state.uploadPathIsSuccessful === undefined
                ? 'labels'
                : state.uploadPathIsSuccessful === true
                ? 'success'
                : 'error'
            }
            systemId={systemId}
            value={state.uploadPath || ''}
            onChange={(folder) => {
              state.setUploadPath(folder.fullPath);
              testAccessForUploadPath(folder.fullPath);
              onUploadPathChange(folder.fullPath);
            }}
            isLoading={state.isValidating}
          />
        </div>
      </div>
    );
  });
