import React, { FC, memo, SVGProps } from 'react';

export const RetryIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="currentColor"
  >
    <path d="M9.27991 2.81143V1.12891C9.27991 0.641857 9.81128 0.331911 10.2318 0.597538L14.7703 3.43132C15.1687 3.67485 15.1687 4.25042 14.7703 4.49395L10.2539 7.30563C9.83327 7.57125 9.30205 7.2613 9.30205 6.77425V5.09174H9.25773C5.4719 5.15814 2.35039 8.30184 2.32825 12.0876C2.28393 16.1833 5.73756 19.482 9.87762 19.1942C13.2869 18.9728 16.0986 16.2497 16.4307 12.8404C16.6079 11.0471 16.1208 9.38672 15.1687 8.05835C14.9473 7.74841 15.0359 7.32776 15.368 7.12851L16.2314 6.61936C16.5192 6.44223 16.8956 6.53087 17.0948 6.79649C18.1574 8.30186 18.7773 10.1394 18.7773 12.132C18.7773 17.5339 14.1946 21.8731 8.7041 21.4746C4.07707 21.1423 0.38027 17.4449 0.0256519 12.8843C-0.372769 7.41598 3.92206 2.85537 9.27971 2.81109L9.27991 2.81143Z"/>
  </svg>
));
