import React, { FC } from 'react';
import { FadeInDiv } from './FadeInDiv';

export const FormError: FC = ({ children }) => (
  <FadeInDiv className="relative">
    <div className="absolute w-full px-1 rounded-b-md text-xs text-error">
      {children}
    </div>
  </FadeInDiv>
);
