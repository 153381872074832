export enum TotalsDataEntryType {
  CustomerInvoice = 'customerInvoice',
  CustomerCreditMemo = 'customerCreditMemo',
  Other = 'other',
}

export type TotalsDataEntry = {
  periodIndex: number;
  entityType: TotalsDataEntryType;
  count: number;
};

export type PeriodDates = {
  periodStart: string;
  periodEnd: string;
};
export type PeriodResult = {
  periodToDate: PeriodData;
  period: PeriodData;
};

export type PeriodData = {
  periodDates: PeriodDates;
  subTotals: TotalsDataEntry[];
};

export type TotalsData = {
  currentPeriodData: PeriodResult;
  previousPeriodData: PeriodResult;
};
