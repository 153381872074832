import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  CreateExternalSystemInvitation,
  FadeInDiv,
  Header,
} from '../../components/general';
import { OauthCallback } from '../../components/integrationHub/ExternalSystemSetups';
import { useStore } from '../../hooks';
import { HarvestModules } from '../../types/auth';
import {
  LedgerPage,
  RelationActionPage,
  RelationInvitePage,
  RelationListPage,
} from '../finance';
import { SystemsPage } from '../integrationHub';
import {
  CreateIntegrationPage,
  EditIntegrationPage,
  IntegrationsOverviewPage,
} from '../integrationHub/Integrations';
import { DashboardPage } from './DashboardPage';
import { ExternalSystemInvitationPage } from './ExternalSystemInvitationPage';
import { InvoiceListPage } from './InvoiceListPage';
import { NewPasswordPage } from './NewPasswordPage';
import { PreferencesPage } from './PreferencesPage';

export const LoggedInPage = observer(() => {
  const { authStore } = useStore();
  const hasFinance = authStore.modules.includes(HarvestModules.Finance);
  const hasIntegrations = authStore.modules.includes(
    HarvestModules.Integrations,
  );

  return (
    <FadeInDiv className="relative flex h-screen w-screen flex-1 overflow-hidden ">
      <div className="flex flex-1 flex-col overflow-y-auto">
        <Header />
        <Routes>
          {/* INTEGRATIONS */}
          {hasIntegrations && (
            <>
              <Route path="/systems" element={<SystemsPage />} />
              <Route path="/addsystem/callback" element={<OauthCallback />} />
              <Route path="/invoices" element={<InvoiceListPage />} />
              <Route
                path="/integrations"
                element={<IntegrationsOverviewPage />}
              />
              <Route
                path="/integrations/create"
                element={<CreateIntegrationPage />}
              />
              <Route
                path="/integrations/edit/:id"
                element={<EditIntegrationPage />}
              />
              <Route
                path="/integrations/invitations/:id"
                element={<ExternalSystemInvitationPage />}
              />
              <Route
                path="/relation-invitation"
                element={<CreateExternalSystemInvitation />}
              />
            </>
          )}

          {/* BOTH */}
          <>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/preferences" element={<PreferencesPage />} />
            <Route path="/new-password" element={<NewPasswordPage />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </>

          {/* FINANCE */}
          {hasFinance && (
            <>
              <Route path="/ledger" element={<LedgerPage />} />
              <Route path="/relations" element={<RelationListPage />} />
              <Route
                path="/relations/invite"
                element={<RelationInvitePage />}
              />
              <Route path="/relations/:id" element={<RelationActionPage />} />
            </>
          )}
        </Routes>
      </div>
    </FadeInDiv>
  );
});
