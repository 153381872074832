import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks';
import { ExternalSystemInvitationInstance } from '../../models';
import { PopupModal } from './PopupModal';

interface CreateExternalSystemInvitationState {
  invitation: ExternalSystemInvitationInstance | null;
  setInvitation: (invitation: ExternalSystemInvitationInstance) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

/**
 * Component that creates a new ExternalSystemInvitation when on a specific route.
 */
export const CreateExternalSystemInvitation = observer(() => {
  const navigate = useNavigate();
  const {
    externalSystemStore: { createExternalSystemInvitation },
  } = useStore();

  const searchParams = new URLSearchParams(location.search);
  const tenantId = searchParams.get('tenantId');
  const relationId = searchParams.get('relationId');
  const email = searchParams.get('email');

  const state = useLocalObservable<CreateExternalSystemInvitationState>(() => ({
    invitation: null,
    setInvitation(invitation) {
      state.invitation = invitation;
    },
    isLoading: false,
    setIsLoading(isLoading) {
      state.isLoading = isLoading;
    },
  }));

  useEffect(() => {
    if ([tenantId, relationId, email].includes(null)) return;

    const createInvitation = async () => {
      try {
        state.setIsLoading(true);
        const invitation = await createExternalSystemInvitation({
          tenantId,
          relationId,
          email,
        });
        state.setInvitation(invitation);
      } finally {
        state.setIsLoading(false);
      }
    };
    createInvitation();
  }, []);

  useEffect(() => {
    if (state.invitation)
      navigate(`/integrations/invitations/${state.invitation.id}`);
  }, [state.invitation]);

  if (state.isLoading || state.invitation) return null;

  return (
    <div className="flex h-1/2 w-full items-center justify-center">
      <PopupModal title="Invalid link" className="w-[27rem]" />
    </div>
  );
});
