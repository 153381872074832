import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { FadeInDiv } from './FadeInDiv';

type BannerProps = {
  level?: string;
  className?: string;
  title: string;
  text: string;
  onClose: () => void;
};

export const Banner: FC<BannerProps> = observer(
  ({ className, title, text, onClose }) => {
    if (text.length === 0 && title.length === 0) return null;

    return (
      <FadeInDiv className={className}>
        <div
          className="border-b-1 over relative flex h-12 w-full border border-blocks bg-error px-6 py-3 pr-10 text-background"
          role="alert"
        >
          <div className="flex w-full">
            <strong className="font-bold">
              {title || 'Something went wrong!'}
            </strong>
            <div className="ml-2 sm:inline">{text || 'Please try again.'}</div>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                className="h-6 w-6 rounded-full fill-current text-background hover:bg-background hover:text-error"
                role="button"
                onClick={onClose}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        </div>
      </FadeInDiv>
    );
  },
);
