import React, { FC, memo, SVGProps } from 'react';

export const PlusIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.09 12.24h-6.78v6.52c0 .964-.807 1.74-1.81 1.74-1.003 0-1.81-.776-1.81-1.74v-6.52H1.91C.907 12.24.1 11.464.1 10.5s.807-1.74 1.81-1.74h6.78V2.24c0-.964.807-1.74 1.81-1.74 1.003 0 1.81.776 1.81 1.74v6.52h6.78c1.003 0 1.81.776 1.81 1.74s-.807 1.74-1.81 1.74z"
      clipRule="evenodd"
    ></path>
  </svg>
));
