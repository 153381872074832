import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../hooks/useStore';
import { CheckmarkIcon, PlusIcon, SftpIcon, Spinner } from '../../../icons';
import { FadeInDiv, Label } from '../../general';
import { Button } from '../../general/buttons';
import { InputWithError } from '../../general/InputWithError';

type SftpSetupProps = {
  systemId?: string;
  onFormSubmit?: (id: string) => void;
};

type SftpSetupState = {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isConnecting: boolean;
  setIsConnecting: (isConnection: boolean) => void;
  hasFormError: boolean;
  setHasFormError: (hasFormError: boolean) => void;
};

type FormData = {
  name: string;
  host: string;
  port: number;
  username: string;
  password: string;
};

export const SftpSetup: FC<SftpSetupProps> = observer(
  ({ systemId, onFormSubmit }) => {
    const { externalSystemStore } = useStore();

    const state = useLocalObservable<SftpSetupState>(() => ({
      isSubmitting: false,
      isConnecting: false,
      setIsSubmitting(isSubmiting: boolean) {
        state.isSubmitting = isSubmiting;
      },
      setIsConnecting(isConnecting: boolean) {
        state.isConnecting = isConnecting;
      },
      hasFormError: false,
      setHasFormError(hasFormError: boolean) {
        state.hasFormError = hasFormError;
      },
    }));

    const isEditMode: boolean = systemId !== undefined;

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
      externalSystemStore.clearConnectionError();
      if (isEditMode) {
        state.setIsSubmitting(true);
        try {
          const systemSettings = externalSystemStore.getSftpSystemSetting(
            systemId!,
          );
          systemSettings.then((data) => {
            if (data) setFormValues(data);
          });
        } catch (error) {
          state.setHasFormError(true);
        } finally {
          state.setIsSubmitting(false);
        }
      }
    }, []);

    const setFormValues = (data: any) => {
      setValue('name', data.name);
      setValue('host', data.host);
      setValue('port', data.port);
      setValue('username', data.username);
    };

    const testConnection = handleSubmit(
      async ({ name, host, port, username, password }) => {
        externalSystemStore.clearConnectionError();
        if (state.isSubmitting) return;
        state.setIsConnecting(true);
        try {
          await externalSystemStore.testSftpConnection({
            name,
            host,
            port,
            username,
            password,
          });
        } catch (error) {
          state.setHasFormError(true);
        } finally {
          state.setIsConnecting(false);
        }
      },
    );

    const onSubmit = handleSubmit(
      async ({ name, host, port, username, password }) => {
        if (state.isSubmitting) return;

        state.setIsSubmitting(true);
        state.setHasFormError(false);
        isEditMode
          ? updateCurrentSystem(systemId, name, host, port, username, password)
          : addNewSystem(name, host, port, username, password);
      },
    );

    const addNewSystem = async (name, host, port, username, password) => {
      try {
        const id = await externalSystemStore.createSftpSystem({
          name,
          host,
          port,
          username,
          password,
        });
        onFormSubmit && onFormSubmit(id);
      } catch (error) {
        state.setHasFormError(true);
      } finally {
        state.setIsSubmitting(false);
      }
    };

    const updateCurrentSystem = async (
      id,
      name,
      host,
      port,
      username,
      password,
    ) => {
      try {
        await externalSystemStore.updateSftpSystemSetting(id, {
          name,
          host,
          port,
          username,
          password,
        });
        onFormSubmit && onFormSubmit(id);
      } catch (error) {
        state.setHasFormError(true);
      } finally {
        state.setIsSubmitting(false);
      }
    };

    return (
      <FadeInDiv>
        <div className='mb-4 flex flex-col items-center justify-center'>
          <SftpIcon className='h-12' />
          SFTP
        </div>
        <form>
          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Name
            </Label>
            <InputWithError
              type="text"
              placeholder="my sftp"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('name', {
                required: 'Fill in this field',
              })}
              error={errors.name?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Host
            </Label>
            <InputWithError
              type="text"
              placeholder="Host"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('host', {
                required: 'Fill in this field',
              })}
              error={errors.host?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Port
            </Label>
            <InputWithError
              type="text"
              placeholder="443"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('port', {
                required: 'Fill in this field',
                pattern: {
                  value: /^[0-9]*$/,
                  message:
                    'Port can only contain numbers',
                }
              })}
              error={errors.port?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Username
            </Label>
            <InputWithError
              type="text"
              placeholder="Username"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('username', {
                required: 'Fill in this field',
              })}
              error={errors.username?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Lösenord
            </Label>
            <input
              type="password"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              placeholder="**********"
              {...register('password', {
                required: 'Fill in this field',
              })}
            />
          </div>

          {externalSystemStore.connectionResult &&
            <div className={`mt-5 flex animate-slideUp items-center align-middle justify-center rounded-md mb-5 text-center bg-selectors`}>
              <p className={`${externalSystemStore.connectionResult === 'Connection successful!' ? 'text-success' : 'text-error'}`}>{externalSystemStore.connectionResult}</p>
            </div>}


          <div className="flex-col">
            <Button
              isLoading={state.isConnecting}
              className="bg-text w-full"
              onClick={testConnection}
            >
              Test connection
            </Button>
          </div>

          <div className="mt-2">
            <Button
              className="w-full bg-titles text-xl font-bold"
              onClick={onSubmit}
              isLoading={state.isSubmitting}
            >
              <span className="font-semibold">
                {isEditMode ? 'Update' : 'Add'}
              </span>
            </Button>
          </div>
        </form>
      </FadeInDiv>
    );
  },
);
