import React, { FC } from 'react';

type LabelProps = {
  className?: string;
  htmlFor?: string;
  required?: boolean;
};

export const Label: FC<LabelProps> = ({
  className,
  children,
  htmlFor,
  required = false,
}) => (
  <label
    htmlFor={htmlFor}
    className={className}
  >
    {children} {required && <span className="text-error">*</span>}
  </label>
);
