import React, { FC, memo, SVGProps } from 'react';

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 460 460"
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M63.587,168.913c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5c26.791,0,48.587,21.796,48.587,48.588
		c0,26.791-21.796,48.587-48.587,48.587c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5c32.523,0,59.405-24.549,63.13-56.087H187.5
		c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-60.783C122.992,193.462,96.11,168.913,63.587,168.913z"
      />
      <path
        d="M140.543,35.869c-44.182,0-80.548,34.098-84.161,77.362C24.699,116.82,0,143.786,0,176.413
		c0,24.244,13.64,45.364,33.646,56.087C13.64,243.224,0,264.343,0,288.587c0,32.627,24.699,59.593,56.382,63.182
		c3.613,43.264,39.979,77.362,84.161,77.362c46.569,0,84.457-37.887,84.457-84.457V120.325
		C225,73.756,187.112,35.869,140.543,35.869z M210,189.37c-34.783-3.753-61.956-33.281-61.956-69.045c0-4.142-3.357-7.5-7.5-7.5
		s-7.5,3.358-7.5,7.5c0,44.04,33.889,80.295,76.956,84.105v56.139c-43.067,3.81-76.956,40.065-76.956,84.105
		c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5c0-35.764,27.173-65.292,61.956-69.045v69.045c0,38.298-31.158,69.457-69.457,69.457
		c-38.298,0-69.456-31.158-69.456-69.457c0-4.142-3.357-7.5-7.5-7.5C36.796,337.175,15,315.378,15,288.587
		C15,261.796,36.796,240,63.587,240c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5C36.796,225,15,203.204,15,176.413
		c0-26.791,21.796-48.587,48.587-48.587c4.143,0,7.5-3.358,7.5-7.5c0-38.298,31.158-69.457,69.456-69.457
		c38.299,0,69.457,31.158,69.457,69.457V189.37z"
      />
      <path
        d="M401.413,281.087c-26.791,0-48.587-21.796-48.587-48.587c0-26.791,21.796-48.588,48.587-48.588c4.143,0,7.5-3.358,7.5-7.5
		s-3.357-7.5-7.5-7.5c-32.523,0-59.405,24.549-63.13,56.088H277.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h60.783
		c3.725,31.538,30.607,56.087,63.13,56.087c4.143,0,7.5-3.358,7.5-7.5S405.556,281.087,401.413,281.087z"
      />
      <path
        d="M431.354,232.5C451.36,221.776,465,200.657,465,176.413c0-32.627-24.699-59.593-56.382-63.182
		c-3.613-43.264-39.979-77.362-84.161-77.362c-46.569,0-84.457,37.887-84.457,84.457v224.349c0,46.57,37.888,84.457,84.457,84.457
		c44.182,0,80.548-34.098,84.161-77.362C440.301,348.18,465,321.214,465,288.587C465,264.343,451.36,243.224,431.354,232.5z
		 M401.413,337.175c-4.143,0-7.5,3.358-7.5,7.5c0,38.298-31.158,69.457-69.456,69.457c-38.299,0-69.457-31.158-69.457-69.457V275.63
		c34.783,3.753,61.956,33.281,61.956,69.045c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5c0-44.04-33.889-80.295-76.956-84.105
		v-56.139c43.067-3.81,76.956-40.065,76.956-84.105c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5
		c0,35.764-27.173,65.292-61.956,69.045v-69.045c0-38.298,31.158-69.457,69.457-69.457c38.298,0,69.456,31.158,69.456,69.457
		c0,4.142,3.357,7.5,7.5,7.5c26.791,0,48.587,21.796,48.587,48.587C450,203.204,428.204,225,401.413,225c-4.143,0-7.5,3.358-7.5,7.5
		s3.357,7.5,7.5,7.5C428.204,240,450,261.796,450,288.587C450,315.378,428.204,337.175,401.413,337.175z"
      />
    </g>
  </svg>
));
