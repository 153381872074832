import { applySnapshot, types as t } from 'mobx-state-tree';

export const UIStore = t
  .model('UIStore', {
    isMenuExpanded: window.innerWidth < 768 || window.innerWidth > 1024,
    errorTitle: '',
    errorText: '',
  })
  .volatile(() => ({
    isMobileMenuOpen: false,
    isMobile: window.innerWidth < 768,
    isTablet: 768 <= window.innerWidth && window.innerWidth < 1024,
    isDesktop: 1024 <= window.innerWidth,
    isAddIntegrationOpen: false,
    isSidebarOpen: false,
    isFilterPickerOpen: false,
  }))

  .actions((self) => {
    return {
      checkDeviceResolution() {
        const { isMenuExpanded, isMobile, isTablet, isDesktop } = self;
        const becameMobile = window.innerWidth < 768;
        const becameTablet =
          768 <= window.innerWidth && window.innerWidth < 1024;
        const becameDesktop = 1024 <= window.innerWidth;

        if (isMobile !== becameMobile) {
          self.isMobile = becameMobile;
        }

        if (becameMobile && !isMenuExpanded) {
          self.isMenuExpanded = true;
          self.isTablet = false;
          self.isDesktop = false;
        } else if (!isTablet && becameTablet) {
          self.isMenuExpanded = false;
          self.isTablet = true;
          self.isDesktop = false;
        } else if (!isDesktop && becameDesktop) {
          self.isMenuExpanded = true;
          self.isTablet = false;
          self.isDesktop = true;
        }
      },
      toggleMenuExpanded() {
        self.isMenuExpanded = !self.isMenuExpanded;
      },
      openMobileMenu() {
        self.isMobileMenuOpen = true;
      },
      closeMobileMenu() {
        self.isMobileMenuOpen = false;
      },
      toggleIsAddIntegrationOpen() {
        self.isAddIntegrationOpen = !self.isAddIntegrationOpen;
      },
      toggleIsSidebarOpen() {
        self.isSidebarOpen = !self.isSidebarOpen;
      },
      toggleIsFilterPickerOpen() {
        self.isFilterPickerOpen = !self.isFilterPickerOpen;
      },
      clearError() {
        self.errorText = '';
        self.errorTitle = '';
      },
    };
  })
  .actions((self) => {
    let timeout: number;

    return {
      setError(errorTitle: string, errorText: string) {
        self.errorTitle = errorTitle;
        self.errorText = errorText;

        clearTimeout(timeout);

        timeout = setTimeout(() => {
          self.clearError();
        }, 5000) as any;
      },
    };
  })
  .actions((self) => ({
    afterCreate() {
      window.addEventListener('resize', self.checkDeviceResolution);
    },
    beforeDestroy() {
      window.removeEventListener('resize', self.checkDeviceResolution);
    },
    reset() {
      applySnapshot(self, {
        isMenuExpanded: false,
      });
    },
  }));
