import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import _ from 'lodash';
import { PeriodData, TimeInterval, TotalsDataEntryType } from '../../types';
import { getIntervalLabel } from './getIntervalLabel';

const getEmptyPeriodState = () => ({
  customerInvoice: 0,
  customerCreditMemo: 0,
  other: 0,
});

export const transformPeriodData = (
  periodData: PeriodData,
  interval: TimeInterval,
  isDarkMode: boolean,
  t: (key: string) => string,
) => {
  const periods: {
    [key: string]: {
      customerInvoice: number;
      customerCreditMemo: number;
      other: number;
    };
  } = {};

  const indexDateMapper = (index: number) => {
    const date = new Date(periodData.periodDates.periodStart);

    switch (interval) {
      case TimeInterval.Day:
        return addHours(date, index);
      case TimeInterval.Month:
        return addDays(date, index - 1);
      case TimeInterval.Year:
        return addMonths(date, index - 1);
    }
  };

  const now = new Date();

  switch (interval) {
    case TimeInterval.Day: {
      const startDay = startOfDay(now);
      const endDay = endOfDay(now);

      for (let time = startDay; time < endDay; time = addHours(time, 1)) {
        periods[time.toString()] = getEmptyPeriodState();
      }
      break;
    }
    case TimeInterval.Month: {
      const startMonth = startOfMonth(now);
      const endMonth = endOfMonth(now);

      for (let time = startMonth; time < endMonth; time = addDays(time, 1)) {
        periods[time.toString()] = getEmptyPeriodState();
      }
      break;
    }
    case TimeInterval.Year: {
      const startYear = startOfYear(now);
      const endYear = endOfYear(now);

      for (let time = startYear; time < endYear; time = addMonths(time, 1)) {
        periods[time.toString()] = getEmptyPeriodState();
      }
      break;
    }
  }

  periodData.subTotals.forEach((subTotal) => {
    const mapped = indexDateMapper(subTotal.periodIndex)!.toString();
    if (!(mapped in periods)) return;

    const entityType = Object.values(TotalsDataEntryType).includes(
      _.camelCase(subTotal.entityType) as TotalsDataEntryType,
    )
      ? _.camelCase(subTotal.entityType)
      : 'other';

    periods[mapped][entityType] += subTotal.count;
  });

  const values = Object.values(periods);

  return {
    labels: Object.keys(periods).map((key) => getIntervalLabel(key, interval)),
    datasets: [
      {
        label: t('Invoices'),
        data: values.map((v) => v.customerInvoice),
        backgroundColor: isDarkMode
          ? 'rgb(252, 212, 230)'
          : 'rgb(252, 212, 230)',
        barPercentage: 0.95,
        categoryPercentage: 1,
      },
      {
        label: t('Credit memos'),
        data: values.map((v) => v.customerCreditMemo),
        backgroundColor: isDarkMode ? 'rgb(255, 171, 201)' : 'rgb(3, 3, 28)',
        barPercentage: 0.95,
        categoryPercentage: 1,
      },
      {
        label: t('Other'),
        data: values.map((v) => v.other),
        backgroundColor: isDarkMode ? 'rgb(3, 3, 28)' : 'rgb(233, 242, 255)',
        barPercentage: 0.95,
        categoryPercentage: 1,
      },
    ],
  };
};
