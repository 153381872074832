import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { InputWithError, Label, PopupModal } from '../../components/general';
import { Button } from '../../components/general/buttons';
import { useStore } from '../../hooks';
import { CheckmarkIcon, LoginIcon, UserPlusIcon } from '../../icons';
import { Buffer } from 'buffer';
import { StateChangeTypes } from 'downshift';

type FormData = {
  email: string;
  password: string;
  rememberEmail: string;
};

export const LoginPage = observer(() => {
  const rootStore = useStore();
  const { authStore } = rootStore;
  const location = useLocation();

  const state = useLocalObservable(() => ({
    isSubmitting: false,
    setIsSubmitting(isSubmiting: boolean) {
      state.isSubmitting = isSubmiting;
    },

    rememberEmail: '',
    setRememberEmail(rememberEmail: string) {
      state.rememberEmail = rememberEmail;
      localStorage.setItem('rememberEmail', rememberEmail);
    },

    hasFormError: false,
    setHasFormError(hasFormError: boolean) {
      state.hasFormError = hasFormError;
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit(async ({ email, password, rememberEmail }) => {
    if (state.isSubmitting) return;
    state.setIsSubmitting(true);
    state.setHasFormError(false);
    if (rememberEmail) {
      state.setRememberEmail(email.toLowerCase());
    } else {
      localStorage.removeItem('rememberEmail');
    }

    try {
      await authStore.logIn(email.toLowerCase(), password);
    } catch (error) {
      state.setHasFormError(true);
    } finally {
      state.setIsSubmitting(false);
    }
  });

  const getRedirectQuery = () => {
    if (!location.search) {
      return '';
    }
    const redirectPath = location.pathname + location.search;
    const redirectBase64Path = Buffer.from(redirectPath, 'utf8').toString(
      'base64',
    );
    return `?redirect=${redirectBase64Path}`;
  };

  const redirectQuery = getRedirectQuery();

  return (
    <div>
      <PopupModal
        title="Log in"
        titleIcon={<LoginIcon className="mr-2 h-7 w-7" />}
        className="max-w-md sm:w-md"
      >
        <div className="flex w-full items-center justify-center">
          <div className="w-full">
            {state.hasFormError && (
              <div
                className="mb-7 rounded-md border border-solid border-pink-500 p-3 text-pink-500"
                style={{ backgroundColor: '#EC48990F' }}
              >
              {`We couldn't find your account. Please try again or`}
              <Link to={`/register` + redirectQuery}> <span className='underline'>create a new account</span>.</Link>
              </div>
            )}
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <Label className="text-light-blue mb-2 inline-block whitespace-nowrap font-semibold">
                  Email
                </Label>
                <InputWithError
                  type="text"
                  defaultValue={localStorage.getItem('rememberEmail') || ''}
                  placeholder="namn.namnsson@e-post.se"
                  {...register('email', {
                    required: 'Fill in this field',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Ange en giltig e-post',
                    },
                  })}
                  error={errors.email?.message}
                  className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                />
              </div>
              <div>
                <Label className="text-light-blue mb-2 inline-block whitespace-nowrap font-semibold">
                  Password
                </Label>
                <input
                  type="password"
                  className="text-sky placeholder:text-sky w-full rounded-md bg-blocks py-3 pl-3"
                  placeholder="**********"
                  {...register('password', { required: true })}
                />
              </div>
              <div className="mt-6 text-center">
                <Button
                  isLoading={state.isSubmitting}
                  className="w-full font-extrabold text-popup"
                >
                  Log in
                </Button>
              </div>
              <div className="text-sky mt-4 flex items-center justify-between text-sm">
                <div className="relative inline-flex items-center">
                  <Label
                    htmlFor="remember-me"
                    className="flex cursor-pointer items-center"
                  >
                    <input
                      {...register('rememberEmail')}
                      defaultChecked={
                        localStorage.getItem('rememberEmail') ? true : false
                      }
                      type="checkbox"
                      id="remember-me"
                      className="peer relative mr-2 h-6 w-6 appearance-none rounded-md bg-blocks"
                    />
                    <CheckmarkIcon className="z-5 text-sky invisible absolute -top-0 h-6 w-6 peer-checked:visible" />
                    <div>Remember me</div>
                  </Label>
                </div>
                <div className="relative">
                  <Link to="/forgot-password">Forgot your password?</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </PopupModal>
      <Link
        to={`/register` + redirectQuery}
        className="mt-5 flex items-center justify-center space-x-2 font-light text-text"
      >
        <UserPlusIcon className="w-6" />
        <span>Create a new account?</span>
      </Link>
    </div>
  );
});
