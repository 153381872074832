import React from 'react';
import ReactDOM from 'react-dom';

import './styles/tailwind.less';
import './styles/globals.less';
import { BrowserRouter as Router } from 'react-router-dom';
import { onSnapshot } from 'mobx-state-tree';
import './config/i18n';
import { RootStore } from './models';
import { StoreProvider } from './hooks';
import App from '../App';

const emptyState = {
  logStore: {},
  integrationEntityStore: {},
  integrationStore: {},
  externalSystemStore: {},
  authStore: {},
  uiStore: {},
  statisticsStore: {},
  settingsStore: {},
  relationStore: {},
  tenantStore: {},
  customerInvoiceStore: {}
};
const initialState = {
  ...emptyState,
};

const savedAuthStore = localStorage.getItem('authStore');
if (savedAuthStore) {
  initialState.authStore = JSON.parse(savedAuthStore);
}

const savedSettingsStore = localStorage.getItem('userSettings');
if (savedSettingsStore) {
  initialState.settingsStore = JSON.parse(savedSettingsStore);
}

let rootStore;
try {
  rootStore = RootStore.create(initialState);
} catch (error) {
  // Creating the RootStore instance can fail if it is given
  // broken data. This can happen if the authStore snapshot in
  // localStorage is out of date. If this happens we fall back
  // to the empty state.
  rootStore = RootStore.create(emptyState);
}

// The rootStore is initialized automatically on login, but
// if the user is already logged in we initialize it manually.
if (rootStore.authStore.isLoggedIn) {
  rootStore.init();
}

onSnapshot(rootStore.settingsStore, (newSnapshot) => {
  localStorage.setItem('userSettings', JSON.stringify(newSnapshot));
});

onSnapshot(rootStore.authStore, (newSnapshot) => {
  localStorage.setItem('authStore', JSON.stringify(newSnapshot));
});

if (rootStore.authStore.isLoggedIn) {
  rootStore.init();
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <Router>
        <App />
      </Router>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
