import { runInAction, toJS } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FadeInDiv,
  Label,
  NotFound,
  PopupModal,
  SftpSourceConfiguration,
  SftpTargetConfiguration,
  ToggleSlider,
} from '../../../components/general';
import {
  Button,
  ExternalSystemButton,
} from '../../../components/general/buttons';
import { useStore } from '../../../hooks/useStore';
import { ArrowRightIcon, IntegrationIcon } from '../../../icons';
import {
  ExternalSystemInstance,
  IntegrationInstance,
  PatchableIntegrationInstance,
} from '../../../models';
import {
  ExternalSystemType,
  IntegrationEntityType,
  IntegrationState,
  JsonPatchType,
} from '../../../types';

export const EditIntegrationPage = observer(() => {
  const { id } = useParams();
  const { integrationStore } = useStore();

  useEffect(() => {
    const fetchDataInParallel = async () => {
      const promises = [integrationStore.fetchIntegrations()];

      await Promise.all(promises);
    };

    fetchDataInParallel();
  }, []);

  if (integrationStore.integrations === null) {
    return null;
  }

  const integration = integrationStore.integrations?.find(
    (x) => x.id === id && !x.isArchived(),
  );

  if (!integration) {
    return <NotFound />;
  }

  return <EditIntegration integration={integration} />;
});

interface EditIntegrationState {
  name: string;
  state: IntegrationState;
  notifyEmailAddress: string | null;
  entityType?: IntegrationEntityType;
  sourceSystemType?: ExternalSystemType;
  targetSystemType?: ExternalSystemType;

  setName: (name: string) => void;
  setState: (state: IntegrationState) => void;
  setSourceSystemType: (systemType: ExternalSystemType) => void;
  setTargetSystemType: (systemType: ExternalSystemType) => void;
  setNotifyEmailAddress: (emailAddress: string) => void;
  handleClose: () => void;
}

const EditIntegration = observer(
  ({ integration }: { integration: IntegrationInstance }) => {
    const blueprintCreation = integration.metadata.find(
      (x) => x.type === 'BlueprintCreation',
    );

    const { uiStore, integrationStore, externalSystemStore } = useStore();
    const navigate = useNavigate();
    const [formValid, setFormValid] = useState<boolean>(true);
    const [showConfirmWindow, setShowConfirmWindow] = useState<boolean>(false);
    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const [isReaderConfigValid, setIsReaderConfigValid] =
      useState<boolean>(false);
    const [isWriterConfigValid, setIsWriterConfigValid] =
      useState<boolean>(false);
    const [readerSystemInputs, setReaderSystemInputs] = useState<
      Record<string, string>
    >(blueprintCreation.readerSystemInputs);
    const [writerSystemInputs, setWriterSystemInputs] = useState<
      Record<string, string>
    >(blueprintCreation.writerSystemInputs);

    const validateForm = (focus: boolean) => {
      try {
        setFormValid(false);
        const { name, notifyEmailAddress } = state;

        if (!name) {
          if (focus) nameRef?.current?.focus();
          return false;
        }

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (notifyEmailAddress && !notifyEmailAddress.match(emailRegex)) {
          if (focus) emailRef?.current?.focus();
          return false;
        }

        if (
          targetSystem?.externalSystemType === ExternalSystemType.SFTP &&
          !isWriterConfigValid
        ) {
          return false;
        }
        if (
          sourceSystem?.externalSystemType === ExternalSystemType.SFTP &&
          !isReaderConfigValid
        ) {
          return false;
        }

        setFormValid(true);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    useEffect(() => {
      validateForm(true);
    }, [isWriterConfigValid, isReaderConfigValid]);

    useEffect(() => {
      const fetchDataInParallel = async () => {
        const promises = [externalSystemStore.fetchSystems()];

        await Promise.all(promises);
      };

      fetchDataInParallel();
      validateForm(true);
    }, []);

    const sourceSystem = externalSystemStore.externalSystems?.find(
      (system: ExternalSystemInstance) =>
        system.id === blueprintCreation?.readerSystemId,
    );
    const targetSystem = externalSystemStore.externalSystems?.find(
      (system: ExternalSystemInstance) =>
        system.id === blueprintCreation?.writerSystemId,
    );

    const state = useLocalObservable<EditIntegrationState>(() => ({
      name: integration.name,
      state: integration.state as IntegrationState,
      notifyEmailAddress: integration.notifyEmailAddress,
      entityType: blueprintCreation?.entityType as IntegrationEntityType,
      sourceSystemType: undefined,
      targetSystemType: undefined,

      setName(name: string) {
        state.name = name;
      },
      setState(newState: IntegrationState) {
        state.state = newState;
      },
      setSourceSystemType(systemType: ExternalSystemType) {
        runInAction(() => {
          this.sourceSystemType = systemType;
        });
      },
      setTargetSystemType(systemType: ExternalSystemType) {
        runInAction(() => {
          this.targetSystemType = systemType;
        });
      },
      setNotifyEmailAddress(emailAddress: string) {
        state.notifyEmailAddress = emailAddress;
      },
      handleClose() {
        uiStore.toggleIsAddIntegrationOpen();
      },
    }));

    state.setSourceSystemType(
      sourceSystem?.externalSystemType as ExternalSystemType,
    );
    state.setTargetSystemType(
      targetSystem?.externalSystemType as ExternalSystemType,
    );

    const onSubmit = async (e: any) => {
      e.preventDefault();
      if (!validateForm(true)) {
        return;
      }

      const newMetadata = {
        ...[integration.metadata],
        ...[
          {
            ...blueprintCreation,
            readerSystemInputs: readerSystemInputs,
            writerSystemInputs: writerSystemInputs,
          },
        ],
      };

      const updatedIntegration: PatchableIntegrationInstance = {
        name: state.name,
        state: state.state,
        notifyEmailAddress: state.notifyEmailAddress,
        metadata: Object.values(newMetadata)
      };

      const updatedFields = new Array<JsonPatchType>();

      let key: keyof PatchableIntegrationInstance;

      for (key in updatedIntegration) {
        if (updatedIntegration[key] !== integration[key]) {
          updatedFields.push({
            op: 'replace',
            path: key,
            value: updatedIntegration[key],
          });
        }
      }
      // Nothing changed
      if (updatedFields.length == 0) {
        navigate('/integrations');
        return;
      }
      await integrationStore.editIntegration(integration, updatedFields);
      navigate('/integrations');
    };

    return (
      <FadeInDiv className="mt-32 flex items-center justify-center">
        <PopupModal
          className="w-[442px]"
          titleIcon={<IntegrationIcon className="h-6" />}
          title="Are you sure you want to edit this integration?"
          open={!showConfirmWindow}
          canClose={true}
          onClose={() => navigate('/integrations')}
        >
          <div className="flex flex-wrap rounded-lg">
            <form onBlur={() => validateForm(false)}>
              <div className="flex items-center justify-between pr-2">
                <Label className="mb-4 font-semibold">Active</Label>
                <ToggleSlider
                  statusColors={true}
                  checked={integration.state != 'Disabled'}
                  onActive={() => state.setState(IntegrationState.Enabled)}
                  onDisabled={() => state.setState(IntegrationState.Disabled)}
                />
              </div>
              <div className="mb-5 mt-5">
                <Label
                  className="mb-2 inline-block whitespace-nowrap font-semibold text-labels"
                  required
                >
                  Name
                </Label>
                <input
                  type="text"
                  ref={nameRef}
                  name="name"
                  value={state.name}
                  placeholder="Name your integration"
                  onChange={(e) => {
                    state.setName(e.target.value), validateForm(false);
                  }}
                  className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                />
              </div>

              <div className="mb-5">
                <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
                  Notifications email
                </Label>
                <input
                  type="text"
                  name="email"
                  ref={emailRef}
                  value={state.notifyEmailAddress ?? ''}
                  placeholder="user@domain.com"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  onChange={(e) => {
                    state.setNotifyEmailAddress(e.target.value),
                      validateForm(false);
                  }}
                  className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                />
              </div>

              <div className="mb-5 opacity-50">
                <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
                  Type
                </Label>
                <input
                  type="text"
                  name="entityType"
                  value={state?.entityType}
                  placeholder="Entity Type"
                  className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                  disabled={true}
                  readOnly={true}
                />
              </div>

              <div className="mt-10 flex justify-between">
                <div className="mb-2 flex w-28 flex-col items-start whitespace-nowrap font-bold text-labels">
                  <h3 className="text-left text-xl font-bold">Source</h3>

                  <div className="mt-5 opacity-50">
                    <ExternalSystemButton
                      logoUrl={sourceSystem?.systemLogo || undefined}
                      description={sourceSystem?.name}
                      disabled={true}
                      externalSystemType={
                        state?.sourceSystemType as ExternalSystemType
                      }
                      externalSystemName={sourceSystem?.name || ''}
                    ></ExternalSystemButton>
                  </div>
                </div>
                <ArrowRightIcon className="mt-4 w-9 text-titles" />
                <div className="mb-2 flex w-28 flex-col items-end whitespace-nowrap font-semibold text-labels">
                  <h3 className="text-right text-xl font-bold">Destination</h3>
                  <div className="mt-5 opacity-50">
                    <ExternalSystemButton
                      logoUrl={targetSystem?.systemLogo || undefined}
                      description={targetSystem?.name}
                      disabled={true}
                      externalSystemType={
                        state?.targetSystemType as ExternalSystemType
                      }
                      externalSystemName={targetSystem?.name || ''}
                    />
                  </div>
                </div>
              </div>
              {/* {sourceSystem?.externalSystemType === ExternalSystemType.SFTP && ( */}
              {sourceSystem?.externalSystemType === ExternalSystemType.SFTP && (
                <div className="-mx-8 mt-4 rounded-md bg-selectors p-8">
                  <SftpSourceConfiguration
                    downloadPath={readerSystemInputs.ListPath}
                    processedPath={readerSystemInputs.MovePath}
                    systemId={sourceSystem.id}
                    onDownloadPathChange={(folder) => {
                      setReaderSystemInputs({
                        MovePath: readerSystemInputs.MovePath,
                        ListPath: folder,
                      });
                    }}
                    onProcessedPathChange={(folder) => {
                      setReaderSystemInputs({
                        MovePath: folder,
                        ListPath: readerSystemInputs.ListPath,
                      });
                    }}
                    onValidation={(isValid: boolean) => {
                      setIsReaderConfigValid(isValid);
                    }}
                  />
                </div>
              )}
              {targetSystem?.externalSystemType === ExternalSystemType.SFTP && (
                <div className="-mx-8 mt-4 rounded-md bg-selectors p-8">
                  <SftpTargetConfiguration
                    uploadPath={writerSystemInputs?.Path}
                    systemId={targetSystem.id}
                    onValidation={(isValid: boolean) => {
                      setIsWriterConfigValid(isValid);
                    }}
                    onUploadPathChange={(folder) =>
                      setWriterSystemInputs({
                        Path: folder,
                      })
                    }
                  />
                </div>
              )}

              <Button
                className={`mt-10 w-full bg-warning text-xl font-bold`}
                onClick={() => {
                  setShowConfirmWindow(true);
                }}
              >
                Delete integration
              </Button>
              <Button
                disabled={!formValid}
                onClick={onSubmit}
                className={`mt-2 w-full text-xl font-bold`}
              >
                Save Changes
              </Button>
            </form>
          </div>
        </PopupModal>
        <PopupModal
          titleIcon={<IntegrationIcon className="h-4" />}
          className="w-[442px]"
          title="Are you sure you want to delete this integration?"
          canClose={true}
          onClose={() => setShowConfirmWindow(false)}
          open={showConfirmWindow}
        >
          <Button
            className="w-full bg-error"
            onClick={() => {
              integrationStore.editIntegration(integration, [
                { op: 'replace', path: 'state', value: 'Archived' },
              ]);
              navigate('/integrations');
            }}
          >
            Yes, delete it forever!
          </Button>
          <Button
            onClick={() => setShowConfirmWindow(false)}
            className="mt-2 w-full bg-success"
          >
            Cancel
          </Button>
        </PopupModal>
      </FadeInDiv>
    );
  },
);
