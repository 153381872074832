import React, { FC, memo, SVGProps } from 'react';

export const LoginIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg 
    width="21" 
    height="21" 
    viewBox="0 0 21 21" 
    fill="currentColor" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path 
      d="M9 9.5V6.5L14 10.5L9 14.5V11.5H0V9.5H9ZM1.458 13.5H3.582C4.28005 15.2191 5.55371 16.6422 7.18512 17.5259C8.81652 18.4097 10.7043 18.6991 12.5255 18.3447C14.3468 17.9904 15.9883 17.0142 17.1693 15.5832C18.3503 14.1523 18.9975 12.3554 19 10.5C19.001 8.64266 18.3558 6.84283 17.1749 5.40922C15.994 3.97561 14.3511 2.99756 12.528 2.64281C10.7048 2.28807 8.81505 2.57874 7.18278 3.46498C5.55051 4.35121 4.27747 5.77778 3.582 7.5H1.458C2.732 3.443 6.522 0.5 11 0.5C16.523 0.5 21 4.977 21 10.5C21 16.023 16.523 20.5 11 20.5C6.522 20.5 2.732 17.557 1.458 13.5Z" 
    />
  </svg>
));
