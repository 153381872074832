import React, { FC, memo, SVGProps } from 'react';

export const Logo: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 350 400"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,416.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M0 4089 c0 -79 68 -345 351 -1374 541 -1962 785 -2565 1040 -2565 81
0 144 50 223 179 174 284 350 891 746 2575 77 323 141 590 143 592 2 2 72 -82
157 -187 147 -182 180 -212 187 -169 2 8 -70 105 -159 215 -90 111 -170 212
-179 225 l-16 24 -249 -105 c-241 -102 -284 -128 -241 -145 8 -3 110 35 227
85 116 50 215 91 219 91 15 0 -388 -1682 -510 -2130 -197 -722 -345 -1088
-478 -1177 -212 -143 -451 364 -885 1877 -118 413 -317 1138 -461 1680 -53
201 -101 369 -106 375 -5 5 -9 -21 -9 -66z"
      />
      <path
        d="M1250 2585 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
      />
      <path
        d="M1073 2525 c12 -36 26 -65 32 -65 6 0 20 29 32 65 15 47 18 65 9 65
-7 0 -19 -19 -26 -42 l-13 -43 -13 30 c-7 17 -13 36 -14 43 0 6 -6 12 -14 12
-11 0 -10 -12 7 -65z"
      />
      <path
        d="M1170 2509 c0 -37 4 -49 15 -49 11 0 15 11 15 36 0 27 5 37 20 41 32
8 23 21 -15 21 l-35 0 0 -49z"
      />
      <path
        d="M1250 2510 c0 -38 4 -50 15 -50 11 0 15 12 15 50 0 38 -4 50 -15 50
-11 0 -15 -12 -15 -50z"
      />
      <path
        d="M1350 2529 c0 -47 16 -68 50 -67 29 1 30 3 30 50 0 56 -17 65 -22 11
-2 -21 -7 -38 -13 -38 -5 0 -11 17 -13 38 -4 47 -32 53 -32 6z"
      />
      <path
        d="M1460 2490 c0 -56 3 -70 15 -70 9 0 15 9 15 23 0 18 5 23 23 23 20
-1 22 4 22 44 0 45 0 45 -37 47 l-38 2 0 -69z m60 20 c0 -10 -5 -22 -10 -25
-13 -8 -24 20 -16 41 8 20 26 9 26 -16z"
      />
      <path
        d="M1560 2490 c0 -56 3 -70 15 -70 9 0 15 9 15 23 0 18 5 23 23 23 20
-1 22 4 22 44 0 45 0 45 -37 47 l-38 2 0 -69z m60 20 c0 -10 -5 -22 -10 -25
-13 -8 -24 20 -16 41 8 20 26 9 26 -16z"
      />
    </g>
  </svg>
));
