import React, { FC, memo, SVGProps } from 'react';

export const CheckmarkIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
<svg version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" fill='currentColor' {...props}>
  <path d="M 529.2 145.2 h -15.7 l -215.6 215.6 l -100.8 -100.8 h -15.7 l -79.5 79 v 15.7 l 188.2 188.2 h 15.7 l 302.4 -302.4 v -15.7 z"/>
</svg>
));






