import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { FINANCE_API_URL } from '../constants';
import { withRequest } from '../extensions';
import { PageInstance, Paging } from './Paging';
import { Relation } from './Relation';

export const RelationStore = t
  .model('RelationStore', {
    relation: t.maybeNull(Relation),
    pagedRelations: t.maybeNull(t.array(Relation)),
    pageInfo: t.maybeNull(Paging),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    return {
      fetchRelation: flow(function* (id: string) {
        const { data } = yield request({
          method: 'GET',
          url: `${FINANCE_API_URL}api/relations/${id}`,
        });

        self.relation = data;
      }),
      fetchRelations: flow(function* (page = 1, pageSize = 20) {
        const { data } = yield request({
          method: 'GET',
          url: `${FINANCE_API_URL}api/relations`,
          params: {
            page: page,
            pageSize: pageSize,
          },
        });

        const pageResult: PageInstance = {
          currentPage: data.currentPage,
          totalPages: data.totalPages,
          pageSize: data.pageSize,
          totalItems: data.totalItems,
        };

        self.pagedRelations = data.items;
        self.pageInfo = pageResult;
      }),
      invite: flow(function* (
        orgNo: string,
        contactName: string,
        companyName: string,
        email: string,
        phoneNumber: string,
      ) {
        yield request({
          method: 'POST',
          url: `${FINANCE_API_URL}api/relations/invite`,
          data: {
            orgNo: orgNo,
            contactName: contactName,
            companyName: companyName,
            email: email,
            phoneNumber: phoneNumber,
          },
        });
      }),
      reinvite: flow(function* (id: string) {
        yield request({
          method: 'POST',
          url: `${FINANCE_API_URL}api/relations/${id}/reinvite`,
        });
      }),
      revokeInvite: flow(function* (id: string) {
        yield request({
          method: 'DELETE',
          url: `${FINANCE_API_URL}api/relations/${id}/invite`,
        });
      }),
      resume: flow(function* (id: string) {
        yield request({
          method: 'PATCH',
          url: `${FINANCE_API_URL}api/relations/${id}/resume`,
        });
      }),
      pause: flow(function* (id: string) {
        yield request({
          method: 'PATCH',
          url: `${FINANCE_API_URL}api/relations/${id}/pause`,
        });
      }),
      archive: flow(function* (id: string) {
        yield request({
          method: 'DELETE',
          url: `${FINANCE_API_URL}api/relations/${id}`,
        });
      }),
      reset() {
        applySnapshot(self, {});
      },
    };
  });
