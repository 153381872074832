import { MONTHS } from '../../constants';
import { TimeInterval } from '../../types';

export const formatInterval = (
  t: (key: string) => string,
  interval: TimeInterval,
  value: string | number,
) => {
  if (interval === TimeInterval.Year) return t(MONTHS[value]).substring(0, 3);

  return (parseInt(value.toString()) + 1).toString();
};
