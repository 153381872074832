import { Instance, types as t } from 'mobx-state-tree';
import { ExternalSystemType, IsoDate } from '../types';

export const ExternalSystem = t.model({
  id: t.identifier,
  name: t.string,
  externalSystemType: t.maybeNull(
    t.enumeration(Object.keys(ExternalSystemType)),
  ),
  createdAt: IsoDate,
  systemLogo: t.maybeNull(t.string)
});

export type ExternalSystemInstance = Instance<typeof ExternalSystem>;
