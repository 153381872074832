import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useStore } from '../../hooks';
import { Folder, OpenFolder } from '../../icons';

type Folder = {
  name: string;
  fullPath: string;
  selected?: boolean;
};

type FolderView = {
  folders?: Folder[];
};

interface SelectInterface {
  folderView?: FolderView;
  setFolderView: (folderview: FolderView) => void;
  select: (folder: Folder) => void;
}

interface FolderSelectProps {
  title?: string;
  ftpSystemId?: string;
  onFolderSelected: (folderFullPath?: string) => void;
  defaultPath?: string;
}

export const SftpFolderSelect: FC<FolderSelectProps> = observer(
  ({ title, ftpSystemId, onFolderSelected, defaultPath }) => {
    const { externalSystemStore } = useStore();

    const state = useLocalObservable<SelectInterface>(() => ({
      folderView: { folders: Array.from([]) },
      setFolderView(folderview: FolderView) {
        this.folderView = folderview;
      },
      select(folder: Folder) {
        if (!this.folderView?.folders) return;

        this.folderView.folders.forEach((item) => {
          item.selected = false;
        });
        onFolderSelected(folder.fullPath);
        folder.selected = true;
      },
    }));

    useEffect(() => {
      openFolder(defaultPath ?? '.');
    }, []);

    const openFolder = async (path) => {
      const data = await externalSystemStore.listSftpDir(
        ftpSystemId || '',
        encodeURIComponent(path),
      );

      const folderView = { folders: [] } as FolderView;
      data.folders
        ?.sort((a, b) => a.name > b.name)
        .forEach((folder) => {
          folderView.folders!.push({
            fullPath: folder.fullPath,
            name: folder.name,
            selected: folder.name === '.' ? true : false,
          });
        });
      onFolderSelected(folderView?.folders?.find((x) => x.selected)?.fullPath);
      state.setFolderView(folderView);
    };

    return (
      <div className="flex flex-col">
        <div className="hidden whitespace-nowrap font-bold text-blue-900 md:block">
          {title}
        </div>
        <div className="w-96 bg-white text-gray-900">
          {state.folderView?.folders?.map((item, i) => (
            <div key={i} className="flex flex-row content-center">
              {item.name === '..' || item.name === '.' ? (
                ''
              ) : item.selected ? (
                <div className="h-7 w-7 flex-none self-center">
                  <OpenFolder />
                </div>
              ) : (
                <div className="h-7 w-7 flex-none self-center">
                  <Folder />
                </div>
              )}
              <div
                onClick={() => {
                  state.select(item);
                }}
                onDoubleClick={() => openFolder(item.fullPath)}
                className={
                  'block w-48 grow cursor-pointer select-none border-b px-2 py-1 hover:text-gray-500'
                }
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  },
);
