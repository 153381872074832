import React, { FC, memo, SVGProps } from 'react';

export const HarvestLogoIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 23 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.5C1.79086 0.5 0 2.29086 0 4.5V19.5C0 21.7091 1.79086 23.5 4 23.5H19C21.2091 23.5 23 21.7091 23 19.5V4.5C23 2.29086 21.2091 0.5 19 0.5H4ZM14.7006 2.5424C15.742 2.65174 16.5182 2.93327 16.6029 2.9647L16.6043 2.96333L17.3847 3.25443V4.08673C17.3847 5.95768 16.8421 7.40361 15.772 8.3835C14.527 9.5233 13.0059 9.61486 12.4333 9.6053V8.10471C12.4333 7.66464 12.8515 7.28744 13.2779 7.18221C13.5977 7.10294 14.0282 6.89248 14.3398 6.60138C14.7293 6.23648 14.9821 5.53129 15.0928 4.82609C14.2824 4.67986 13.0073 4.64159 12.2624 5.16366C11.534 5.67342 11.165 6.71481 11.165 8.26051V21.1467C11.165 21.3039 11.0365 21.4324 10.8794 21.4324H9.05352C8.89635 21.4324 8.76788 21.3039 8.76788 21.1467V8.42587C8.76788 6.04515 9.48538 4.33956 10.8999 3.35693C11.8934 2.66677 13.2082 2.38524 14.7006 2.5424ZM12.0151 12.5872V14.3994H13.8273C14.4382 14.3994 14.9343 13.9033 14.9343 13.2924V11.4802H13.1221C12.5112 11.4802 12.0151 11.9763 12.0151 12.5872ZM5 8.56128H6.81219C7.42309 8.56128 7.91919 9.05738 7.91919 9.66827V11.4805H6.107C5.4961 11.4805 5 10.9844 5 10.3735V8.56128Z"
    />
  </svg>
));
