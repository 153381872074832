import React, { ButtonHTMLAttributes, FC } from 'react';
import { ExternalSystemType } from '../../../types';
import { ExternalSystemIconMapper } from '../ExternalSystemIconMapper';
import { SquareButton } from './SquareButton';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  externalSystemType?: ExternalSystemType;
  externalSystemName: string;
  description?: string;
  onClick?: () => void;
  showStatus?: boolean;
  disabled?: boolean;
  small?: boolean;
  classname?: string;
  truncate?: boolean;
  logoUrl?: string;
}

export const ExternalSystemButton: FC<Props> = ({
  small,
  externalSystemType,
  externalSystemName,
  description,
  onClick,
  showStatus = true,
  disabled,
  className,
  truncate = true,
  logoUrl,
}) => {
  const statusColor = 'bg-success';
  const iconHeight = small ? 'h-8' : 'h-16';
  const iconWidth = small ? 'w-8' : 'w-16';
  
  return (
    <SquareButton
      disabled={disabled}
      description={description}
      onClick={onClick}
      small={small}
      className={`bg-background ${className}`}
      truncate={truncate}
    >
      <div className="relative">
        {showStatus && (
          <div
            className={`absolute rounded-full text-blocks ${statusColor} ${small ? '-top-1 -right-1 h-2 w-2' : '-top-2 -right-2 h-4 w-4'
              }`}
          ></div>
        )}

        <ExternalSystemIconMapper
          logoUrl={logoUrl}
          className={`${iconHeight} ${iconWidth} rounded-sm`}
          externalSystemType={externalSystemType}
          externalSystemName={externalSystemName}
        />
      </div>


    </SquareButton>
  );
};
