import { Instance, types as t } from 'mobx-state-tree';
import { IsoDate } from '../../types';

export const SimpleCustomerInvoice = t
  .model({
    id: t.string,
    createdAt: IsoDate,
    relationId: t.maybeNull(t.string),
    relationOrgNo: t.maybeNull(t.string),
    customerNumber: t.string,
    documentNumber: t.string,
    invoiceDate: IsoDate,
    dueDate: IsoDate,
    amount: t.number,
    currency: t.string
  });

export type SimpleCustomerInvoiceInstance = Instance<typeof SimpleCustomerInvoice>;