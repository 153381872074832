import { endOfDay, isValid, startOfDay } from 'date-fns';
import debounce from 'lodash.debounce';
import { getRoot, flow, types as t } from 'mobx-state-tree';
import { InvoicesFilterModel } from './InvoicesFilter';
import { PageInstance, Paging } from './Paging';
import {
  INTEGRATIONS_API_URL,
  INVOICES_PAGE_SIZE,
  REQUEST_DEBOUNCE_WAIT,
} from '../constants';
import { IntegrationEntity } from './IntegrationEntity';
import { withRequest } from '../extensions';
import { InvoicesFilter } from '../types';
import { RootStore } from './RootStore';

export const IntegrationEntityStore = t
  .model('IntegrationEntityStore', {
    invoiceEntity: t.maybeNull(IntegrationEntity),
    invoicesFilters: t.array(InvoicesFilterModel),
    invoicesSearch: '',
    pagedInvoiceEntities: t.maybeNull(t.array(IntegrationEntity)),
    pageInfo: t.maybeNull(Paging),
  })
  .views((self) => {
    return {
      get invoicesSearchQuery() {
        return new URLSearchParams(self.invoicesSearch);
      },
    };
  })
  .views((self) => {
    return {
      get invoicesPage() {
        const pageParam = self.invoicesSearchQuery.get('page');
        if (!pageParam) return 1;

        const currentPage = Number(pageParam);
        return currentPage >= 1 ? currentPage : 1;
      },
      get invoicesFiltersParams() {
        const searchParams = new URLSearchParams();

        self.invoicesFilters.forEach((filter) => {
          const value =
            typeof filter.value === 'string'
              ? filter.value
              : filter.value?.id.toString();

          if (!value) return;

          if (filter.name === InvoicesFilter.Imported) {
            const [startDateString, endDateString] = value.split('/');
            const startDate = new Date(startDateString);
            const endDate = new Date(endDateString);

            if (!isValid(startDate) || !isValid(endDate)) return;

            searchParams.append(
              'importedStart',
              startOfDay(startDate).toISOString(),
            );
            searchParams.append('importedEnd', endOfDay(endDate).toISOString());
          } else {
            searchParams.append(filter.name, value);
          }
        });

        return searchParams;
      },
    };
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    return {
      fetchEntityById: flow(function* (id: string) {
        const { data } = yield request({
          method: 'GET',
          url: `${INTEGRATIONS_API_URL}api/integrationentities/${id}`,
        });

        self.invoiceEntity = data;
      }),
      fetchInvoiceEntities: flow(function* () {
        // We need to use URLSearchParams in order to structure the entityType
        // query parameters like ?entityType=1&entityType=2
        const params = new URLSearchParams();
        params.append('page', String(self.invoicesPage));
        params.append('pageSize', String(INVOICES_PAGE_SIZE));
        for (const [key, value] of self.invoicesFiltersParams.entries()) {
          params.append(key, value);
        }

        const { data } = yield request({
          method: 'GET',
          url: `${INTEGRATIONS_API_URL}api/integrationentities`,
          params,
        });

        const pageResult: PageInstance = {
          currentPage: data.currentPage,
          totalPages: data.totalPages,
          pageSize: data.pageSize,
          totalItems: data.totalItems,
        };

        self.pagedInvoiceEntities = data.items;
        self.pageInfo = pageResult;
      }),
      retry: flow(function* (id: string) {
        try {
          yield request({
            method: 'POST',
            url:
              INTEGRATIONS_API_URL +
              `api/integrationentities/${id}/retry-request`,
          });
        } catch (error) {
          const { uiStore } = getRoot<typeof RootStore>(self);
          uiStore.setError('Something went wrong!', 'Please try again');
        }
      }),
    };
  })
  .actions((self) => {
    // If we would derive the filters from the current state each InvoiceFilter
    // instance would become a completely independent mst tree which causes
    // all the references to be null, to prevent this from happening we need
    // to explicitly set the filters in the store.
    return {
      setInvoicesFilters() {
        self.invoicesFilters.clear();

        for (const [key, value] of self.invoicesSearchQuery) {
          if (!Object.values(InvoicesFilter).includes(key as InvoicesFilter))
            continue;

          self.invoicesFilters.push(
            InvoicesFilterModel.create({ name: key as InvoicesFilter, value }),
          );
        }
      },
    };
  })
  .actions((self) => {
    // We debounce invoices requests that result from the user
    // navigating as to not overwhelm the network when navigating
    // back and forth quickly.
    const debouncedFetchInvoiceEntities = debounce(
      self.fetchInvoiceEntities,
      REQUEST_DEBOUNCE_WAIT,
    );

    return {
      handleInvoicesSearchChange(search: string) {
        self.invoicesSearch = search;
        self.setInvoicesFilters();
        debouncedFetchInvoiceEntities();
      },
    };
  });
