import { observer, useLocalObservable } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useCallback } from 'react';
import { UserPlusIcon, UserIcon, OrganizationIcon } from '../../icons';
import {
  ImageCrop,
  InputWithError,
  PageContainer,
} from '../../components/general';
import { Button, UploadImageButton } from '../../components/general/buttons';
import { ToggleSlider } from '../../components/general/ToggleSlider';
import { useStore } from '../../hooks';
import { useForm } from 'react-hook-form';
import { IdNameObj, Select } from '../../components/general/Select';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import axios from 'axios';

const LANGUAGES: IdNameObj[] = [
  {
    id: 'en',
    name: 'English',
  },
  {
    id: 'se',
    name: 'Swedish',
  },
];

const LANGUAGE_NAMES = {
  en: 'English',
  se: 'Swedish',
};

type FormData = {
  email: string;
};

interface State {
  formValid: boolean;
  setFormValid: (value: boolean) => void;
  email: string;
  setNotificationEmail: (value: string) => void;
  isImageCropOpen: boolean;
  setIsImageCropOpen: (isImageCropOpen: boolean) => void;
  isImageBlobUploading: boolean;
  setIsImageBlobUploading: (isImageBlobUploading: boolean) => void;
  imageBlob: Blob | null;
  setImageBlob: (imageBlob: Blob | null) => void;
  imageSrc: string;
  setImageSrc: (value: string) => void;
  handleImageSave: (blob: Blob) => void;
}

export const PreferencesPage = observer(() => {
  const navigate = useNavigate();
  const { settingsStore, authStore, tenantStore } = useStore();

  const state = useLocalObservable<State>(() => ({
    formValid: true,
    setFormValid: (value: boolean) => (state.formValid = value),
    email: '',
    setNotificationEmail: (email) => (state.email = email),
    isImageBlobUploading: false,
    setIsImageBlobUploading: (isImageBlobUploading) =>
      (state.isImageBlobUploading = isImageBlobUploading),
    isImageCropOpen: false,
    setIsImageCropOpen: (isImageCropOpen) =>
      (state.isImageCropOpen = isImageCropOpen),

    imageBlob: null,
    setImageBlob(imageBlob) {
      state.imageBlob = imageBlob;
      state.setImageSrc(imageBlob ? URL.createObjectURL(imageBlob) : null);
    },
    imageSrc: '',
    setImageSrc(src) {
      state.imageSrc = src;
    },
    handleImageSave(blob) {
      state.setImageBlob(blob);
      state.setIsImageCropOpen(false);
    },
  }));

  const fetchImage = useCallback(async (url: string) => {
    const res = await axios.get(url, {
      responseType: 'blob',
    });
    state.setImageBlob(res.data);
  }, []);

  useEffect(() => {
    const tenant = tenantStore.currentTenantInfo;
    state.setNotificationEmail(tenant.emailAddress);
    state.setImageSrc(tenant.logoUrl);

    if (tenant.logoUrl) {
      fetchImage(tenant.logoUrl);
    }
  }, [tenantStore.currentTenantInfo]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async () => {
    try {
      state.setIsImageBlobUploading(true);
      const email: string = state.email?.toLowerCase();
      const imageBlob: Blob = state.imageBlob;

      await tenantStore.updateTenant(email, imageBlob);
      state.setIsImageBlobUploading(false);

      navigate('/dashboard');
    } catch (error) {
      return;
    }
    reset();
  });

  return (
    <PageContainer
      useBackground={true}
      title="Preferences"
      titleIcon={<UserIcon className="h-10 text-titles" />}
      className="flex flex-col items-center"
    >
      <div className="flex w-full flex-col text-labels lg:flex-row lg:divide-x-[1px] lg:divide-dashed lg:divide-labels xl:p-12">
        <div className="mb-10 flex w-full justify-center md:w-full lg:mb-0 lg:w-full">
          <div className="flex w-full flex-col lg:w-2/3 xl:w-2/3">
            <div className="mb-6 flex flex-row align-middle text-xl font-bold text-titles lg:mb-12 ">
              <span className="mr-2 mt-[1px] self-start">
                {<UserPlusIcon className="mt-1 h-6 text-titles" />}
              </span>
              My preferences
            </div>
            <div>
              <div className="mb-12 flex items-center justify-between">
                <span>Langauge</span>
                <div className="w-32">
                  <Select
                    value={LANGUAGE_NAMES[settingsStore.language]}
                    items={LANGUAGES}
                    name="language"
                    placeholder="language"
                    onChange={(lang) => settingsStore.changeLanguage(lang.id)}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div>Dark Mode {settingsStore.darkMode} </div>
                <div className="mt-1 mr-2">
                  <ToggleSlider
                    checked={settingsStore.darkMode ?? false}
                    onActive={() => settingsStore.setDarkMode()}
                    onDisabled={() => settingsStore.removeDarkMode()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={onSubmit} className="w-full">
          <div className="flex justify-center">
            <div className="flex w-full flex-col lg:w-2/3 xl:w-2/3">
              <div className="mb-6 flex flex-row align-middle text-xl font-bold text-titles lg:mb-12 ">
                <span className="mr-2 mt-[1px] self-start">
                  {<OrganizationIcon className="mt-1 h-6 text-titles" />}
                </span>
                My organization
              </div>
              <div className='font-bold text-2xl'>{tenantStore.name}</div>              
              <div>
                <div className="flex flex-col">
                  <div className="mb-8 flex w-full items-center justify-between space-y-3">
                    <span className="font-bold">Organization Logo</span>
                    <UploadImageButton
                      imageSrc={state.imageSrc}
                      onClick={() => state.setIsImageCropOpen(true)}
                      onDelete={() => state.setImageBlob(null)}
                    />
                  </div>
                  <div className="font-bold">Notifications email</div>
                  <div className="mt-1 mr-2">
                    <InputWithError
                      value={state.email || ''}
                      type="text"
                      placeholder="namn.namnsson@e-post.se"
                      {...register('email', {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Please input a valid email address',
                        },
                      })}
                      onChange={(e) => {
                        state.setNotificationEmail(e.target.value);
                      }}
                      error={errors.email?.message}
                      className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {state.isImageCropOpen && (
        <DialogOverlay
          className={`${
            settingsStore.darkMode && 'darkMode'
          } absolute top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40`}
          isOpen
          onDismiss={() => state.setIsImageCropOpen(false)}
        >
          <DialogContent
            aria-label="dialog"
            className="max-w-screen mx-3 w-[40rem] rounded-md bg-popup p-3 sm:p-10"
          >
            <ImageCrop onSave={state.handleImageSave} circularCrop={false} />
          </DialogContent>
        </DialogOverlay>
      )}
      <div className="flex h-52 w-80 flex-col items-center justify-end gap-3">
        <Button
          isLoading={state.isImageBlobUploading}
          form="tenantForm"
          onClick={() => onSubmit()}
          className={`w-full text-popup`}
        >
          Save changes
        </Button>
        <Button
          onClick={() => {
            authStore.logOut();
            navigate('/');
            return true;
          }}
          className="w-full bg-error text-popup"
        >
          Sign out
        </Button>
      </div>
    </PageContainer>
  );
});
