import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  INTEGRATION_ENTITY_STATUS_TEXTS,
  INTEGRATION_TYPE_TEXTS,
} from '../../../constants';
import { useStore } from '../../../hooks/useStore';
import {
  IntegrationEntityStatus,
  IntegrationType,
  InvoicesFilter,
} from '../../../types';
import { FilterPicker, FilterInputType, FilterObj } from './FilterPicker';

const INVOICES_FILTER_TEXTS = {
  [InvoicesFilter.Imported]: 'Imported',
  [InvoicesFilter.EntityType]: 'Type',
  [InvoicesFilter.EntityStatus]: 'Status',
  [InvoicesFilter.InvoiceNumber]: 'Invoice #',
  [InvoicesFilter.CustomerNumber]: 'Customer #',
  [InvoicesFilter.Amount]: 'Amount',
  [InvoicesFilter.TargetSystemId]: 'Destination',
  [InvoicesFilter.SourceSystemId]: 'Source',
};

const getInputType = (inputType: InvoicesFilter) => {
  switch (inputType) {
    case InvoicesFilter.Imported:
      return FilterInputType.Date;
    case InvoicesFilter.EntityType:
    case InvoicesFilter.EntityStatus:
    case InvoicesFilter.SourceSystemId:
    case InvoicesFilter.TargetSystemId:
      return FilterInputType.Select;
    default:
      return FilterInputType.Text;
  }
};

const filterTypeItems = Object.values(InvoicesFilter).map((value) => ({
  name: INVOICES_FILTER_TEXTS[value],
  value,
  inputType: getInputType(value),
}));

export const InvoicesFilterPicker = observer(() => {
  const {
    integrationEntityStore: { invoicesFilters },
    externalSystemStore: { externalSystems },
  } = useStore();

  const filters = invoicesFilters.map((filter) => ({
    filterType: {
      name: INVOICES_FILTER_TEXTS[filter.name],
      value: filter.name,
      inputType: filter.inputType,
    },
    filterValue: {
      name: filter.displayName,
      value:
        typeof filter.value === 'string'
          ? filter.value
          : filter.value?.id || '',
    },
  }));

  const getFilterValueItems = (filter: InvoicesFilter) => {
    switch (filter) {
 
      case InvoicesFilter.EntityType:
        return Object.values(IntegrationType)
          .filter((value) => typeof value === 'string')
          .map((value) => ({
            name: INTEGRATION_TYPE_TEXTS[value],
            value: value,
          })) as FilterObj[];

      case InvoicesFilter.EntityStatus:
        return Object.values(IntegrationEntityStatus)
          .filter((value) => typeof value === 'string')
          .map((value) => ({
            name: INTEGRATION_ENTITY_STATUS_TEXTS[value],
            value: value,
          })) as FilterObj[];

      case InvoicesFilter.SourceSystemId:
      case InvoicesFilter.TargetSystemId:
        return externalSystems?.map((system) => ({
          name: system.name,
          value: system.id,
        })) as FilterObj[];

      default:
        return [];
    }
  };

  return (
    <FilterPicker
      filterTypes={Object.values(InvoicesFilter)}
      filterTypeItems={filterTypeItems}
      filters={filters}
      getFilterValueItems={getFilterValueItems}
    />
  );
});
