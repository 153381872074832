import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks/useStore';
import { ArrowRightIcon, PauseIcon, PlusIcon } from '../../icons';
import { ExternalSystemInstance, IntegrationInstance } from '../../models';
import { ExternalSystemType } from '../../types';
import { ExternalSystemIconMapper } from '../general';
import { ExternalSystemButton } from '../general/buttons';
import { FadeInDiv } from '../general/FadeInDiv';

type IntegrationBoxProps = {
  className?: string;
  integration: IntegrationInstance;
};

const entityTypeTextMapper = {
  CustomerInvoice: 'Customer invoices',
  SupplierInvoice: 'Supplier invoices',
  Payment: 'Payments',
};

const integrationStatusMapper = {
  Ready: {
    bgColor: 'bg-success',
    text: 'Active',
    icon: <ArrowRightIcon className="m-auto w-6 self-center" />,
  },
  Running: {
    bgColor: 'bg-success',
    text: 'Active',
    icon: <ArrowRightIcon className="m-auto w-6 self-center" />,
  },
  Failed: {
    bgColor: 'bg-error',
    text: 'Failed',
    icon: <PlusIcon className="m-auto w-6 rotate-45 self-center" />,
  },
  Disabled: {
    bgColor: 'bg-warning',
    text: 'Disabled',
    icon: <PauseIcon className="m-auto w-9 self-center" />,
  },
};

export const IntegrationBox: FC<IntegrationBoxProps> = observer(
  ({ className, integration }) => {
    const navigate = useNavigate();

    const { externalSystemStore } = useStore();
    const { externalSystems } = externalSystemStore;

    const blueprintCreation = integration?.metadata?.find(
      (metadata) => metadata.type == 'BlueprintCreation',
    );
    const sourceSystem = externalSystems?.find(
      (system: ExternalSystemInstance) =>
        system.id === blueprintCreation?.readerSystemId,
    );
    const targetSystem = externalSystems?.find(
      (system: ExternalSystemInstance) =>
        system.id === blueprintCreation?.writerSystemId,
    );

    const integrationStatus = integration.isActive()
      ? integrationStatusMapper[integration.status]
      : integrationStatusMapper['Disabled'];

    return (
      <FadeInDiv
        className={`${className} mt-8 rounded-lg bg-selectors transition-shadow hover:-translate-y-1 hover:cursor-pointer hover:bg-popup hover:drop-shadow-card`}
      >
        <div
          onClick={() => {
            navigate(`/integrations/edit/${integration.id}`);
          }}
          className={`rounded-lg rounded-t-xl`}
        >
          <div className="relative select-none rounded-t-lg py-4 px-4">
            <div>
              <div className="mb-4 text-center text-xl font-light text-labels">
                {integration.name}
              </div>
              <div className="flex justify-between">
                <ExternalSystemButton
                  logoUrl={
                    (sourceSystem && sourceSystem.systemLogo) || undefined
                  }
                  description={sourceSystem?.name}
                  small
                  className={`${!integration.isActive() && 'opacity-50'} mt-3`}
                  externalSystemName={sourceSystem?.name || ''}
                  externalSystemType={
                    sourceSystem?.externalSystemType as ExternalSystemType
                  }
                ></ExternalSystemButton>

                <div className="flex content-center justify-center align-middle text-selectors">
                  <div
                    className={`flex h-14 w-14 rounded-full ${integrationStatus.bgColor}`}
                  >
                    {integrationStatus.icon}
                  </div>
                </div>

                <ExternalSystemButton
                  logoUrl={
                    (targetSystem && targetSystem.systemLogo) || undefined
                  }
                  description={targetSystem?.name}
                  small
                  className={`${!integration.isActive() && 'opacity-50'} mt-3`}
                  externalSystemName={targetSystem?.name || ''}
                  externalSystemType={
                    targetSystem?.externalSystemType as ExternalSystemType
                  }
                ></ExternalSystemButton> 
              </div>
            </div>
          </div>
          <div
            className={`rounded-b-lg pb-[3px] pt-[3px] text-center text-xs text-background ${integrationStatus.bgColor}`}
          >
            {entityTypeTextMapper[blueprintCreation.entityType]} -{' '}
            {integrationStatus.text}
          </div>
        </div>
      </FadeInDiv>
    );
  },
);
