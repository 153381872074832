import React, { FC, memo, SVGProps } from 'react';

export const ArrowRightIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9083 7.2014C16.9197 7.15936 16.9253 7.11526 16.9346 7.07168C16.9497 7.00179 16.9667 6.93241 16.9762 6.85865C16.9898 6.75446 16.9954 6.64872 16.9971 6.54298C16.9973 6.52828 17 6.51487 17 6.50043V6.4994C17 6.37741 16.9915 6.25542 16.9757 6.1355C16.9706 6.0963 16.9606 6.06045 16.9536 6.02228C16.9397 5.94362 16.9268 5.86445 16.9059 5.78862C16.8952 5.74942 16.8799 5.71461 16.8674 5.67695C16.8439 5.6068 16.8224 5.53563 16.7932 5.4696C16.7779 5.43453 16.7577 5.40539 16.7405 5.37211C16.7093 5.31073 16.6804 5.24781 16.6434 5.19132C16.6337 5.17662 16.622 5.16682 16.6122 5.15238L14.0585 1.27355C13.5835 0.551395 12.8128 0.551395 12.3373 1.27355C11.8618 1.99571 11.8618 3.16629 12.3373 3.88765L12.8402 4.65153L1.21717 4.65127C0.544726 4.65127 0 5.4786 0 6.49991C0 7.52121 0.544726 8.34854 1.21717 8.34854H12.8429L12.3404 9.1117C11.8648 9.83385 11.8648 11.0044 12.3402 11.7263C12.8157 12.4485 13.5864 12.4477 14.0614 11.7263L16.6336 7.8199C16.6952 7.72809 16.7503 7.62725 16.798 7.51816C16.8051 7.50113 16.8099 7.48257 16.8166 7.46529C16.8509 7.38096 16.883 7.29404 16.9082 7.20145L16.9083 7.2014Z"
      fill="currentColor"
    />
  </svg>
));
