import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RelationStatusButton } from '../../components/finance';
import {
  NothingToDisplay,
  PageContainer,
  Pagination,
} from '../../components/general';
import { Button } from '../../components/general/buttons';
import {
  Table,
  TableHeaderCell,
  TableRow,
} from '../../components/general/Table';
import { useStore } from '../../hooks';
import { ClientsIcon, PlusIcon } from '../../icons';
import { RelationInstance } from '../../models';

export const RelationListPage = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const urlSearch = new URLSearchParams(self.location.search);
  let currentPage = Number(urlSearch.get('page'));
  let currentPageSize = Number(urlSearch.get('pageSize'));

  const { relationStore } = useStore();
  const { pagedRelations, pageInfo } = relationStore;

  const setCurrentPage = (page: number) => {
    if (page <= 0) {
      page = 1;
    }
    if (page === 1) {
      urlSearch.delete('page');
    } else {
      urlSearch.set('page', String(page));
    }
    currentPage = page;

    currentPageSize = Number(urlSearch.get('pageSize'));
    if (currentPageSize === 0) {
      currentPageSize = 20;
    }
    if (currentPageSize === 20) {
      urlSearch.delete('pageSize');
    }

    navigate(`${location.pathname}?${urlSearch.toString()}`);
    relationStore.fetchRelations(currentPage, currentPageSize);
  };

  useEffect(() => {
    setCurrentPage(currentPage);
  }, []);

  const isAscending = false;
  const tableHeaders = [
    {
      title: 'Status',
      className: '',
    },
    {
      title: 'Org #',
      className: '',
    },
    {
      title: 'Name',
      className: '',
    },
    {
      title: 'Company',
      className: '',
    },
    {
      title: 'Email',
      className: '',
    },
    {
      title: 'Telephone',
      className: '',
    },
  ];

  const getTableRowItems = (relation: RelationInstance) => {
    return [
      {
        value: (
          <RelationStatusButton
            status={relation.status}
            onClick={() => {
              navigate(`/relations/${relation.id}`);
            }}
          />
        ),
        className: 'py-0',
      },
      {
        value: relation.orgNo,
      },
      {
        value: relation.contactName,
      },
      {
        value: relation.companyName,
      },
      {
        value: relation.email,
      },
      {
        value: relation.phoneNumber,
      },
    ];
  };

  const isLoading = pagedRelations === null;
  const hasData = pagedRelations && pagedRelations.length !== 0;

  return (
    <PageContainer
      title="Relations"
      titleIcon={<ClientsIcon className="w-12" />}
      useBackground={false}
    >
      {hasData && (
        <div className="flex justify-end">
          <div className="mr-2">Invite</div>
          <button
            onClick={() => navigate('/relations/invite')}
            className={`flex h-6 w-6 items-center justify-center rounded-sm bg-titles text-xl font-bold text-popup hover:cursor-pointer`}
          >
            <PlusIcon className="h-4 text-background" />
          </button>
        </div>
      )}

      {hasData ? (
        <>
          <Table
            headerItems={tableHeaders}
            renderHeaderCell={(item, index) => (
              <TableHeaderCell
                key={index}
                className={item.className}
                title={item.title || ''}
                isAscending={isAscending}
              />
            )}
            items={pagedRelations as RelationInstance[]}
            renderRow={(item, index) => (
              <TableRow
                key={index}
                items={getTableRowItems(item)}
                index={index}
              />
            )}
          />
          {pageInfo && pageInfo.totalPages > 1 && (
            <Pagination
              className="mt-5"
              page={pageInfo.currentPage}
              numPages={pageInfo.totalPages}
              onChange={setCurrentPage}
            />
          )}
        </>
      ) : (
        <NothingToDisplay
          loading={isLoading}
          className={`mt-5 sm:mt-20 ${isLoading && 'animate-pulse'}`}
          text={'There are currently no relations to display'}
        >
          {!isLoading && (
            <Button onClick={() => navigate('/relations/invite')}>
              {"Let's get started!"}
            </Button>
          )}
        </NothingToDisplay>
      )}
    </PageContainer>
  );
});
