import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';

type ToggleSliderProps = {
  checked: boolean;
  onActive: () => void;
  onDisabled: () => void;
  statusColors?: boolean;
};

export const ToggleSlider: FC<ToggleSliderProps> = observer(
  ({ checked, onActive, onDisabled, statusColors }) => {
    const [isChecked, updateCheckedState] = useState(checked);
    function updateToggleState() {
      if (isChecked) {
        onDisabled();
        updateCheckedState(false);
      } else {
        onActive();
        updateCheckedState(true);
      }
    }

    return (
      <div>
        <label
          htmlFor="toggle-slider"
          className="relative inline-flex cursor-pointer items-center"
        >
          <input
            type="checkbox"
            id="toggle-slider"
            className="peer sr-only"
            defaultChecked={isChecked}
            onClick={updateToggleState}
          />
          {statusColors ? (
            <div className="h-5 w-10 scale-150 rounded-full bg-blocks after:absolute after:top-[0px] after:left-[0px] after:h-5 after:w-5 after:rounded-full after:border after:border-error after:bg-error after:transition-all after:content-[''] peer-checked:bg-blocks peer-checked:after:translate-x-full peer-checked:after:border-success peer-checked:after:bg-success"></div>
          ) : (
            <div className="h-5 w-10 scale-150 rounded-full bg-blocks after:absolute after:top-[0px] after:left-[0px] after:h-5 after:w-5 after:rounded-full after:border after:border-titles after:bg-titles after:transition-all after:content-[''] peer-checked:bg-blocks peer-checked:after:translate-x-full peer-checked:after:border-titles peer-checked:after:bg-titles"></div>
          )}
        </label>
      </div>
    );
  },
);
