import { Instance, types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';

export const Relation = t.model({
  id: t.string,
  createdAt: IsoDate,
  companyName: t.string,
  orgNo: t.string,
  phoneNumber: t.string,
  status: t.string,
  email: t.string,
  contactName: t.string,
});

export type RelationInstance = Instance<typeof Relation>;
