import React, { FC, memo, SVGProps } from 'react';

export const MenuIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    width="26px"
    height="26px"
    {...props}
  >
    <path d="M 0 4 L 0 6 L 26 6 L 26 4 Z M 0 12 L 0 14 L 26 14 L 26 12 Z M 0 20 L 0 22 L 26 22 L 26 20 Z" />
  </svg>
));
