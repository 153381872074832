import React, { FC, memo, SVGProps } from 'react';

export const DashboardIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4"
    {...props}
  >
    <circle cx="6.125" cy="6.125" r="5.125" stroke="currentColor" />
    <circle cx="19.875" cy="6.125" r="5.125" stroke="currentColor" />
    <circle cx="6.125" cy="19.875" r="5.125" stroke="currentColor" />
    <circle cx="19.875" cy="19.875" r="5.125" stroke="currentColor" />
  </svg>
));
