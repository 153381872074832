import { TimeInterval } from '../types/TimeInterval';

export const getTrendTextsToDate = (
  interval: TimeInterval,
): [string, string] => {
  switch (interval) {
    case TimeInterval.Day:
      return ['Today', 'Last DTD'];
    case TimeInterval.Month:
      return ['This month', 'Last MTD'];
    case TimeInterval.Year:
      return ['This year', 'Last YTD'];
  }
};
