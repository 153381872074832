import React, { FC, useState } from 'react';
import { ClientsIcon, HeartIcon } from '../../../icons';
import { MostActiveClientsData } from '../../../types';
import { Card } from './Card';
import { PillTabs } from './PillTabs';

const SE_NUM_FORMATTER = new Intl.NumberFormat('se-SE');
const SE_USD_FORMATTER = new Intl.NumberFormat('se-SE', {
  style: 'currency',
  currency: 'USD',
});

enum Tab {
  Volume = 'volume',
  Money = 'money',
}

const tabs = [
  { value: Tab.Volume, label: 'Volume' },
  { value: Tab.Money, label: '$' },
];

export const MostActiveClientsCard: FC<MostActiveClientsData> = ({
  volume,
  money,
}) => {
  const [tab, setTab] = useState<Tab>(Tab.Volume);

  const clients = tab === Tab.Volume ? volume : money;
  const hasData = clients.length > 0;

  return (
    <Card icon={<ClientsIcon className="h-5" />} title="Most active customers">
      {hasData ? (
        <div>
          <div className="mt-5">
            <PillTabs
              tabs={tabs}
              value={tab}
              onChange={(newTab) => setTab(newTab as Tab)}
            />
          </div>
          <table className="mt-6">
            <tbody>
              {clients.map((client, i) => (
                <tr key={i} className="text-labels">
                  <td className="pr-7 text-xl font-light">
                    {tab === Tab.Volume
                      ? SE_NUM_FORMATTER.format(client.count)
                      : SE_USD_FORMATTER.format(client.count)}
                  </td>
                  <td className="w-full text-xs font-light">{client.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mt-10 h-64 text-text">
          There is currently no customer data to display.
        </div>
      )}
    </Card>
  );
};
