import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useRef } from 'react';
import { CloseIcon } from '../../icons';
import { RoundButton } from './buttons';

interface PopupModalProps {
  className?: string;
  title?: string;
  open?: boolean;
  canClose?: boolean;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
  titleIcon?: JSX.Element;
}

export const PopupModal: FC<PopupModalProps> = observer(
  ({
    className,
    title,
    open = true,
    canClose = false,
    onClose,
    closeOnClickOutside,
    titleIcon,
    children,
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose && onClose();
        }
      };
      if (canClose && closeOnClickOutside)
        document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [onClose]);

    if (!open) return null;

    return (
      <div
        ref={ref}
        className={`relative h-fit rounded-lg bg-popup p-12 ${
          className ? className : ''
        }`}
      >
        {canClose && (
          <RoundButton
            className="absolute -top-5 -right-5 z-10 h-9 w-9 bg-titles text-background"
            onClick={onClose}
          >
            <CloseIcon className="text-background" />
          </RoundButton>
        )}
        <div className={`flex w-full flex-col py-3 px-4`}>
          {(title || titleIcon) && (
            <div className="mb-12 flex flex-row align-middle text-xl font-bold text-titles ">
              <span className="mr-2 mt-[1px] self-start">{titleIcon}</span>
              {title}
            </div>
          )}
          <div>{children}</div>
        </div>
      </div>
    );
  },
);
