import React, { FC, memo, SVGProps } from 'react';

export const NewPasswordIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg 
    width="24" 
    height="25" 
    viewBox="0 0 24 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17 7.5H22V17.5H17V19.5C17 19.7652 17.1054 20.0196 17.2929 20.2071C17.4804 20.3946 17.7348 20.5 18 20.5H20V22.5H17.5C16.95 22.5 16 22.05 16 21.5C16 22.05 15.05 22.5 14.5 22.5H12V20.5H14C14.2652 20.5 14.5196 20.3946 14.7071 20.2071C14.8946 20.0196 15 19.7652 15 19.5V5.5C15 5.23478 14.8946 4.98043 14.7071 4.79289C14.5196 4.60536 14.2652 4.5 14 4.5H12V2.5H14.5C15.05 2.5 16 2.95 16 3.5C16 2.95 16.95 2.5 17.5 2.5H20V4.5H18C17.7348 4.5 17.4804 4.60536 17.2929 4.79289C17.1054 4.98043 17 5.23478 17 5.5V7.5ZM2 7.5H13V9.5H4V15.5H13V17.5H2V7.5ZM20 15.5V9.5H17V15.5H20ZM8.5 12.5C8.5 12.1022 8.34196 11.7206 8.06066 11.4393C7.77936 11.158 7.39782 11 7 11C6.60218 11 6.22064 11.158 5.93934 11.4393C5.65804 11.7206 5.5 12.1022 5.5 12.5C5.5 12.8978 5.65804 13.2794 5.93934 13.5607C6.22064 13.842 6.60218 14 7 14C7.39782 14 7.77936 13.842 8.06066 13.5607C8.34196 13.2794 8.5 12.8978 8.5 12.5ZM13 11.39C12.39 10.83 11.44 10.88 10.88 11.5C10.32 12.1 10.37 13.05 11 13.61C11.55 14.13 12.43 14.13 13 13.61V11.39Z" fill="#FFABC9"/>
  </svg>
));
