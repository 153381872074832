import {
  startOfYear,
  startOfDay,
  startOfMonth,
  endOfDay,
  addYears,
} from 'date-fns';
import { TotalsData, TotalsDataEntryType, TimeInterval } from '../types';

const TOTALS_DATA_ENTRY_TYPE_VALUES = Object.values(TotalsDataEntryType);

export const fetchTotalsData_demo = (
  interval: TimeInterval,
  setFunc: React.Dispatch<React.SetStateAction<TotalsData | undefined>>,
) => {
  const periodStart = (date: Date) => {
    if (interval === TimeInterval.Year) return startOfYear(date);
    if (interval === TimeInterval.Month) return startOfMonth(date);
    if (interval === TimeInterval.Day) return startOfDay(date);
  };

  setFunc({
    currentPeriodData: {
      periodToDate: {
        periodDates: {
          periodStart: periodStart(new Date())!.toString(),
          periodEnd: new Date().toString(),
        },
        subTotals: Array.from({ length: 100 }, () => ({
          periodIndex: Math.floor(Math.random() * 31) + 1,
          entityType:
            TOTALS_DATA_ENTRY_TYPE_VALUES[
              Math.floor(Math.random() * TOTALS_DATA_ENTRY_TYPE_VALUES.length)
            ],
          count: Math.floor(Math.random() * 100) + 1,
        })),
      },
      period: {
        periodDates: {
          periodStart: periodStart(new Date())!.toString(),
          periodEnd: endOfDay(new Date()).toString(),
        },
        subTotals: Array.from({ length: 100 }, () => ({
          periodIndex: Math.floor(Math.random() * 31) + 1,
          entityType:
            TOTALS_DATA_ENTRY_TYPE_VALUES[
              Math.floor(Math.random() * TOTALS_DATA_ENTRY_TYPE_VALUES.length)
            ],
          count: Math.floor(Math.random() * 100) + 1,
        })),
      },
    },
    previousPeriodData: {
      periodToDate: {
        periodDates: {
          periodStart: periodStart(addYears(new Date(), -1))!.toString(),
          periodEnd: addYears(new Date(), -1).toString(),
        },
        subTotals: Array.from({ length: 100 }, () => ({
          periodIndex: Math.floor(Math.random() * 31) + 1,
          entityType:
            TOTALS_DATA_ENTRY_TYPE_VALUES[
              Math.floor(Math.random() * TOTALS_DATA_ENTRY_TYPE_VALUES.length)
            ],
          count: Math.floor(Math.random() * 100) + 1,
        })),
      },
      period: {
        periodDates: {
          periodStart: periodStart(addYears(new Date(), -1))!.toString(),
          periodEnd: endOfDay(addYears(new Date(), -1)).toString(),
        },
        subTotals: Array.from({ length: 100 }, () => ({
          periodIndex: Math.floor(Math.random() * 31) + 1,
          entityType:
            TOTALS_DATA_ENTRY_TYPE_VALUES[
              Math.floor(Math.random() * TOTALS_DATA_ENTRY_TYPE_VALUES.length)
            ],
          count: Math.floor(Math.random() * 100) + 1,
        })),
      },
    },
  });
};
