import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {
  DashboardIcon,
  ExternalSystemIcon,
  HarvestLogoIcon,
  HarvestTextIcon,
  ListIcon,
  IntegrationIcon,
  UserIcon,
  ClientsIcon,
} from '../../icons';

import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks';
import { Banner } from './Banner';
import { HarvestModules } from '../../types/auth';

export const Header = observer(() => {
  const { uiStore, authStore, tenantStore } = useStore();

  useEffect(() => {
    return; // Only here to observe logo variable and rerender usericon..
  }, [tenantStore.logoUrl]);

  const navigate = useNavigate();
  const location = useLocation();
  const rootPath = location.pathname.split('/')[1];

  const hasFinance = authStore.modules.includes(HarvestModules.Finance);
  const hasIntegrations = authStore.modules.includes(
    HarvestModules.Integrations,
  );

  return (
    <>
      <Banner
        title={uiStore.errorTitle}
        text={uiStore.errorText}
        onClose={() => uiStore.clearError()}
      />
      <div className="flex flex-col justify-between gap-6 p-6 align-middle md:flex-row md:gap-0">
        <div
          className="flex align-middle hover:cursor-pointer hover:opacity-80"
          onClick={() => navigate('/dashboard')}
        >
          <HarvestLogoIcon className="h-6 text-titles" />
          <HarvestTextIcon className="ml-2 h-6 text-titles" />
        </div>
        {authStore.isLoggedIn && (
          <div className="w-full self-center bg-blocks p-2 md:w-auto md:bg-transparent md:p-0">
            <div className="flex justify-center">
              <DashboardIcon
                className={`${
                  rootPath === 'dashboard' ? 'text-text' : 'text-titles'
                } h-6 hover:cursor-pointer hover:opacity-80`}
                onClick={() => navigate('/dashboard')}
              />

              {/* GROUP INTEGRATIONS */}
              {hasIntegrations && (
                <div className="flex">
                  <ListIcon
                    className={`${
                      rootPath === 'invoices' ? 'text-text' : 'text-titles'
                    } ml-5 h-6 hover:cursor-pointer hover:opacity-80`}
                    onClick={() => navigate('/invoices')}
                  />
                  <IntegrationIcon
                    className={`${
                      rootPath === 'integrations' ? 'text-text' : 'text-titles'
                    } ml-5 h-6 hover:cursor-pointer hover:opacity-80`}
                    onClick={() => navigate('/integrations')}
                  />
                  <ExternalSystemIcon
                    className={`${
                      rootPath === 'systems' ? 'text-text' : 'text-titles'
                    } ml-5 h-6 hover:cursor-pointer hover:opacity-80`}
                    onClick={() => navigate('/systems')}
                  />
                </div>
              )}

              {/* GROUP FINANCE */}
              {hasFinance && (
                <>
                  <div className="mx-5 border border-popup" />
                  <div className="flex content-evenly justify-evenly">
                    <ClientsIcon
                      className={`${
                        rootPath === 'relations' ? 'text-text' : 'text-titles'
                      } h-6 hover:cursor-pointer hover:opacity-80`}
                      onClick={() => navigate('/relations')}
                    />
                    <ListIcon
                      className={`${
                        rootPath === 'ledger' ? 'text-text' : 'text-titles'
                      } ml-5 h-6 hover:cursor-pointer hover:opacity-80`}
                      onClick={() => navigate('/ledger')}
                    />
                  </div>
                </>
              )}

              {/* GROUP BOTH */}
              <div className="mx-5 border border-popup" />
              <div className="flex items-center">
                <UserIcon
                  className={`${
                    rootPath === 'preferences' ? 'text-text' : 'text-titles'
                  } h-7 hover:cursor-pointer hover:opacity-80`}
                  onClick={() => navigate('/preferences')}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
});
