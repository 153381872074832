import React, { FC } from 'react';

type Props = {
  className?: string;
  text?: string;
};

export const BasicTextLoader: FC<Props> = ({ className, text }) => (
  <div className={className}>
    <div className="animate-pulse">{text}</div>
  </div>
);
