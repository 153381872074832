import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { FadeInDiv } from './FadeInDiv';
import { PageTitle } from './PageTitle';

interface PageContainerProps {
  useBackground?: boolean;
  title?: string;
  titleIcon?: JSX.Element;
  className?: string;
}

export const PageContainer: FC<PageContainerProps> = observer(
  ({ useBackground = true, title, titleIcon, className, children }) => {
    return (
      <FadeInDiv className="mx-0 mb-8 mt-5 flex flex-col p-4 align-middle md:mx-6 md:mt-20 md:p-7 lg:mx-10 lg:p-10 xl:p-14">
        <PageTitle title={title} titleIcon={titleIcon} />
        <div
          className={`mt-10 rounded-lg ${
            useBackground && 'bg-popup p-4 md:p-7 lg:p-10 xl:p-14'
          } ${className && className}`}
        >
          {children}
        </div>
      </FadeInDiv>
    );
  },
);
