import { flow, types as t, applySnapshot } from 'mobx-state-tree';
import { string } from 'mobx-state-tree/dist/internal';
import { INTEGRATIONS_API_URL } from '../constants';
import { withRequest } from '../extensions';
import { ExternalSystemType } from '../types';
import { AddedSystemsMap } from './AddedSystemsMap';
import { AvailableSystemsMap } from './AvailableSystemsMap';
import { ExternalSystem, ExternalSystemInstance } from './ExternalSystem';
import { ExternalSystemInvitation } from './ExternalSystemInvitation';

const ExternalSystemArray = t.array(ExternalSystem);
const AddedSystemsArray = t.array(AddedSystemsMap);

export const ExternalSystemStore = t
  .model('ExternalSystemStore', {
    externalSystems: t.maybeNull(ExternalSystemArray),
    formsErrorText: t.maybeNull(t.string),
    connectionResult: t.maybeNull(t.string),
    addedSystems: t.maybeNull(AddedSystemsArray),
    availableSystems: t.maybeNull(AvailableSystemsMap),
    externalSystemInvitations: t.array(ExternalSystemInvitation),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchSystems: flow(function* () {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + 'api/systems/',
        });

        if (self.externalSystems === null) {
          self.externalSystems = ExternalSystemArray.create();
        }

        self.externalSystems.replace(data);
      }),
      addSystemFortnox: flow(function* (authCode: string) {
        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/fortnox',
          data: { authCode },
        });

        const created: ExternalSystemInstance = {
          createdAt: response.data.createdAt,
          externalSystemType: response.data.externalSystemType,
          id: response.data.id,
          name: response.data.name,
          systemLogo: response.data.systemLogo,
        };

        const updatedSystem = self.externalSystems?.find(
          (x) => x.id === created.id,
        );
        if (updatedSystem) {
          self.externalSystems?.remove(updatedSystem);
        }

        self.externalSystems!.push(created);
        return response.data.id;
      }),
      addTestOauth: flow(function* (authCode: string) {
        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/testoauth',
          data: { authCode },
        });

        const created: ExternalSystemInstance = {
          createdAt: response.data.createdAt,
          externalSystemType: response.data.externalSystemType,
          id: response.data.id,
          name: response.data.name,
          systemLogo: response.data.systemLogo,
        };

        self.externalSystems!.push(created);
      }),
      testSftpFolderAccess: flow(function* (
        systemId: string,
        folderPath: string,
      ) {
        const data = { systemId, folderPath };

        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/sftp/testpath',
          data,
        });

        return response.data.status === 'Success';
      }),
      createSftpSystem: flow(function* (systemData: any) {
        const data = {
          name: systemData.name,
          username: systemData.username,
          password: systemData.password,
          host: systemData.host,
          port: systemData.port,
        };

        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/sftp',
          data,
        });

        const created: ExternalSystemInstance = {
          createdAt: response.data.createdAt,
          externalSystemType: response.data.externalSystemType,
          id: response.data.id,
          name: response.data.name,
          systemLogo: response.data.systemLogo,
        };

        self.externalSystems!.push(created);
        self.connectionResult = '';
        return response.data.id;
      }),
      clearConnectionError: () => {
        self.connectionResult = '';
      },
      getSftpSystemSetting: flow(function* (id: string) {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + `api/systems/sftp/${id}`,
        });

        return data;
      }),
      updateSftpSystemSetting: flow(function* (id: string, systemData: any) {
        const data = Object.keys(systemData).map((key) => {
          if (systemData[key]) {
            return {
              op: 'replace',
              path: `/${key}`,
              value: systemData[key],
            };
          }
        });

        const response = yield request({
          method: 'PATCH',
          url: INTEGRATIONS_API_URL + `api/systems/sftp/${id}`,
          data,
        });

        const updateIndex = self.externalSystems?.findIndex((x) => x.id === id);
        if (updateIndex) {
          self.externalSystems?.splice(updateIndex, 1, response.data);
        }
      }),
      addBjornlundenSystem: flow(function* (systemData: any) {
        const data = {
          clientId: systemData.clientId,
          clientSecret: systemData.clientSecret,
          companyPublicKey: systemData.companyPublicKey,
        };

        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/bjornlunden',
          data,
        });

        const created: ExternalSystemInstance = {
          createdAt: response.data.createdAt,
          externalSystemType: response.data.externalSystemType,
          id: response.data.id,
          name: response.data.name,
          systemLogo: response.data.systemLogo,
        };

        self.externalSystems!.push(created);
        return created.id;
      }),
      updateBjornlundenSystem: flow(function* (id: string, systemData: any) {
        const data = Object.keys(systemData).map((key) => {
          if (systemData[key]) {
            return {
              op: 'replace',
              path: `/${key}`,
              value: systemData[key],
            };
          }
        });

        const response = yield request({
          method: 'PATCH',
          url: INTEGRATIONS_API_URL + `api/systems/bjornlunden/${id}`,
          data,
        });

        return response.data;
      }),
      getBjornlundenSystemSetting: flow(function* (id: string) {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + `api/systems/bjornlunden/${id}`,
        });

        return data;
      }),
      addTwentyFourSevenOfficeSystem: flow(function* (systemData: any) {
        const data = {
          applicationId: systemData.applicationId,
          username: systemData.username,
          password: systemData.password,
          identityId: systemData.identityId,
        };

        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/twentyfourseven',
          data,
        });

        const created: ExternalSystemInstance = {
          createdAt: response.data.createdAt,
          externalSystemType: response.data.externalSystemType,
          id: response.data.id,
          name: response.data.name,
          systemLogo: response.data.systemLogo,
        };

        self.externalSystems!.push(created);
        return created.id;
      }),
      AddHarvestFinanceSystem: flow(function* () {
        const data = {};

        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/harvestfinance',
          data,
        });

        const created: ExternalSystemInstance = {
          createdAt: response.data.createdAt,
          externalSystemType: ExternalSystemType.HarvestFinance,
          id: response.data.id,
          name: response.data.name,
          systemLogo: response.data.systemLogo,
        };

        self.externalSystems!.push(created);
        return created.id;
      }),
      getTwentyFourSevenOfficeSystemSetting: flow(function* (id: string) {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + `api/systems/twentyfourseven/${id}`,
        });

        return data;
      }),
      updateTwentyFourSevenOfficeSystemSetting: flow(function* (
        id: string,
        systemData: any,
      ) {
        const data = Object.keys(systemData).map((key) => {
          if (systemData[key]) {
            return {
              op: 'replace',
              path: `/${key}`,
              value: systemData[key],
            };
          }
        });

        const response = yield request({
          method: 'PATCH',
          url: INTEGRATIONS_API_URL + `api/systems/twentyfourseven/${id}`,
          data,
        });

        return response.data;
      }),
      testSftpConnection: flow(function* (systemData: any) {
        const data = {
          name: systemData.name,
          username: systemData.username,
          password: systemData.password,
          host: systemData.host,
          port: systemData.port,
        };
        const response = yield request({
          method: 'POST',
          url: INTEGRATIONS_API_URL + 'api/systems/sftp/test',
          data,
        });
        if (!response?.data) {
          self.connectionResult = 'Unable to connect.';
        } else if (response?.data?.status == 'Error') {
          self.connectionResult = 'Unable to connect. ' + response.data.message;
        } else if (response?.data?.status == 'Success') {
          self.connectionResult = 'Connection successful!';
        }
      }),
      listSftpDir: flow(function* (externalSystemId: string, path: string) {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + 'api/systems/sftp/dir',
          params: { systemId: externalSystemId, path },
        });

        return data;
      }),
      getAddedSystems: flow(function* () {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + 'api/systems/added',
        });

        self.addedSystems = AddedSystemsArray.create(data);
      }),
      getAvailableSystems: flow(function* () {
        const { data } = yield request({
          method: 'GET',
          url: INTEGRATIONS_API_URL + 'api/systems/available',
        });
        self.availableSystems = data;
      }),
      fetchExternalSystemInvitations: flow(function* () {
        const { data: invitations } = yield request({
          method: 'GET',
          url: `${INTEGRATIONS_API_URL}api/invitations`,
        });
        self.externalSystemInvitations = invitations;
      }),
      createExternalSystemInvitation: flow(function* (data) {
        const { data: invitation } = yield request({
          method: 'POST',
          url: `${INTEGRATIONS_API_URL}api/invitations`,
          data,
        });
        self.externalSystemInvitations.push(invitation);

        return invitation;
      }),
      reset() {
        applySnapshot(self, {
          externalSystems: null,
          formsErrorText: null,
          connectionResult: null,
          addedSystems: null,
          availableSystems: null,
          externalSystemInvitations: [],
        });
      },
    };
  })
  .views((self) => {
    return {
      get pendingExternalSystemInvitations() {
        return self.externalSystemInvitations.filter(
          (invitation) => invitation.accepted === null,
        );
      },
    };
  });
