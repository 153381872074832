import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchWentWrongData } from '../../../api/fetchWentWrongData';
import { useStore } from '../../../hooks';
import { CheckmarkIcon, NoteIcon } from '../../../icons';
import { WentWrongError } from '../../../types';
import { Card } from './Card';

export const WentWrongCard: FC = () => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const [wentWrongData, setWentWrongData] = useState<WentWrongError[]>();
  const [hasData, setHasData] = useState<boolean>(false);

  const handleClick = (error) => {
    if (error.type === 'Documents') {
      navigate(`/invoices${error.count > 0 && '?EntityStatus=Error'}`);
    } else if (error.type === 'Sources & destinations') {
      navigate('/systems');
    } else if (error.type === 'Integrations') {
      navigate('/integrations');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [data] = await Promise.all([fetchWentWrongData(authStore)]);
      if (data) {
        setWentWrongData(data.errors);
        setHasData(data.hasData);
      }
    };
    fetchData();
  }, []);

  const hasErrors =
    wentWrongData &&
    wentWrongData.reduce((partialSum, a) => partialSum + a.count, 0) > 0;

  return (
    <Card
      icon={<NoteIcon />}
      title={`${
        !hasData
          ? 'Nothing to report'
          : !hasErrors && hasData
          ? 'Everything is fine!'
          : hasErrors && 'Something went wrong!'
      } `}
    >
      <div className="mt-10">
        {hasData && (
          <div className="flex h-auto flex-grow flex-col justify-start">
            {wentWrongData?.map((error) => (
              <div
                onClick={() => handleClick(error)}
                key={error.type}
                className={`mt-3 flex justify-between rounded-sm p-3 text-background hover:cursor-pointer ${
                  error.count > 0 ? 'bg-error' : 'bg-success'
                }`}
              >
                <div className="whitespace-nowrap">
                  {error.count === 0 ? (
                    <CheckmarkIcon className="w-6" />
                  ) : (
                    <div className="ml-2">
                      <span className="text-lg font-bold">{error.count} </span>
                      <span className="ml-1 text-lg font-medium">errors</span>
                    </div>
                  )}
                </div>

                <span className="font-light">{error.type}</span>
              </div>
            ))}
          </div>
        )}

        {!hasData && (
          <div className="h-56 text-text">
            There are currently no errors to report.
          </div>
        )}
      </div>
    </Card>
  );
};
