import { types as t } from 'mobx-state-tree';
import { AuthStore } from './AuthStore';
import { TenantStore } from './TenantStore';
import { ExternalSystemStore } from './ExternalSystemStore';
import { CustomerInvoiceStore } from './finance';
import { IntegrationEntityStore } from './IntegrationEntityStore';
import { IntegrationStore } from './IntegrationStore';
import { LogStore } from './LogStore';
import { RelationStore } from './RelationStore';
import { SettingsStore } from './SettingsStore';
import { StatisticsStore } from './StatisticsStore';
import { UIStore } from './UIStore';
import { HarvestModules } from '../types/auth';

export const RootStore = t
  .model('RootStore', {
    logStore: LogStore,
    integrationEntityStore: IntegrationEntityStore,
    integrationStore: IntegrationStore,
    externalSystemStore: ExternalSystemStore,
    authStore: AuthStore,
    uiStore: UIStore,
    statisticsStore: StatisticsStore,
    settingsStore: SettingsStore,
    relationStore: RelationStore,
    tenantStore: TenantStore,
    customerInvoiceStore: CustomerInvoiceStore,
  })
  .actions((self) => ({
    init() {
      self.tenantStore.fetchCurrentTenant();
      self.settingsStore.changeLanguage(self.settingsStore.language);

      if (self.authStore.modules.includes(HarvestModules.Integrations)) {
        self.externalSystemStore.fetchSystems();
        self.integrationStore.fetchIntegrations();
        self.integrationEntityStore.fetchInvoiceEntities();  
      }

      if (self.authStore.modules.includes(HarvestModules.Finance)) {
        self.relationStore.fetchRelations();
      }
    },
    reset() {
      self.logStore.reset();
      self.integrationStore.reset();
      self.externalSystemStore.reset();
      self.authStore.reset();
      self.uiStore.reset();
      self.statisticsStore.reset();
      self.relationStore.reset();
      self.tenantStore.reset();
      self.customerInvoiceStore.reset();
    },
  }));
