import React, { FC, memo, SVGProps } from 'react';

export const ArrowUpIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1024_3341)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49939 17.0019C8.78118 17.0019 9.05143 16.89 9.25069 16.6907C9.44995 16.4914 9.56189 16.2212 9.56189 15.9394V3.62927L14.1221 8.19165C14.2209 8.29044 14.3382 8.3688 14.4673 8.42226C14.5963 8.47573 14.7347 8.50324 14.8744 8.50324C15.0141 8.50324 15.1524 8.47573 15.2815 8.42226C15.4106 8.3688 15.5279 8.29044 15.6266 8.19165C15.7254 8.09286 15.8038 7.97559 15.8573 7.84651C15.9107 7.71744 15.9382 7.5791 15.9382 7.4394C15.9382 7.29969 15.9107 7.16136 15.8573 7.03228C15.8038 6.90321 15.7254 6.78594 15.6266 6.68715L9.25164 0.312149C9.15294 0.213202 9.03569 0.134698 8.90661 0.0811348C8.77753 0.0275711 8.63914 0 8.49939 0C8.35963 0 8.22125 0.0275711 8.09217 0.0811348C7.96308 0.134698 7.84584 0.213202 7.74714 0.312149L1.37214 6.68715C1.17263 6.88666 1.06055 7.15725 1.06055 7.4394C1.06055 7.72155 1.17263 7.99214 1.37214 8.19165C1.57165 8.39116 1.84224 8.50324 2.12439 8.50324C2.40654 8.50324 2.67713 8.39116 2.87664 8.19165L7.43689 3.62927V15.9394C7.43689 16.2212 7.54883 16.4914 7.74809 16.6907C7.94735 16.89 8.2176 17.0019 8.49939 17.0019Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1024_3341">
        <rect width="17" height="17.0019" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
