import React, { FC, memo, SVGProps } from 'react';

export const PlayCircleIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="20" fill="#BDFFD4" />
    <path d="M29 20.1537L16 31.375V8.93243L29 20.1537Z" fill="#03031C" />
  </svg>
));
