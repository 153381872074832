import { Instance, types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';
import { PipelineStatus } from '../types/PipelineStatus';
import { IntegrationTrigger } from './IntegrationTrigger';

export const BaseAction = t.model({
  status: t.string,
  errorMessage: t.string,
});

export const Pipeline = t.model({
  pipelineSteps: t.array(BaseAction),
  status: t.enumeration<PipelineStatus>(Object.values(PipelineStatus)),
});

export const IntegrationEntityData = t.model({
  amount: t.number,
  customerNumber: t.string,
  entityType: t.string,
  imported: t.string,
  invoiceNumber: t.string,
});

export const IntegrationEntity = t.model({
  id: t.string,
  createdAt: IsoDate,
  integrationId: t.string,
  name: t.string,
  trigger: t.maybeNull(t.frozen(IntegrationTrigger)),
  status: t.string,
  statusMessage: t.maybeNull(t.string),
  executeStartTimestamp: t.maybeNull(IsoDate),
  executeStopTimestamp: t.maybeNull(IsoDate),
  metadata: t.array(t.frozen())
});

export type IntegrationEntityInstance = Instance<typeof IntegrationEntity>;
export type BaseActionInstance = Instance<typeof BaseAction>;
