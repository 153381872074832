import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { TimeInterval } from '../types/TimeInterval';

export const getDateTimesFromInterval = (
  interval: TimeInterval,
): [string, string] => {
  const now = new Date();

  switch (interval) {
    case TimeInterval.Day:
      return [startOfDay(now).toISOString(), endOfDay(now).toISOString()];
    case TimeInterval.Month:
      return [startOfMonth(now).toISOString(), endOfMonth(now).toISOString()];
    case TimeInterval.Year:
      return [startOfYear(now).toISOString(), endOfYear(now).toISOString()];
  }
};
