import React, { FC, ButtonHTMLAttributes } from 'react';
import { Spinner } from '../../../icons';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  className,
  children,
  isLoading,
  disabled,
  ...props
}) => {
  const useDefaultBg = !className?.includes('bg-');

  return (
    <button
      disabled={isLoading}
      className={`${disabled && 'opacity-30'} flex h-11 justify-center rounded-lg ${
        useDefaultBg && 'bg-titles'
      }  py-2 px-4 text-xl font-bold text-popup hover:cursor-pointer hover:opacity-80 ${className}`}
      {...props}
    >
      <span className="self-center">{isLoading ? <Spinner /> : children}</span>
    </button>
  );
};
