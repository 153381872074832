import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterIcon, ThickCrossIcon } from '../../../icons';

interface ActiveFiltersProps<TFilter> {
  filters: TFilter[];
  onFilterClick: () => void;
}

interface FilterObj {
  name: string;
  value: string | { id: string } | null;
  displayName: string | undefined;
}

export const ActiveFilters = observer(
  <TFilter extends FilterObj>({
    filters,
    onFilterClick,
  }: ActiveFiltersProps<TFilter>) => {
    const navigate = useNavigate();
    const location = useLocation();

    const removeAllFilters = () => {
      const searchParams = new URLSearchParams(location.search);

      filters.forEach((filter) => {
        searchParams.delete(filter.name);
      });

      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    };

    const removeFilter = (filter: TFilter) => {
      const searchParams = new URLSearchParams(location.search);
      const filterToRemove = `${filter.name}=${
        typeof filter.value === 'string' ? filter.value : filter.value?.id
      }`;
      const decodedParams = decodeURIComponent(searchParams.toString());
      const index = decodedParams.indexOf(filterToRemove);

      navigate(
        `${location.pathname}?${decodedParams.replace(
          `${index > 0 ? '&' : ''}${filterToRemove}`,
          '',
        )}`,
        {
          replace: true,
        },
      );
    };

    return (
      <div className="flex min-w-0 flex-wrap items-center gap-2 whitespace-nowrap">
        <button className="flex items-center gap-2" onClick={onFilterClick}>
          <FilterIcon />
          <span>Filter</span>
        </button>
        {filters.length !== 0 && (
          <button
            className="flex h-fit items-center rounded px-1.5 py-0.5 text-sm text-titles transition hover:bg-titles hover:text-background"
            onClick={removeAllFilters}
          >
            <span>Rensa alla</span> <ThickCrossIcon className="w-5" />
          </button>
        )}
        {filters.map((filter, key) => {
          return (
            <button
              key={`${filter.name}-${filter.value}-${key}`}
              className="flex h-fit items-center rounded px-1.5 py-0.5 text-sm text-titles transition hover:bg-titles hover:text-background"
              onClick={() => removeFilter(filter)}
            >
              <span className="">{filter.displayName}</span>
              <ThickCrossIcon className="w-5" />
            </button>
          );
        })}
      </div>
    );
  },
);
