import { types as t, Instance } from 'mobx-state-tree';
import { FilterInputType } from '../../components/general/Filter';
import { CUSTOMER_INVOICE_TYPE_TEXTS } from '../../constants';
import { CustomerInvoiceFilter } from '../../types/finance';
import { numberToMoney } from '../../utils/numberToMoney';

export const CustomerInvoiceFilterInputType = (filter: CustomerInvoiceFilter): FilterInputType => {
  switch (filter) {
    case CustomerInvoiceFilter.Type:
      return FilterInputType.Select;
    default:
      return FilterInputType.Text;
  }
};

const DefaultFilter = t
  .model('DefaultFilter', {
    name: t.enumeration<CustomerInvoiceFilter>(Object.values(CustomerInvoiceFilter)),
    value: t.string,
  })
  .views((self) => {
    return {
      get displayName(): string {
        switch (self.name) {
          case CustomerInvoiceFilter.Type:
            return CUSTOMER_INVOICE_TYPE_TEXTS[self.value];
          case CustomerInvoiceFilter.DocumentNumber:
            return `Invoice #${self.value}`;
          case CustomerInvoiceFilter.CustomerNumber:
            return `Customer #${self.value}`;
          case CustomerInvoiceFilter.Amount:
            return numberToMoney(self.value, 'SEK');
          default:
            return self.value;
        }
      },
      get inputType() {
        return CustomerInvoiceFilterInputType(self.name);
      },
    };
  });

export const CustomerInvoiceFilterModel = t.union(
  {
    dispatcher(snapshot) {
      return DefaultFilter;
    },
  },
  DefaultFilter,
);

export type CustomerInvoiceFilterInstance = Instance<typeof CustomerInvoiceFilterModel>;
