export enum ExternalSystemType {
  None = 'None',
  Fortnox = 'Fortnox',
  SFTP = 'SFTP',
  TestOauth = 'Test Oauth',
  TwentyFourSevenOffice = 'TwentyFourSevenOffice',
  Bjornlunden = 'Bjornlunden',
  HarvestFinance = 'HarvestFinance'
}

