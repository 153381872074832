import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useStore } from '../../hooks/useStore';
import { IntegrationIcon } from '../../icons';
import { ExternalSystemInstance } from '../../models';
import {
  ExternalSystemDirection,
  ExternalSystemType,
  IntegrationEntityType,
} from '../../types';
import { AddButton, ExternalSystemButton } from '../general/buttons';
import { PopupModal } from '../general/PopupModal';
import { ManageSystemPopup } from './ManageSystemPopup';

interface SelectSystemProps {
  onAddedSystem: (id: string) => void;
  selectSystem: (id?: string) => void;
  className?: string;
  disabled?: boolean;
  entityType?: IntegrationEntityType;
  externalSystems?: Array<ExternalSystemInstance>;
  externalSystemDirection?: ExternalSystemDirection;
  parentState?: object;
}

interface SelectSystemState {
  addNewSystem: boolean;
  setaddNewSystem: (selecting: boolean) => void;
}

export const SelectSystem: FC<SelectSystemProps> = observer(
  ({
    selectSystem,
    className,
    disabled,
    entityType,
    externalSystems,
    externalSystemDirection,
    onAddedSystem,
    parentState,
  }) => {
    const { externalSystemStore } = useStore();

    const state = useLocalObservable<SelectSystemState>(() => ({
      addNewSystem: false,
      setaddNewSystem(addNewSystem: boolean) {
        state.addNewSystem = addNewSystem;
      },
    }));

    useEffect(() => {
      externalSystemStore.fetchSystems();
      externalSystemStore.getAddedSystems();
    }, []);

    return (
      <>
        <PopupModal
          titleIcon={<IntegrationIcon className="h-6" />}
          title={externalSystemDirection == ExternalSystemDirection.Reader ? "Where should we get you data from?" : "Where should we send your data?"}
          className={`w-[442px] ${className}`}
          open={!state.addNewSystem}
          onClose={() => selectSystem(undefined)}
          canClose={true}
        >
          <div className="flex w-full flex-wrap gap-4 rounded-md bg-selectors p-7">
            <AddButton
              description="Add new"
              onClick={() => state.setaddNewSystem(true)}
            />

            {externalSystems &&
              externalSystems?.map((system: ExternalSystemInstance) => {
                return (
                  <div key={system.id}>
                    <ExternalSystemButton
                      logoUrl={system.systemLogo || undefined}
                      onClick={() => selectSystem(system.id)}
                      externalSystemType={
                        system.externalSystemType as ExternalSystemType
                      }
                      externalSystemName={system.name}
                      description={system.name}
                    ></ExternalSystemButton>
                  </div>
                );
              })}
          </div>
        </PopupModal>

        {state.addNewSystem && (
          <ManageSystemPopup
            externalSystemType={ExternalSystemType.None}
            open={true}
            onClose={() => state.setaddNewSystem(false)}
            parentState={{ ...parentState, redirect: location }}
            onAddedSystem={onAddedSystem}
            entityType={entityType}
            externalSystemDirection={externalSystemDirection}
          />
        )}
      </>
    );
  },
);
