import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { InputWithError, Label, PopupModal } from '../../components/general';
import { Button } from '../../components/general/buttons';
import { useStore } from '../../hooks';
import { LoginIcon, NewPasswordIcon } from '../../icons';

type FormData = {
  email: string;
};

export const RecoverPasswordPage = observer(() => {
  const { authStore } = useStore();

  const state = useLocalObservable(() => ({
    isSubmitting: false,
    setIsSubmitting(isSubmiting: boolean) {
      state.isSubmitting = isSubmiting;
    },

    hasFormError: false,
    setHasFormError(hasFormError: boolean) {
      state.hasFormError = hasFormError;
    },

    passwordResetConfirmed: false,
    setPasswordResetConfirmed(value: boolean) {
      state.passwordResetConfirmed = value;
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit(async ({ email }) => {
    if (state.isSubmitting) return;
    state.setIsSubmitting(true);
    state.setHasFormError(false);
    try {
      const result = await authStore.recoverPassword(email.toLowerCase());
      if (result.status == 200) state.setPasswordResetConfirmed(true);
    } catch (error) {
      state.setHasFormError(true);
    } finally {
      state.setIsSubmitting(false);
    }
  });

  const ResetPasswordView = () => {
    return (
      <PopupModal
        title="Återställ Lösenord"
        titleIcon={<NewPasswordIcon className="mr-2 h-7 w-7" />}
      >
        <div className="flex w-full items-center justify-center">
          <div className="w-full max-w-md">
            {state.hasFormError && (
              <div
                className="mb-7 rounded-md border border-solid border-pink-500 p-3 text-pink-500"
                style={{ backgroundColor: '#EC48990F' }}
              >
                Ett fel inträffade, försök gärna igen.
              </div>
            )}
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-light-rose">
                  E-post
                </Label>
                <InputWithError
                  type="text"
                  placeholder="namn.namnsson@e-post.se"
                  {...register('email', {
                    required: 'Fill in this field',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Ange en giltig e-post',
                    },
                  })}
                  error={errors.email?.message}
                  className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                />
              </div>
              <div className="mt-6 text-center">
                <Button
                  isLoading={state.isSubmitting}
                  className="w-full font-extrabold text-popup"
                >
                  Skicka
                </Button>
              </div>
            </form>
          </div>
        </div>
      </PopupModal>
    );
  };

  const PasswordResetConfirmedView = () => {
    return (
      <PopupModal
        title="Återställ Lösenord"
        titleIcon={<NewPasswordIcon className="mr-2 h-7 w-7" />}
      >
        <div className="flex w-full items-center justify-center">
          <div className="text-sky w-full max-w-md">
            <div className="mb-6">
              <p>Det är dags att öppna din mail!</p>
            </div>
            <div>
              <p>
                Vi har skickat ett mail till din mailadress med instruktioner på
                hur du återställer lösenordet för ditt konto.
              </p>
            </div>
          </div>
        </div>
      </PopupModal>
    );
  };

  return (
    <span>
      {state.passwordResetConfirmed
        ? PasswordResetConfirmedView()
        : ResetPasswordView()}
      <div className="text-sky mt-4 flex justify-center">
        <Link className="flex" to="/">
          <LoginIcon className="mr-2 h-6 w-6" />
          Logga in?
        </Link>
      </div>
    </span>
  );
});
