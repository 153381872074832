import React, { forwardRef } from 'react';

type SquareButtonProps = {
  className?: string;
  htmlFor?: string;
  required?: boolean;
  description?: string;
  small?: boolean;
  truncate?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: any;
};

export const SquareButton = forwardRef<HTMLDivElement, SquareButtonProps>(
  (
    { className, children, description, small, truncate, disabled, onClick },
    ref,
  ) => {
    return (
      <div className={`flex flex-col items-center ${small && 'w-32'}`}>
        <div
          ref={ref}
          tabIndex={0}
          className="rounded-lg border border-transparent focus:border focus:border-labels"
        >
          <button
            disabled={disabled}
            type="button"
            onClick={onClick}
            className={`${
              small ? 'h-8 w-8' : 'h-16 w-16 p-8'
            } flex justify-center rounded-lg align-middle hover:cursor-pointer hover:opacity-80  ${className}`}
          >
            <div className="self-center">{children}</div>
          </button>
        </div>
        <div
          className={`${!small && truncate ? 'w-16' : 'w-full'} ${
            truncate && 'truncate'
          }  mt-1 text-center text-xs font-light`}
        >
          {description}
        </div>
      </div>
    );
  },
);
