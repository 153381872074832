import React, { FC } from 'react';

interface RowItem {
  value: string | number | JSX.Element | undefined;
  className?: string;
}

interface TableRowProps {
  index: number;
  items: RowItem[];
}

export const TableRow: FC<TableRowProps> = ({ index, items }) => {
  return (
    <tr className={`${index % 2 === 0 ? 'bg-popup' : ''}`}>
      {items.map((item, index) => {
        return (
          <td
            key={index}
            className={`py-2 pl-5 text-xl text-labels first-of-type:rounded-l-4px last-of-type:rounded-r-4px md:text-base xs:pl-7 ${
              item.className || ''
            }`}
          >
            {item.value || ''}
          </td>
        );
      })}
    </tr>
  );
};
