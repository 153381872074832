import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChevronUpDownIcon, TriangleDownIcon } from '../../../icons';

type TableHeaderCellProps = {
  className?: string;
  title: string;
  isActive?: boolean;
  isAscending?: boolean;
  onClick?: () => void;
};

export const TableHeaderCell = observer(
  ({
    className = '',
    title,
    isActive = false,
    isAscending = false,
    onClick,
  }: TableHeaderCellProps) => {
    return (
      <th
        className={`cursor-pointer bg-background pl-5 pt-7 pb-3 text-titles xs:pl-7 ${className}`}
        onClick={onClick}
      >
        <div className="group flex">
          <span className="text-sm font-semibold">{title}</span>
          <span className="relative">
            {isActive && (
              <TriangleDownIcon
                className={`absolute w-5 ${isAscending ? 'rotate-180' : ''}`}
              />
            )}
            {!isActive && (
              <ChevronUpDownIcon className="absolute hidden w-5 group-hover:block" />
            )}
          </span>
        </div>
      </th>
    );
  },
);
