import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useStore } from '../../hooks';
import { SftpFolderBrowser } from '../integrationHub';
import { Label } from './Label';

interface SftpSourceConfigurationProps {
  systemId: string;
  downloadPath?: string;
  processedPath?: string;
  onDownloadPathChange: (path: string) => void;
  onProcessedPathChange: (path: string) => void;
  onValidation: (success: boolean) => void;
}

interface SftpSourceConfigurationState {
  downloadPath: string;
  processedPath: string;
  isDownloadValidating: boolean;
  isProcessedValidating: boolean;
  setIsDownloadValidating: (isLoading: boolean) => void;
  setIsProcessedValidating: (isLoading: boolean) => void;
  downloadPathIsSuccessful?: boolean;
  processedPathIsSuccessful?: boolean;
  setDownloadPathIsSuccessful: (isSuccess: boolean) => void;
  setProcessedPathIsSuccessful: (isSuccess: boolean) => void;
  setDownloadPath: (path: string) => void;
  setProcessedPath: (path: string) => void;
}

export const SftpSourceConfiguration: FC<SftpSourceConfigurationProps> =
  observer(
    ({
      systemId,
      downloadPath,
      processedPath,
      onDownloadPathChange,
      onProcessedPathChange,
      onValidation,
    }) => {
      const state = useLocalObservable<SftpSourceConfigurationState>(() => ({
        downloadPath: downloadPath || '',
        processedPath: processedPath || '',
        downloadPathIsSuccessful: undefined,
        isDownloadValidating: false,
        isProcessedValidating: false,
        setDownloadPathIsSuccessful(isSuccess) {
          state.downloadPathIsSuccessful = isSuccess;
          onValidation(
            (state.downloadPathIsSuccessful &&
              state.processedPathIsSuccessful) ||
              false,
          );
        },
        setProcessedPathIsSuccessful(isSuccess) {
          state.processedPathIsSuccessful = isSuccess;
          onValidation(
            (state.downloadPathIsSuccessful &&
              state.processedPathIsSuccessful) ||
              false,
          );
        },
        setDownloadPath: (path) => (state.downloadPath = path),
        setProcessedPath: (path) => (state.processedPath = path),
        setIsDownloadValidating: (isLoading: boolean) =>
          (state.isDownloadValidating = isLoading),
        setIsProcessedValidating: (isLoading: boolean) =>
          (state.isProcessedValidating = isLoading),
      }));

      const { externalSystemStore } = useStore();

      useEffect(() => {
        testAccessForProcessedPath(processedPath || '');
        testAccessForDownloadPath(downloadPath || '');
      }, []);

      const testAccessForDownloadPath = async (path: string) => {
        try {
          if (!path) {
            return;
          }

          state.setIsDownloadValidating(true);

          await externalSystemStore
            .testSftpFolderAccess(systemId, path!)
            .then((result) => {
              state.setDownloadPathIsSuccessful(result);
            });
        } catch (error) {
          state.setDownloadPathIsSuccessful(false);
        }

        setTimeout(() => {
          state.setIsDownloadValidating(false);
        }, 1000);
      };

      const testAccessForProcessedPath = async (path: string) => {
        try {
          if (!path) {
            return;
          }

          state.setIsProcessedValidating(true);

          externalSystemStore
            .testSftpFolderAccess(systemId, path!)
            .then((result) => {
              state.setProcessedPathIsSuccessful(result);
            });
        } catch (error) {
          state.setProcessedPathIsSuccessful(false);
        }

        state.setIsProcessedValidating(false);
      };

      return (
        <div>
          <div>
            <Label
              className="mb-2 inline-block whitespace-nowrap text-xl font-semibold text-labels"
              required
            >
              Source folder
            </Label>
            <SftpFolderBrowser
              statusColor={
                state.downloadPathIsSuccessful === undefined
                  ? 'labels'
                  : state.downloadPathIsSuccessful === true
                  ? 'success'
                  : 'error'
              }
              systemId={systemId}
              value={state.downloadPath || ''}
              onChange={(folder) => {
                state.setDownloadPath(folder.fullPath);
                testAccessForDownloadPath(folder.fullPath);
                onDownloadPathChange(folder.fullPath);
              }}
              isLoading={state.isDownloadValidating}
            />
          </div>
          <div className="mt-4">
            <Label
              className="mb-2 inline-block whitespace-nowrap text-xl font-semibold text-labels"
              required
            >
              Processed folder
            </Label>
            <SftpFolderBrowser
              statusColor={
                state.processedPathIsSuccessful === undefined
                  ? 'labels'
                  : state.processedPathIsSuccessful === true
                  ? 'success'
                  : 'error'
              }
              systemId={systemId}
              value={state.processedPath || ''}
              onChange={(folder) => {
                state.setProcessedPath(folder.fullPath);
                testAccessForProcessedPath(folder.fullPath);
                onProcessedPathChange(folder.fullPath);
              }}
              isLoading={state.isProcessedValidating}
            />
          </div>
        </div>
      );
    },
  );
