import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { PopupModal } from './PopupModal';

interface SlideInPopupProps {
  isOpen: boolean;
  onToggle: () => void;
  title?: string;
}

export const SlideInPopup: FC<SlideInPopupProps> = observer(
  ({ onToggle, isOpen, title, children }) => {
    return (
      <div
        className={`absolute inset-0 z-50 h-full w-full bg-black bg-opacity-70 ${
          isOpen ? 'animate-fadeIn' : 'translate-y-full'
        }`}
      >
        <div
          className={`flex h-full w-full items-center justify-center bg-opacity-50 duration-300 ${
            isOpen ? 'animate-slideUp' : ''
          }`}
        >
          <PopupModal
            className="p-4 sm:p-6 md:p-12"
            title={title}
            onClose={onToggle}
            open={isOpen}
            closeOnClickOutside
            canClose
          >
            {children}
          </PopupModal>
        </div>
      </div>
    );
  },
);
