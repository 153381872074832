import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FadeInDiv,
  NothingToDisplay,
  PageContainer,
} from '../../components/general';
import {
  AddButton,
  Button,
  ExternalSystemButton,
} from '../../components/general/buttons';
import { ManageSystemPopup } from '../../components/integrationHub';
import { useStore } from '../../hooks/useStore';
import { ExternalSystemIcon } from '../../icons/ExternalSystemIcon';
import { ExternalSystemInstance } from '../../models';
import {
  ExternalSystemDirection,
  ExternalSystemType,
  IntegrationEntityType,
} from '../../types';

interface SystemsPageState {
  externalSystemType: ExternalSystemType;
  setExternalSystemType: (externalSystemType: ExternalSystemType) => void;
  externalSystemId: string;
  setExternalSystemId: (externalSystemId: string) => void;
  addNewSystem: boolean;
  setaddNewSystem: (selecting: boolean) => void;
}

export const SystemsPage = observer(() => {
  const { externalSystemStore } = useStore();
  const { externalSystems } = externalSystemStore;
  const location = useLocation();
  const navigate = useNavigate();

  const state = useLocalObservable<SystemsPageState>(() => ({
    addNewSystem: false,
    setaddNewSystem(addNewSystem: boolean) {
      state.addNewSystem = addNewSystem;
    },
    externalSystemType: ExternalSystemType.None,
    setExternalSystemType(externalSystemType: ExternalSystemType) {
      state.externalSystemType = externalSystemType;
    },
    externalSystemId: '',
    setExternalSystemId(externalSystemId: string) {
      state.externalSystemId = externalSystemId;
    },
  }));

  const setExternalSystemInfo = (system: ExternalSystemInstance) => {
    state.setExternalSystemType(
      system.externalSystemType as ExternalSystemType,
    );
    state.setExternalSystemId(system.id);
  };

  const resetExternalSystemInfo = () => {
    state.setExternalSystemType(ExternalSystemType.None);
    state.setExternalSystemId('');
  };

  useEffect(() => {
    externalSystemStore.fetchSystems();
  }, []);

  const isLoading = externalSystems === null;

  return (
    <>
      {!state.addNewSystem && (
        <div>
          {state.externalSystemType === ExternalSystemType.None && (
            <PageContainer
              useBackground={externalSystems?.length !== 0}
              title="Sources & destinations"
              titleIcon={<ExternalSystemIcon className="w-12" />}
            >
              {externalSystems && externalSystems.length > 0 ? (
                <div className="flex flex-col items-start gap-5">
                  <AddButton
                    description="Add new"
                    onClick={() => state.setaddNewSystem(true)}
                  />
                  <div className="flex flex-wrap gap-5">
                    {state.externalSystemType == ExternalSystemType.None &&
                      externalSystems?.map((system: ExternalSystemInstance) => {
                        return (
                          <ExternalSystemButton
                            logoUrl={system.systemLogo || undefined}
                            description={system.name}
                            key={system.id}
                            externalSystemType={
                              system.externalSystemType as ExternalSystemType
                            }
                            externalSystemName={system.name}
                            onClick={() => setExternalSystemInfo(system)}
                          />
                        );
                      })}
                  </div>
                </div>
              ) : (
                <NothingToDisplay
                  className={`mt-5 sm:mt-20 ${isLoading && 'animate-pulse'}`}
                  loading={isLoading}
                  text="There are currently no sources or destinations to display"
                >
                  {!isLoading && (
                    <Button onClick={() => navigate('/integrations/create')}>
                      {"Let's get started!"}
                    </Button>
                  )}
                </NothingToDisplay>
              )}
            </PageContainer>
          )}
        </div>
      )}
      {/* Add new system */}
      {(state.addNewSystem || state.externalSystemId.length > 0) && (
        <FadeInDiv className="mb-32 mt-32 flex h-full w-full justify-center">
          <ManageSystemPopup
            systemId={
              state.externalSystemId.length > 0
                ? state.externalSystemId
                : undefined
            }
            externalSystemType={state.externalSystemType}
            showTypeSelector={false}
            open={true}
            onClose={() => {
              state.setaddNewSystem(false);
              resetExternalSystemInfo();
            }}
            onAddedSystem={() => {
              state.setaddNewSystem(false);
              resetExternalSystemInfo();
            }}
            parentState={{ redirect: location }}
            entityType={IntegrationEntityType.Unknown}
            externalSystemDirection={ExternalSystemDirection.Any}
          />
        </FadeInDiv>
      )}
    </>
  );
});
