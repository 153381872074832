import {
  applySnapshot,
  flow,
  getParentOfType,
  types as t,
} from 'mobx-state-tree';
import i18n from '../config/i18n';
import { withRequest } from '../extensions';

import {
  DashboardSettings,
  IntegrationStatsSettingType,
} from './DashboardSettings';
import { ExternalSystemStore } from './ExternalSystemStore';
import { IntegrationStore } from './IntegrationStore';

export const SettingsStore = t
  .model('SettingsStore', {
    dashboard: t.maybeNull(DashboardSettings),
    darkMode: t.maybeNull(t.boolean),
    language: i18n.language,
  })
  .extend(withRequest)
  .actions((self) => {
    return {
      reset() {
        applySnapshot(self, {
          dashboard: {
            defaultColors: [
              '#FB7185',
              '#F472B6',
              '#E879F9',
              '#C084FC',
              '#A78BFA',
              '#818CF8',
              '#60A5FA',
              '#38BDF8',
              '#22D3EE',
              '#2DD4BF',
              '#34D399',
              '#4ADE80',
              '#A3E635',
              '#FACC15',
              '#FBBF24',
              '#FB923C',
              '#F87171',
              '#A8A29E',
              '#A1A1AA',
              '#94A3B8',
            ],
          },
          darkMode: false,
        });
      },
    };
  })
  .actions((self) => {
    return {
      setDashboardDefault: flow(function* () {
        const integrationStore = getParentOfType(self, IntegrationStore);
        const externalSystemsStore = getParentOfType(self, ExternalSystemStore);

        if (self.dashboard === null) self.reset();

        yield integrationStore.fetchIntegrations();
        yield externalSystemsStore.fetchSystems();

        if (!integrationStore.integrations) return;

        const integrations = integrationStore.integrations.slice(0, 3);
        const settings = integrations.map((integration, index) => {
          return {
            id: integration.id,
            name:
              externalSystemsStore.externalSystems?.find(
                (x) => x.id == integration.sourceSystemId,
              )?.name || '',
            color: self.dashboard?.defaultColors[index] || '',
          };
        });

        self.dashboard?.integrationStatsSettings.replace(settings);
      }),
      setDashboardIntegrationStats(
        integrations: IntegrationStatsSettingType[],
      ) {
        self.dashboard?.integrationStatsSettings.replace(integrations);
      },
      setDarkMode() {
        const body = document.getElementsByTagName('body')[0];
        self.darkMode = true;

        if (body?.classList.contains('darkMode')) return;
        body!.classList.add('darkMode');
      },
      removeDarkMode() {
        const body = document.getElementsByTagName('body')[0];
        self.darkMode = false;

        if (body?.classList.contains('darkMode')) {
          body!.classList.remove('darkMode');
        }
      },
      toggleDarkMode() {
        const body = document.getElementsByTagName('body')[0];
        self.darkMode = !self.darkMode;

        if (self.darkMode) body!.classList.remove('darkMode');
        else body!.classList.add('darkMode');
      },
      changeLanguage(lng: string) {
        self.language = lng;
        i18n.changeLanguage(lng);
      },
    };
  });
