import React, { FC, memo, SVGProps } from 'react';

export const HarvestTextIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
<svg
  className='ml-2'
  viewBox="0 0 80 22"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio='xMidYMid meet'
  {...props}
  >
  <path
    d="M-6.10352e-05 20.6425V0.872681H3.5767V20.6425H-6.10352e-05ZM8.84636 20.6425V13.0686C8.84636 12.2848 8.60029 11.6464 8.10949 11.1549C7.6187 10.662 6.99075 10.4169 6.22565 10.4169C5.71603 10.4169 5.26019 10.526 4.86083 10.7455C4.46013 10.9636 4.14683 11.2788 3.91958 11.6882C3.69234 12.0989 3.57804 12.5595 3.57804 13.0686L2.18633 12.3306C2.18633 11.3098 2.40417 10.4128 2.84117 9.63713C3.27818 8.86276 3.88731 8.25674 4.66989 7.81906C5.45248 7.38137 6.34398 7.1632 7.34574 7.1632C8.3475 7.1632 9.22958 7.37733 9.99469 7.80559C10.7584 8.23385 11.3595 8.83044 11.7965 9.59673C12.2335 10.3617 12.4514 11.2465 12.4514 12.2498V20.6439H8.84771L8.84636 20.6425Z"
  />
  <path
    d="M19.1678 20.9159C17.9671 20.9159 16.8887 20.6156 15.9326 20.0136C14.9766 19.4116 14.2263 18.5915 13.6803 17.5531C13.1344 16.5148 12.8615 15.3472 12.8615 14.053C12.8615 12.7588 13.1344 11.5643 13.6803 10.526C14.2263 9.4863 14.9766 8.66615 15.9326 8.06551C16.8887 7.46353 17.9751 7.16321 19.1947 7.16321C20.1225 7.16321 20.9602 7.35444 21.7065 7.73691C22.4528 8.11938 23.0539 8.64864 23.5084 9.32335C23.9628 9.99805 24.2089 10.7724 24.2452 11.6478V16.4327C24.2089 17.2892 23.9669 18.0595 23.5218 18.7436C23.0754 19.4278 22.4743 19.9611 21.72 20.3435C20.9643 20.726 20.1131 20.9172 19.1678 20.9172V20.9159ZM19.8509 17.607C20.8513 17.607 21.6622 17.2703 22.2807 16.5956C22.8992 15.9209 23.2085 15.0738 23.2085 14.053C23.2085 13.3608 23.0619 12.74 22.7715 12.1932C22.4797 11.6464 22.0884 11.2222 21.5976 10.9219C21.1068 10.6216 20.5232 10.4708 19.8509 10.4708C19.1786 10.4708 18.6179 10.6256 18.1177 10.9354C17.6175 11.2451 17.2262 11.6653 16.9438 12.1932C16.6614 12.7225 16.5202 13.3325 16.5202 14.0247C16.5202 14.717 16.6614 15.3553 16.9438 15.8846C17.2262 16.4138 17.6215 16.8326 18.1311 17.1424C18.6407 17.4521 19.2136 17.607 19.8509 17.607ZM22.9907 20.6425V17.0872L23.5917 13.8604L22.9907 10.6606V7.4339H26.5943V20.6412H22.9907V20.6425Z"
  />
  <path
    d="M27.658 20.6425V7.43525H31.2347V20.6425H27.658ZM31.2347 13.3689L29.843 12.4114C30.0071 10.7886 30.4804 9.5092 31.263 8.56919C32.0455 7.63053 33.1737 7.16052 34.6488 7.16052C35.3036 7.16052 35.8818 7.26557 36.382 7.47431C36.8822 7.6844 37.3421 8.02646 37.7603 8.49916L35.5214 11.097C35.3211 10.86 35.0804 10.6916 34.798 10.5906C34.5156 10.491 34.1929 10.4398 33.8285 10.4398C33.0648 10.4398 32.4409 10.6768 31.9581 11.1509C31.4754 11.6249 31.2347 12.3629 31.2347 13.3662V13.3689Z"
  />
  <path
    d="M41.1448 20.6426L35.3292 7.4353H39.2609L43.4374 18.3181H41.4984L45.6762 7.4353H49.499L43.7117 20.6426H41.1448Z"
  />
  <path
    d="M54.887 20.9441C53.5034 20.9441 52.2703 20.6438 51.1879 20.0418C50.1054 19.4399 49.2543 18.6197 48.6358 17.5814C48.0172 16.5417 47.7079 15.358 47.7079 14.026C47.7079 12.6941 48.0118 11.5373 48.6223 10.499C49.2314 9.45932 50.0651 8.63917 51.1207 8.03853C52.1762 7.43655 53.3595 7.13623 54.6705 7.13623C55.9076 7.13623 57.0183 7.41904 58.0012 7.98332C58.9841 8.54894 59.7479 9.33273 60.2952 10.3347C60.8411 11.338 61.1141 12.4773 61.1141 13.7527C61.1141 13.9897 61.1046 14.2307 61.0872 14.4772C61.0683 14.7236 61.0226 14.993 60.95 15.2839L49.8379 15.3108V12.6308L59.284 12.6039L57.8103 13.7244C57.7914 12.9406 57.6556 12.2847 57.4001 11.7555C57.1447 11.2276 56.7897 10.8262 56.3352 10.5529C55.8794 10.2795 55.3159 10.1421 54.6423 10.1421C53.9323 10.1421 53.3138 10.297 52.7853 10.6067C52.2569 10.9165 51.8521 11.3582 51.5698 11.9332C51.2874 12.5069 51.1462 13.187 51.1462 13.9708C51.1462 14.7546 51.2968 15.4711 51.5967 16.0623C51.8965 16.6548 52.3295 17.106 52.8929 17.4157C53.4563 17.7255 54.1125 17.8804 54.8588 17.8804C55.5324 17.8804 56.1416 17.7713 56.6875 17.5518C57.2334 17.3336 57.7067 16.9875 58.1074 16.5134L60.2373 18.6466C59.5637 19.4116 58.7717 19.9866 57.8627 20.3691C56.9524 20.7516 55.96 20.9428 54.887 20.9428V20.9441Z"
  />
  <path
    d="M66.2492 21.1273C65.4855 21.1273 64.7432 21.0317 64.0238 20.8405C63.3045 20.6492 62.6456 20.3664 62.0445 19.9934C61.4435 19.6204 60.9258 19.1773 60.4888 18.6669L62.6456 16.5067C63.0826 17.036 63.6016 17.4279 64.2013 17.6824C64.8024 17.9383 65.4572 18.0649 66.1672 18.0649C66.7494 18.0649 67.1959 17.9787 67.5051 17.805C67.8144 17.6326 67.969 17.3902 67.969 17.0804C67.969 16.7155 67.805 16.4381 67.4782 16.2468C67.1515 16.0556 66.736 15.8872 66.2358 15.7405C65.7356 15.595 65.2031 15.4401 64.6383 15.2758C64.0736 15.1115 63.5411 14.8893 63.0409 14.6065C62.5394 14.3237 62.1265 13.9278 61.7985 13.4174C61.4704 12.907 61.3077 12.2511 61.3077 11.4485C61.3077 10.6458 61.5161 9.87684 61.9356 9.24657C62.3538 8.61766 62.9454 8.13418 63.7105 7.7975C64.4756 7.46082 65.3766 7.29114 66.4133 7.29114C67.5051 7.29114 68.4921 7.47833 69.3755 7.85137C70.2576 8.22576 70.9904 8.79542 71.574 9.56036L69.4737 10.2714C69.073 9.79738 68.7126 10.3792 68.1627 10.3792C67.5589 10.3792 67.0332 10.3536 66.4697 10.3536C65.9063 10.3536 65.5137 10.4357 65.2407 10.6C64.9678 10.7643 64.8306 10.9919 64.8306 11.2842C64.8306 11.6128 64.9853 11.8632 65.2945 12.0356C65.6038 12.2094 66.0139 12.3683 66.5235 12.5137C67.0331 12.6605 67.5656 12.8194 68.121 12.9918C68.6763 13.1655 69.2034 13.4066 69.705 13.7163C70.2052 14.0261 70.6153 14.4368 70.934 14.9472C71.2527 15.4576 71.4113 16.1135 71.4113 16.9161C71.4113 18.2103 70.9474 19.2365 70.0196 19.992C69.0918 20.7489 67.8359 21.1273 66.2519 21.1273H66.2492Z"
  />
  <path
    d="M66.0301 10.3859V7.29114H79.4685V10.3859H66.0301ZM72.8071 20.8257V2.12378H76.3839V20.827H72.8071V20.8257Z"
  />
</svg>
));




          
          