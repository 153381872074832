import React, { FC, memo, SVGProps } from 'react';

export const CloseIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="currentColor"
  >
    <path d="M29.1308 13.9451L23.1375 19.9384L28.9003 25.7013C29.7528 26.5537 29.7253 27.9523 28.8388 28.8388C27.9523 29.7253 26.5537 29.7527 25.7013 28.9003L19.9385 23.1375L13.9451 29.1308C13.0585 30.0174 11.66 30.0448 10.8076 29.1924C9.95517 28.3399 9.98259 26.9414 10.8691 26.0548L16.8625 20.0615L11.0996 14.2986C10.2472 13.4462 10.2746 12.0476 11.1611 11.1611C12.0477 10.2746 13.4462 10.2472 14.2986 11.0996L20.0615 16.8625L26.0549 10.8691C26.9414 9.98255 28.3399 9.95516 29.1924 10.8076C30.0447 11.6599 30.0174 13.0586 29.1308 13.9451Z" />
    <rect width="40" height="40" rx="20" fill="none" />
  </svg>
));
