import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  FadeInDiv,
  InputWithError,
  Label,
  PopupModal,
} from '../../components/general';
import { Button } from '../../components/general/buttons';
import { useStore } from '../../hooks';
import { UserPlusIcon } from '../../icons';

export const RelationInvitePage = observer(() => {
  const navigate = useNavigate();
  const { relationStore } = useStore();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);

  type FormData = {
    orgNo: string;
    contactName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = handleSubmit(
    async ({ orgNo, contactName, companyName, email, phoneNumber }) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      setHasFormError(false);
      let isError = false;

      try {
        await relationStore.invite(
          orgNo,
          contactName,
          companyName,
          email,
          phoneNumber,
        );
      } catch (error) {
        isError = true;
      } finally {
        setIsSubmitting(false);
        setHasFormError(isError);
      }
      if (!isError) navigate('/relations');
    },
  );

  return (
    <FadeInDiv className="mt-32 flex items-center self-center">
      <PopupModal
        className="w-[442px]"
        title="Invitation"
        titleIcon={<UserPlusIcon className="mr-0 h-7 w-7" />}
        canClose={true}
        onClose={() => navigate('/relations')}
      >
        <div className="flex w-full items-center">
          <form className="w-full" onSubmit={onSubmit}>
            {hasFormError && (
              <div className="mb-7 rounded-md border border-solid border-error p-2 text-error ">
                An error has occurred. Please check your inputs and try again.
              </div>
            )}
            <div className="mb-5">
              <Label
                className="mb-2 inline-block whitespace-nowrap font-semibold text-labels"
                required
              >
                Organisation number
              </Label>
              <InputWithError
                type="text"
                placeholder="Organisation number"
                {...register('orgNo', {
                  required: 'Required value',
                })}
                error={errors.orgNo?.message}
                className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              />
            </div>
            <div className="mb-5">
              <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
                Contact
              </Label>
              <InputWithError
                type="text"
                placeholder="Contact"
                {...register('contactName')}
                className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              />
            </div>
            <div className="mb-5">
              <Label
                className="mb-2 inline-block whitespace-nowrap font-semibold text-labels"
                required
              >
                Company name
              </Label>
              <InputWithError
                type="text"
                placeholder="Company name"
                {...register('companyName', {
                  required: 'Required value',
                })}
                error={errors.companyName?.message}
                className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              />
            </div>
            <div className="mb-5">
              <Label
                className="mb-2 inline-block whitespace-nowrap font-semibold text-labels"
                required
              >
                Email address
              </Label>
              <InputWithError
                type="text"
                placeholder="example@example.com"
                {...register('email', {
                  required: 'Required value',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Provide a valid email address',
                  },
                })}
                error={errors.email?.message}
                className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              />
            </div>
            <div className="mb-5">
              <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
                Phone number
              </Label>
              <InputWithError
                type="text"
                placeholder="Phone number"
                {...register('phoneNumber')}
                className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              />
            </div>
            <div className="mb-5">
              <Button
                isLoading={isSubmitting}
                className={'mt-10 w-full bg-titles text-xl font-bold'}
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </PopupModal>
    </FadeInDiv>
  );
});
