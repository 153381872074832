import React, { FC } from 'react';

export const PillTabs: FC<{
  tabs: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
}> = ({ tabs, value, onChange }) => {
  return (
    <div>
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={`rounded-md px-2 py-1 text-xs font-light ${
            tab.value === value ? 'bg-titles text-blocks' : 'text-titles'
          }`}
          onClick={() => value !== tab.value && onChange(tab.value)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};
