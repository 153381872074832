import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

type NotFound = {};

export const NotFound: FC<NotFound> = observer(({}) => {
  return (
    <div className="mt-32 flex items-center self-center">
      <span>Ooops! 404 - Page not found</span>
    </div>
  );
});