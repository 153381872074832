import { Instance, types as t } from 'mobx-state-tree';

export const Paging = t.model({
  currentPage: t.number,
  totalPages: t.number,
  pageSize: t.number,
  totalItems: t.number,
});

export type PageInstance = Instance<typeof Paging>;
