import _ from 'lodash';
import { PeriodData, TrendData } from '../../types';
import { format, getYear, subMonths, subYears } from 'date-fns';
import { TimeInterval } from '../../types/TimeInterval';

export const getTrendData = (
  entityType: string,
  currentPeriod: PeriodData,
  previousPeriod: PeriodData,
): TrendData => {
  const firstPeriod = currentPeriod.subTotals
    .filter((x) => _.camelCase(x.entityType) === entityType)
    .reduce((sum, current) => sum + current.count, 0);
  const secondPeriod = previousPeriod.subTotals
    .filter((x) => _.camelCase(x.entityType) === entityType)
    .reduce((sum, current) => sum + current.count, 0);

  const percentage =
    ((firstPeriod - secondPeriod) / Math.abs(secondPeriod)) * 100;

  return {
    isPositiveTrend: firstPeriod > secondPeriod,
    sum: firstPeriod ?? 0,
    trendPercentage: isFinite(percentage) ? percentage : 0,
  };
};

export const getTrendTexts = (
  interval: TimeInterval,
  t: (key: string) => string,
): [string, string] => {
  switch (interval) {
    case TimeInterval.Day:
      return [t('Today'), t('Yesterday DTD')];
    case TimeInterval.Month:
      return [t('This month'), t('Last month MTD')];
    case TimeInterval.Year:
      return [t('This year'), t('Last year YTD')];
  }
};
