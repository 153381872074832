import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { HeartIcon } from '../../../icons';
import { Card, ChartLegends } from '../../general/Dashboard';
import { IntegrationsStateData } from '../../../types/integrationHub';
import { fetchIntegrationsStateData } from '../../../api/fetchIntegrationsStateData';

const getChartData = (failed, disabled, active, isDarkMode: boolean) => ({
  labels: ['Failed', 'Disabled', 'Active'],
  datasets: [
    {
      data: [failed, disabled, active],
      backgroundColor: isDarkMode
        ? ['rgb(255, 143, 143)', 'rgb(255, 253, 171)', 'rgb(189, 255, 212)']
        : ['rgb(234, 127, 127)', 'rgb(221, 180, 34)', 'rgb(100, 186, 155)'],
    },
  ],
});

export const IntegrationsStateCard: FC = observer(() => {
  const {
    settingsStore: { darkMode },
    authStore,
    integrationStore,
  } = useStore();

  const [integrationsStateData, setIntegrationsStateData] =
    useState<IntegrationsStateData | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [data] = await Promise.all([fetchIntegrationsStateData(authStore)]);
      setIntegrationsStateData(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const failed = integrationsStateData?.failed;
  const disabled = integrationsStateData?.disabled;
  const active = integrationsStateData?.active;

  useLayoutEffect(() => {
    const ctx = canvasRef.current!.getContext('2d')!;

    chartRef.current = new Chart(ctx, {
      type: 'pie' as any,
      data: getChartData(failed, disabled, active, darkMode || false),
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
      },
    });

    return () => chartRef.current!.destroy();
  }, []);

  useLayoutEffect(() => {
    const chart = chartRef.current!;

    chart.data = getChartData(failed, disabled, active, darkMode || false);
    chart.update();
  }, [failed, disabled, active, darkMode]);

  const hasData =
    integrationStore.activeIntegrations &&
    integrationStore.activeIntegrations?.length > 0;

  return (
    <Card icon={<HeartIcon />} title="Integrations state">
      {!hasData && (
        <div className="mt-10 h-56 text-text">
          There are currently no integrations to display.
        </div>
      )}
      <div className={`${!hasData && 'hidden'}`}>
        <div className="mt-5 flex flex-grow flex-col items-center justify-between">
          <div className="my-auto w-36">
            {loading && <span className="animate-ping">Loading</span>}
            <canvas ref={canvasRef} />
          </div>

          <div className="mt-7" />
          <ChartLegends
            legends={[
              {
                label: `${failed || 0} Failed`,
                bg: 'bg-error',
              },
              {
                label: `${disabled || 0} Disabled`,
                bg: 'bg-warning',
              },
              {
                label: `${active || 0} Active`,
                bg: 'bg-success',
              },
            ]}
          />
        </div>
      </div>
    </Card>
  );
});
