import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { HeartIcon } from '../../../icons';
import { Card, ChartLegends } from '../../general/Dashboard';
import { fetchRelationsStateData } from '../../../api/fetchRelationsStateData';
import { RelationStateData } from '../../../types/finance/RelationStateData';

const getChartData = (
  rejected,
  pending,
  paused,
  active,
  isDarkMode: boolean,
) => ({
  labels: ['Rejected', 'Pending', 'Paused', 'Active'],
  datasets: [
    {
      data: [rejected, pending, paused, active],
      backgroundColor: isDarkMode
        ? [
            'rgb(255, 143, 143)',
            'rgb(255, 253, 171)',
            'rgb(171, 243, 255)',
            'rgb(189, 255, 212)',
          ]
        : [
            'rgb(234, 127, 127)',
            'rgb(221, 180, 34)',
            'rgb(0, 163, 215)',
            'rgb(100, 186, 155)',
          ],
    },
  ],
});

export const RelationsStateCard: FC = observer(() => {
  const {
    settingsStore: { darkMode },
    authStore,
    relationStore,
  } = useStore();

  const [relationsStateData, setRelationsStateData] =
    useState<RelationStateData | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [data] = await Promise.all([fetchRelationsStateData(authStore)]);
      setRelationsStateData(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const rejected = relationsStateData?.rejected;
  const pending = relationsStateData?.pending;
  const paused = relationsStateData?.paused;
  const active = relationsStateData?.active;

  useLayoutEffect(() => {
    const ctx = canvasRef.current!.getContext('2d')!;

    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: getChartData(rejected, pending, paused, active, darkMode || false),
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
      },
    });

    return () => chartRef.current!.destroy();
  }, []);

  useLayoutEffect(() => {
    const chart = chartRef.current!;

    chart.data = getChartData(
      rejected,
      pending,
      paused,
      active,
      darkMode || false,
    );
    chart.update();
  }, [rejected, pending, paused, active, darkMode]);

  const hasData =
    relationStore.pagedRelations && relationStore.pagedRelations.length > 0;

  return (
    <Card icon={<HeartIcon />} title="Relations state">
      {!hasData && (
        <div className="mt-10 h-56 text-text">
          There is currently no relations data to display.
        </div>
      )}
      <div className={`${!hasData && 'hidden'}`}>
        <div className="mt-5 flex  flex-grow flex-col items-center justify-between">
          <div className="my-auto w-36">
            {loading && <span className="animate-ping">Loading</span>}
            <canvas ref={canvasRef} />
          </div>

          <div className="mt-7" />
          <ChartLegends
            legends={[
              { label: `${rejected || 0} Rejected`, bg: 'bg-error' },
              { label: `${pending || 0} Pending`, bg: 'bg-warning' },
              { label: `${paused || 0} Paused`, bg: 'bg-information' },
              { label: `${active || 0} Active`, bg: 'bg-success' },
            ]}
          />
        </div>
      </div>
    </Card>
  );
});
