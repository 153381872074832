import { IObservableArray, observable } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FadeInDiv,
  Label,
  PopupModal,
  SftpSourceConfiguration,
  SftpTargetConfiguration,
} from '../../../components/general';
import {
  AddButton,
  Button,
  ExternalSystemButton,
} from '../../../components/general/buttons';
import { IdNameObj, Select } from '../../../components/general/Select';
import { SelectSystem } from '../../../components/integrationHub';
import { useStore } from '../../../hooks/useStore';
import { ArrowRightIcon, IntegrationIcon } from '../../../icons';
import { CreateIntegrationCommandInstance } from '../../../models';
import {
  ExternalSystemDirection,
  ExternalSystemType,
  IntegrationEntityType,
} from '../../../types';

interface CreateIntegrationState {
  isSubmitting: boolean;
  createdSystemId: string;
  createSystemDirection?: ExternalSystemDirection;
  selectSourceSystem: boolean;
  selectTargetSystem: boolean;
  name: string;
  notifyEmailAddress: string;
  sourceSystemId?: string;
  targetSystemId?: string;
  sourceSystemInputs: Record<string, string> | undefined;
  targetSystemInputs: Record<string, string> | undefined;
  sourceSystemInputsValid: boolean;
  targetSystemInputsValid: boolean;
  entityType?: IntegrationEntityType;
  disabled?: boolean;
  entityOptions: IObservableArray<IdNameObj>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  handleClose: () => void;
  setName: (name: string) => void;
  setNotifyEmailAddress: (emailAddress: string) => void;
  setTargetSystemId: (targetSystem?: string) => void;
  setSourceSystemId: (sourceSystemId?: string) => void;
  setSourceInputs: (inputs: Record<string, string>) => void;
  setTargetInputs: (inputs: Record<string, string>) => void;
  setSourceSystemInputsValid: (isValid: boolean) => void;
  setTargetSystemInputsValid: (isValid: boolean) => void;
  setSelectingSourceSystem: (selecting: boolean) => void;
  setSelectingTargetSystem: (selecting: boolean) => void;
  setEntityType: (externalSystemType?: IntegrationEntityType) => void;
  setEntityOptions: (options: IdNameObj[]) => void;
}

export const CreateIntegrationPage = observer(() => {
  const { uiStore, externalSystemStore, integrationStore } = useStore();
  const { externalSystems } = externalSystemStore;
  const navigate = useNavigate();
  const [formValid, setFormValid] = useState<boolean>(false);

  const location = useLocation();
  const redirectedState = location.state;

  const state = useLocalObservable<CreateIntegrationState>(() => ({
    isSubmitting: false,
    createdSystemId: redirectedState?.createdSystemId || '',
    createSystemDirection: redirectedState?.createSystemDirection || undefined,
    entityType:
      (redirectedState?.entityType as IntegrationEntityType) || undefined,
    selectSourceSystem: false,
    selectTargetSystem: false,
    name: redirectedState?.name || '',
    notifyEmailAddress: redirectedState?.notifyEmailAddress || '',
    sourceSystemId:
      redirectedState?.createSystemDirection === ExternalSystemDirection.Reader
        ? redirectedState?.createdSystemId
        : redirectedState?.sourceSystemId,
    targetSystemId:
      redirectedState?.createSystemDirection === ExternalSystemDirection.Writer
        ? redirectedState?.createdSystemId
        : redirectedState?.targetSystemId,
    sourceSystemInputs: undefined,
    targetSystemInputs: undefined,
    sourceSystemInputsValid: false,
    targetSystemInputsValid: false,
    disabled: true,
    entityOptions: observable<IdNameObj>([]),
    setIsSubmitting(isSubmitting: boolean) {
      state.isSubmitting = isSubmitting;
    },
    setName(name: string) {
      state.name = name;
    },
    setNotifyEmailAddress(emailAddress: string) {
      state.notifyEmailAddress = emailAddress;
    },
    setSourceSystemId(sourceSystemId?: string) {
      state.sourceSystemId = sourceSystemId;
    },
    setTargetSystemId(targetSystem?: string) {
      state.targetSystemId = targetSystem;
    },
    setSelectingSourceSystem(selecting: boolean) {
      if (!canAddSystem()) return;
      state.selectSourceSystem = selecting;
    },
    setSelectingTargetSystem(selecting: boolean) {
      if (typeRef && !state.entityType) {
        typeRef.current?.focus();
        return;
      }
      state.selectTargetSystem = selecting;
    },
    setSourceInputs(inputs) {
      state.sourceSystemInputs = inputs;
    },
    setTargetInputs(inputs) {
      state.targetSystemInputs = inputs;
    },
    setSourceSystemInputsValid(isValid) {
      state.sourceSystemInputsValid = isValid;
    },
    setTargetSystemInputsValid(isValid) {
      state.targetSystemInputsValid = isValid;
    },
    setEntityType(entityType?: IntegrationEntityType) {
      state.sourceSystemId = undefined;
      state.targetSystemId = undefined;
      state.entityType = entityType;
    },
    handleClose() {
      uiStore.toggleIsAddIntegrationOpen();
    },
    setEntityOptions(options: IdNameObj[]) {
      state.entityOptions.replace(options);
    },
  }));

  const validateForm = (focus: boolean) => {
    setFormValid(false);
    if (!state.name) {
      if (focus) nameRef?.current?.focus();
      return false;
    }
    if (!state.entityType) {
      if (focus) typeRef?.current?.focus();
      return false;
    }
    if (!state.sourceSystemId) {
      if (focus) sourceRef?.current?.focus();
      return false;
    }
    if (!state.targetSystemId) {
      if (focus) targetRef?.current?.focus();
      return false;
    }
    const target = externalSystems?.find((x) => x.id === state.targetSystemId);
    if (
      target?.externalSystemType === ExternalSystemType.SFTP &&
      !state.targetSystemInputsValid
    ) {
      return false;
    }
    const source = externalSystems?.find((x) => x.id === state.sourceSystemId);
    if (
      source?.externalSystemType === ExternalSystemType.SFTP &&
      !state.sourceSystemInputsValid
    ) {
      return false;
    }  

    setFormValid(true);
    return true;
  };

  const selectSourceSystem = (id?: string) => {
    state.setSourceSystemId(id);
    state.setSelectingSourceSystem(false);
    validateForm(true);
  };
  const selectTargetSystem = (id?: string) => {
    state.setTargetSystemId(id);
    state.setSelectingTargetSystem(false);
    validateForm(true);
  };

  const canAddSystem = () => {
    if (state.entityType) return true;
    else {
      typeRef?.current?.focus();
      return false;
    }
  };

  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const typeRef = useRef<HTMLSelectElement>(null);
  const sourceRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (state.isSubmitting) return;
    if (!validateForm(true)) return;

    state.setIsSubmitting(true);

    const source = externalSystems?.find((x) => x.id === state.sourceSystemId);
    const target = externalSystems?.find((x) => x.id === state.targetSystemId);

    const createIntegrationCommand = {
      name: state.name,
      notifyEmailAddress: state.notifyEmailAddress,
      sourceSystemType: source?.externalSystemType,
      targetSystemType: target?.externalSystemType,
      sourceSystemId: source?.id,
      targetSystemId: target?.id,
      entityType: state.entityType,
      disabled: state.disabled,
      sourceSystemInputs: state.sourceSystemInputs,
      targetSystemInputs: state.targetSystemInputs,
    } as CreateIntegrationCommandInstance;

    try {
      await integrationStore.createIntegration(createIntegrationCommand);
    } finally {
      state.setIsSubmitting(false);
    }

    navigate('/integrations');
  };


  useEffect(() => {
    validateForm(true);
  }, [state.sourceSystemInputsValid, state.targetSystemInputsValid]);

  useEffect(() => {
    if (location.state) {
      validateForm(true);
    }
  }, []);

  useEffect(() => {
    externalSystemStore.getAddedSystems();
    externalSystemStore.fetchSystems();
    const opts = [] as IdNameObj[];
    Object.keys(IntegrationEntityType).forEach((item, i) => {
      opts.push({
        id: String(i),
        name: item,
        selected: item === state.entityType,
      });
    });
    state.setEntityOptions(opts);
  }, []);

  const selectedSource = externalSystems?.find(
    (extSys) => extSys.id === state.sourceSystemId,
  );

  const selectedTarget = externalSystems?.find(
    (extSys) => extSys.id === state.targetSystemId,
  );

  const systems = externalSystemStore.addedSystems?.find(
    (addedSys) =>
      addedSys.entityType.toLowerCase() === state.entityType?.toLowerCase(),
  );
  const readerSystems = externalSystems?.filter((extSys) =>
    systems?.readerSystems?.includes(extSys.id),
  );
  const writerSystems = externalSystems?.filter((extSys) =>
    systems?.writerSystems?.includes(extSys.id),
  );

  return (
    <FadeInDiv className="mt-32 flex items-center justify-center">
      {!state.selectSourceSystem && !state.selectTargetSystem && (
        <PopupModal
          className="w-[442px]"
          titleIcon={<IntegrationIcon className="h-6" />}
          title="Which systems do you want to connect?"
          open={true}
          canClose={true}
          onClose={() => navigate('/integrations')}
        >
          <div className="flex flex-wrap rounded-lg">
            <form onSubmit={onSubmit} onBlur={() => validateForm(false)}>
              <div className="mb-5">
                <Label
                  className="mb-2 inline-block whitespace-nowrap font-semibold text-labels"
                  required
                >
                  Name
                </Label>
                <input
                  type="text"
                  ref={nameRef}
                  name="name"
                  value={state.name}
                  placeholder="Name your integration"
                  onChange={(e) => state.setName(e.target.value)}
                  className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                />
              </div>
              <div className="mb-5">
                <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
                  Notifications email
                </Label>
                <input
                  type="text"
                  name="email"
                  ref={emailRef}
                  value={state.notifyEmailAddress}
                  placeholder="user@domain.com"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  onChange={(e) => state.setNotifyEmailAddress(e.target.value)}
                  className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
                />
              </div>

              <div className="mb-5">
                <Select
                  value={state.entityType}
                  ref={typeRef}
                  name="Type"
                  required
                  label={'Type'}
                  placeholder={''}
                  noMatchText={'No types available'}
                  items={state.entityOptions}
                  onChange={(e) => {
                    state.setEntityType(undefined);
                    setTimeout(
                      () =>
                        state.setEntityType(e.name as IntegrationEntityType),
                      50,
                    );
                  }}
                />
              </div>

              <div className="mt-10 flex justify-between">
                <div className="mb-2 flex w-28 flex-col items-start whitespace-nowrap font-bold text-labels">
                  <h3 className="text-left text-xl font-bold">Source</h3>

                  <div className="mt-5 ">
                    {selectedSource ? (
                      <ExternalSystemButton
                        logoUrl={selectedSource.systemLogo || undefined}
                        onClick={() => state.setSelectingSourceSystem(true)}
                        externalSystemType={
                          selectedSource.externalSystemType as ExternalSystemType
                        }
                        externalSystemName={selectedSource.name}
                        description={selectedSource.name}
                      ></ExternalSystemButton>
                    ) : (
                      <AddButton
                        ref={sourceRef}
                        className={`${!state.entityType && 'opacity-30'}`}
                        onClick={() => state.setSelectingSourceSystem(true)}
                        description="Add source"
                      />
                    )}
                  </div>
                </div>
                <ArrowRightIcon className="mt-4 w-9 text-titles" />
                <div className="mb-2 flex w-28 flex-col items-end whitespace-nowrap font-semibold text-labels">
                  <h3 className="text-right text-xl font-bold">Destination</h3>

                  <div className="mt-5">
                    {selectedTarget ? (
                      <ExternalSystemButton
                        logoUrl={selectedTarget.systemLogo || undefined}
                        onClick={() => state.setSelectingTargetSystem(true)}
                        externalSystemType={
                          selectedTarget.externalSystemType as ExternalSystemType
                        }
                        externalSystemName={selectedTarget.name}
                        description={selectedTarget.name}
                      ></ExternalSystemButton>
                    ) : (
                      <AddButton
                        ref={targetRef}
                        className={`${!state.entityType && 'opacity-30'}`}
                        onClick={() => state.setSelectingTargetSystem(true)}
                        description="Add destination"
                      />
                    )}
                  </div>
                </div>
              </div>
              {selectedSource?.externalSystemType ===
                ExternalSystemType.SFTP && (
                  <div className="-mx-8 mt-12 rounded-md bg-selectors p-8">
                    <SftpSourceConfiguration
                      systemId={selectedSource.id}
                      processedPath={state.sourceSystemInputs?.MovePath || ''}
                      downloadPath={state.sourceSystemInputs?.ListPath || ''}
                      onProcessedPathChange={(folder) =>
                        state.setSourceInputs({
                          MovePath: folder,
                          ListPath: state.sourceSystemInputs?.ListPath || '',
                        })
                      }
                      onDownloadPathChange={(folder) =>
                        state.setSourceInputs({
                          MovePath: state.sourceSystemInputs?.MovePath || '',
                          ListPath: folder,
                        })
                      }
                      onValidation={(isValid) =>
                        state.setSourceSystemInputsValid(isValid)
                      }
                    />
                  </div>
                )}
              {selectedTarget?.externalSystemType ===
                ExternalSystemType.SFTP && (
                  <div className="-mx-8 mt-4 rounded-md bg-selectors p-8">
                    <SftpTargetConfiguration
                      uploadPath={state.targetSystemInputs?.Path || ''}
                      systemId={selectedTarget.id}
                      onUploadPathChange={(folder) =>
                        state.setTargetInputs({
                          Path: folder,
                        })
                      }
                      onValidation={(isValid) =>
                        state.setTargetSystemInputsValid(isValid)
                      }
                    />
                  </div>
                )}
              <Button
                isLoading={state.isSubmitting}
                className={`${!formValid && 'opacity-30'
                  } mt-10 w-full text-xl font-bold`}
              >
                Create
              </Button>
            </form>
          </div>
        </PopupModal>
      )}
      {(state.selectSourceSystem || state.selectTargetSystem) && (
        <SelectSystem
          parentState={{
            ...state,
            createSystemDirection: state.selectSourceSystem
              ? ExternalSystemDirection.Reader
              : ExternalSystemDirection.Writer,
          }}
          onAddedSystem={(id) => {
            if (state.selectSourceSystem) {
              state.setSourceSystemId(id);
            } else if (state.selectTargetSystem) {
              state.setTargetSystemId(id);
            }
            state.setSelectingSourceSystem(false);
            state.setSelectingTargetSystem(false);
          }}
          externalSystems={
            state.selectSourceSystem ? readerSystems : writerSystems
          }
          disabled={false}
          entityType={state.entityType}
          selectSystem={
            state.selectSourceSystem ? selectSourceSystem : selectTargetSystem
          }
          externalSystemDirection={
            state.selectSourceSystem
              ? ExternalSystemDirection.Reader
              : ExternalSystemDirection.Writer
          }
        ></SelectSystem>
      )}
    </FadeInDiv>
  );
});
