import { flow, Instance, types as t } from 'mobx-state-tree';
import { INTEGRATIONS_API_URL } from '../constants';
import { withRequest } from '../extensions';

export const ExternalSystemInvitation = t
  .model('ExternalSystemInvitation', {
    id: t.identifier,
    externalSystemName: t.string,
    accepted: t.maybeNull(t.boolean),
    externalTenantLogoUrl: t.maybeNull(t.string),
  })
  .extend(withRequest)
  .actions((self) => {
    let isLoading = false;

    return {
      accept: flow(function* () {
        if (isLoading) {
          return;
        }
        isLoading = true;

        try {
          const { data } = yield self.request({
            method: 'POST',
            url: `${INTEGRATIONS_API_URL}api/invitations/${self.id}/accept`,
          });
          self = data;
        } finally {
          isLoading = false;
        }
      }),
      decline: flow(function* () {
        if (isLoading) {
          return;
        }
        isLoading = true;

        try {
          const { data } = yield self.request({
            method: 'POST',
            url: `${INTEGRATIONS_API_URL}api/invitations/${self.id}/decline`,
          });
          self = data;
        } finally {
          isLoading = false;
        }
      }),
    };
  });

export type ExternalSystemInvitationInstance = Instance<
  typeof ExternalSystemInvitation
>;
