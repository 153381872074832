import { Instance, types as t } from 'mobx-state-tree';
import { IntegrationEntityType } from '../types';

export const SystemDetails = t.model({
  systemType: t.maybeNull(t.string),
  supportedEntityTypes: t.maybeNull(
    t.array(t.enumeration(Object.keys(IntegrationEntityType))),
  ),
});

export const AvailableSystemsMap = t.model({
  readerSystems: t.array(SystemDetails),
  writerSystems: t.array(SystemDetails),
});

export type AvailableSystemsMapInstance = Instance<typeof AvailableSystemsMap>;
export type SystemDetailsInstance = Instance<typeof SystemDetails>;
