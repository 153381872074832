import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { InputWithError } from '../../general/InputWithError';
import { Button } from '../../general/buttons';
import { useStore } from '../../../hooks/useStore';
import { FadeInDiv, Label } from '../../general';
import { TwentyFourSevenOfficeIcon } from '../../../icons';

type TwentyFourSevenOfficeSetupProps = {
  systemId?: string;
  onFormSubmit?: (id: string) => void;
};

type FormData = {
  applicationId: string;
  username: string;
  password: string;
  identityId: string;
};

export const TwentyFourSevenOfficeSetup: FC<TwentyFourSevenOfficeSetupProps> =
  observer(({ systemId, onFormSubmit }) => {
    const { externalSystemStore } = useStore();
    const state = useLocalObservable(() => ({
      isSubmiting: false,
      setIsSubmitting(isSubmiting: boolean) {
        state.isSubmiting = isSubmiting;
      },

      hasFormError: false,
      setHasFormError(hasFormError: boolean) {
        state.hasFormError = hasFormError;
      },
    }));

    const isEditMode: boolean = systemId !== undefined;

    useEffect(() => {
      if (isEditMode) {
        state.setIsSubmitting(true);
        try {
          const systemSettings =
            externalSystemStore.getTwentyFourSevenOfficeSystemSetting(
              systemId!,
            );
          systemSettings.then((data: any) => {
            if (data) setFormValues(data);
          });
        } catch (error) {
          state.setHasFormError(true);
        } finally {
          state.setIsSubmitting(false);
        }
      }
    }, []);

    const setFormValues = (data: any) => {
      setValue('applicationId', data.applicationId);
      setValue('username', data.username);
      setValue('identityId', data.identityId);
    };

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<FormData>();

    const onSubmit = handleSubmit(
      async ({ applicationId, username, password, identityId }) => {
        if (state.isSubmiting) return;

        state.setIsSubmitting(true);
        state.setHasFormError(false);
        isEditMode
          ? updateCurrentSystem(
            systemId,
            applicationId,
            username,
            password,
            identityId,
          )
          : addNewSystem(applicationId, username, password, identityId);
      },
    );

    const addNewSystem = async (
      applicationId,
      username,
      password,
      identityId,
    ) => {
      try {
        const id = await externalSystemStore.addTwentyFourSevenOfficeSystem({
          applicationId,
          username,
          password,
          identityId,
        });
        onFormSubmit && onFormSubmit(id);
      } catch (error) {
        state.setHasFormError(true);
      } finally {
        state.setIsSubmitting(false);
      }
    };

    const updateCurrentSystem = async (
      id,
      applicationId,
      username,
      password,
      identityId,
    ) => {
      try {
        await externalSystemStore.updateTwentyFourSevenOfficeSystemSetting(id, {
          applicationId,
          username,
          password,
          identityId,
        });
        onFormSubmit && onFormSubmit(id);
      } catch (error) {
        state.setHasFormError(true);
      } finally {
        state.setIsSubmitting(false);
      }
    };

    return (
      <FadeInDiv>
        <div className='mb-4 flex flex-col items-center justify-center'>
          <TwentyFourSevenOfficeIcon className='h-12' />
          24SevenOffice
        </div>

        <form onSubmit={onSubmit}>
          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Application Id
            </Label>
            <InputWithError
              type="text"
              placeholder="Application Id"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('applicationId', {
                required: 'Fill in this field',
              })}
              error={errors.applicationId?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Username
            </Label>
            <InputWithError
              type="text"
              placeholder="Username"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('username', {
                required: 'Fill in this field',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Ange en giltig e-post',
                },
              })}
              error={errors.username?.message}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Lösenord
            </Label>
            <input
              type="password"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              placeholder="**********"
              {...register('password', { required: true })}
            />
          </div>

          <div className="mb-5">
            <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
              Identity Id
            </Label>
            <InputWithError
              type="text"
              placeholder="Identity Id"
              className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
              {...register('identityId', {
                required: 'Fill in this field',
              })}
              error={errors.identityId?.message}
            />
          </div>

          <div>{state.isSubmiting && <>Submitting..</>}</div>
          <div className="mb-5">
            <Button
              onClick={onSubmit}
              className="mt-10 w-full bg-titles text-xl font-bold"
              isLoading={state.isSubmiting}
            >
              <span className="font-semibold">
                {isEditMode ? 'Update' : 'Add'}
              </span>
            </Button>
          </div>
        </form>
      </FadeInDiv>
    );
  });
