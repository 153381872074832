export enum InvoicesFilter {
  Imported = 'imported',
  EntityType = 'entityType',
  EntityStatus = 'EntityStatus',
  InvoiceNumber = 'invoiceNumber',
  CustomerNumber = 'customerNumber',
  Amount = 'amount',
  TargetSystemId = 'targetSystemId',
  SourceSystemId = 'sourceSystemId',
}
