import axios from 'axios';
import { t } from 'i18next';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { PopupModal } from '../../components/general';
import {
  AboutUserForm,
  AboutUserFormData,
} from '../../components/general/AboutForm';
import {
  LoginCredentials,
  LoginCredentialsForm,
} from '../../components/general/LoginCredentialsForm';
import { IDENTITYSERVER_URL } from '../../constants';
import { LoginIcon, UserPlusIcon } from '../../icons';

enum SignUpStep {
  LoginCredentials = 'loginCredentials',
  About = 'about',
}

const SIGN_UP_STEP_TITLES = {
  [SignUpStep.LoginCredentials]: 'Sign Up',
  [SignUpStep.About]: 'More about you',
};

interface SignUpPageState {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  signUpStep: SignUpStep;
  setSignUpStep: (step: SignUpStep) => void;
  loginCredentials: LoginCredentials | null;
  setLoginCredentials: (data: LoginCredentials) => void;
  handleSubmit: (data: AboutUserFormData) => void;
}

export const SignUpWithInvitationPage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  // Using UrlSearchParams replaces (+) with whitespace and if we try
  // to decode the url the token will not be the same, so we have
  // to manually extract the information from location search.
  const searchParams = new Map<string, string>();
  location.search
    .substring(1)
    .split('&')
    .forEach((param) => {
      const values = param.split('=');
      searchParams.set(values[0], values[1]);
    });

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const state = useLocalObservable<SignUpPageState>(() => ({
    isLoading: false,
    setIsLoading(isLoading) {
      state.isLoading = isLoading;
    },
    signUpStep: SignUpStep.LoginCredentials,
    setSignUpStep(step) {
      state.signUpStep = step;
    },
    loginCredentials: null,
    setLoginCredentials(data) {
      state.loginCredentials = data;
      state.setSignUpStep(SignUpStep.About);
    },
    async handleSubmit(aboutFormData) {
      const data = {
        loginCredentials: { ...state.loginCredentials },
        userInfo: { ...aboutFormData },
        resetCode: token,
      };

      try {
        state.setIsLoading(true);
        await axios({
          method: 'POST',
          url: `${IDENTITYSERVER_URL}activate-invite`,
          data,
        });
        navigate('/');
      } finally {
        state.setIsLoading(false);
      }
    },
  }));

  if (!email || !token) return null;

  return (
    <div className="flex flex-col items-center gap-4">
      <PopupModal
        className="max-w-md sm:w-md"
        title={SIGN_UP_STEP_TITLES[state.signUpStep]}
        titleIcon={<UserPlusIcon className="w-6" />}
      >
        {state.signUpStep === SignUpStep.LoginCredentials && (
          <LoginCredentialsForm
            onSubmit={state.setLoginCredentials}
            isLoading={state.isLoading}
            defaultEmail={{ value: email, readonly: true }}
          />
        )}
        {state.signUpStep === SignUpStep.About && (
          <AboutUserForm
            onSubmit={state.handleSubmit}
            isLoading={state.isLoading}
            submitText={t('Sign up')}
          />
        )}
      </PopupModal>
      <Link to="/" className="flex items-center gap-1 font-light text-text">
        <LoginIcon className="w-8" />
        Already have an account? Sign in.
      </Link>
    </div>
  );
});
