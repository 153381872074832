import React, { FC } from 'react';
import { ArrowUpIcon } from '../../../icons';
import { TrendData } from '../../../types';

export const TrendNumbers: FC<{
  title: string;
  currentTimeLabel: string | undefined;
  prevTimeLabel: string | undefined;
  trendData: TrendData | undefined;
}> = ({ title, currentTimeLabel, prevTimeLabel, trendData }) => (
  <div>
    <div className="text-sm font-light text-text">{title}</div>
    <div className="flex items-end">
      <div>
        <div className="text-4xl font-light text-labels">
          <span>{trendData?.sum}</span>
        </div>
        <div className="text-[10px] font-light text-text">
          {currentTimeLabel ?? ''}
        </div>
      </div>

      <div className="ml-4">
        {trendData?.trendPercentage != 0 && (
          <>
            <div
              className={`text-xl font-light ${
                trendData?.trendPercentage ?? 0 > 0
                  ? 'text-success'
                  : trendData?.trendPercentage ?? 0 < 0
                  ? 'text-error'
                  : 'text-labels'
              }`}
            >
              <span>{trendData?.trendPercentage.toFixed(0)}%</span>
              <ArrowUpIcon
                className={`ml-1 inline align-baseline ${
                  trendData?.isPositiveTrend ? '' : 'rotate-180'
                }`}
                width="14"
                height="14"
              />
            </div>
            <div className="text-[10px] font-light text-text">
              {prevTimeLabel ?? ''}
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);
