import { INTEGRATIONS_API_URL } from '../constants';
import { WentWrongErrors } from '../types';

const typeToTextMapper = {
  errorDocuments: 'Documents',
  errorIntegrations: 'Integrations',
  errorSystems: 'Sources & destinations',
};

export const fetchWentWrongData = async (
  authStore,
): Promise<WentWrongErrors | null> => {
  const promise = authStore.authRequest({
    url: INTEGRATIONS_API_URL + 'api/statistics/entitystatus',
  });
  const { data } = await promise;
  const hasData =
    data.documents > 0 || data.systems > 0 || data.integrations > 0;

  const typesWithErrorCount = Object.keys(data)
    .filter((errorType) => typeToTextMapper[errorType])
    .map((errorType) => {
      return { type: typeToTextMapper[errorType], count: data[errorType] };
    });

  const wentWrongErrors = { errors: typesWithErrorCount, hasData: hasData };

  return wentWrongErrors;
};
