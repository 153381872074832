import { Instance, types as t } from 'mobx-state-tree';

export const IntegrationTriggerAction = t.model({
  name: t.string,
  activityType: t.string,
  activitySettings: t.frozen(),
  inputs: t.map(t.string),
  outputs: t.map(t.string)
});

export type IntegrationTriggerActionInstance = Instance<typeof IntegrationTriggerAction>;