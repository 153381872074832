import React, { FC, memo, SVGProps } from 'react';

export const UserPlusIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3V8zM4 8a3.91 3.91 0 004 4 3.91 3.91 0 004-4 3.91 3.91 0 00-4-4 3.91 3.91 0 00-4 4zm6 0a1.91 1.91 0 01-2 2 1.911 1.911 0 01-2-2 1.91 1.91 0 012-2 1.91 1.91 0 012 2zM4 18a3 3 0 013-3h2a3 3 0 013 3v1h2v-1a5 5 0 00-5-5H7a5 5 0 00-5 5v1h2v-1z"
    ></path>
  </svg>
));
