import Chart from 'chart.js/auto';
import { addHours, addMonths, endOfDay, startOfDay } from 'date-fns';
import {
  addDays,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
} from 'date-fns/esm';
import { observer } from 'mobx-react-lite';
import React, { FC, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../hooks';
import { ArrowUpIcon, NoteIcon } from '../../../icons';
import {
  DocumentFlowData,
  DocumentFlowEntry,
  DocumentFlowEntryStatus,
  TimeInterval,
} from '../../../types';
import { formatInterval } from '../../../utils/Dashboard/formatInterval';
import { getIntervalLabel } from '../../../utils/Dashboard/getIntervalLabel';
import { getTrendTextsToDate } from '../../../utils/getTrendTextsToDate';
import { roundDateTime } from '../../../utils/roundDateTime';
import { Button } from '../buttons';
import { Card } from './Card';
import { ChartLegends } from './ChartLegends';
import { IntervalPillTabs } from './IntervalPillTabs';

const getEmptyPeriodState = () => ({ failed: 0, inprogress: 0, delivered: 0 });

const transformDocumentsToData = (
  documents: DocumentFlowEntry[],
  interval: TimeInterval,
  isDarkMode: boolean,
) => {
  const periods: {
    [key: string]: { failed: number; inprogress: number; delivered: number };
  } = {};
  const sortedDocuments = documents
    .slice()
    .sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
  const now = new Date();

  switch (interval) {
    case TimeInterval.Day: {
      const startDay = startOfDay(now);
      const endDay = endOfDay(now);

      for (let time = startDay; time < endDay; time = addHours(time, 1)) {
        periods[time.toString()] = getEmptyPeriodState();
      }
      break;
    }
    case TimeInterval.Month: {
      const startMonth = startOfMonth(now);
      const endMonth = endOfMonth(now);

      for (let time = startMonth; time < endMonth; time = addDays(time, 1)) {
        periods[time.toString()] = getEmptyPeriodState();
      }
      break;
    }
    case TimeInterval.Year: {
      const startYear = startOfYear(now);
      const endYear = endOfYear(now);

      for (let time = startYear; time < endYear; time = addMonths(time, 1)) {
        periods[time.toString()] = getEmptyPeriodState();
      }
      break;
    }
  }

  sortedDocuments.forEach((document) => {
    const roundedCreatedAt = roundDateTime(document.createdAt, interval);

    if (!(roundedCreatedAt in periods)) {
      return;
    }

    const translatedStatus =
      document.pipelineStatus.toLowerCase() === 'completed'
        ? DocumentFlowEntryStatus.Delivered
        : document.pipelineStatus.toLowerCase();

    ++periods[roundedCreatedAt][translatedStatus];
  });

  const values = Object.values(periods);

  return {
    labels: Object.keys(periods).map((key) => getIntervalLabel(key, interval)),
    datasets: [
      {
        label: 'Failed',
        data: values.map((v) => v.failed),
        backgroundColor: isDarkMode
          ? 'rgb(255, 143, 143)'
          : 'rgb(234, 127, 127)',
        barPercentage: 0.95,
        categoryPercentage: 1,
      },
      {
        label: 'In progress',
        data: values.map((v) => v.inprogress),
        backgroundColor: isDarkMode
          ? 'rgb(255, 253, 171)'
          : 'rgb(243, 210, 91)',
        barPercentage: 0.95,
        categoryPercentage: 1,
      },
      {
        label: 'Delivered',
        data: values.map((v) => v.delivered),
        backgroundColor: isDarkMode
          ? 'rgb(189, 255, 212)'
          : 'rgb(100, 186, 155)',
        barPercentage: 0.95,
        categoryPercentage: 1,
      },
    ],
  };
};

export const DocumentFlowCard: FC<{
  interval: TimeInterval;
  onIntervalChange: (interval: TimeInterval) => void;
  data: DocumentFlowData;
}> = observer(({ interval, onIntervalChange, data }) => {
  const {
    settingsStore: { darkMode },
  } = useStore();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  useLayoutEffect(() => {
    const ctx = canvasRef.current!.getContext('2d')!;

    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: transformDocumentsToData(
        data.documents,
        interval,
        darkMode || false,
      ),
      options: {
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: { display: false },
            stacked: true,
            display: true,
            ticks: {
              callback: (tickvalue, index, ticks) =>
                formatInterval(t, interval, tickvalue),
              maxRotation: 0,
              minRotation: 0,
              color: darkMode ? 'rgb(184, 220, 255)' : 'rgb(37, 37, 58)',
            },
          },
          y: {
            grid: {
              display: false,
            },
            stacked: true,
            display: true,
            ticks: {
              color: darkMode ? 'rgb(184, 220, 255)' : 'rgb(37, 37, 58)',
            },
          },
        },
      },
    });

    return () => chartRef.current!.destroy();
  }, []);

  useLayoutEffect(() => {
    const chart = chartRef.current!;

    chart.data = transformDocumentsToData(
      data.documents,
      interval,
      darkMode || false,
    );

    const scaleXTicks = chart.options.scales && chart.options.scales['x'];
    if (scaleXTicks?.ticks) {
      scaleXTicks.ticks.callback = (tickValue) =>
        formatInterval(t, interval, tickValue);
    }

    chart.update();
  }, [data, interval, darkMode]);

  const isPositiveTrend = useMemo(
    () => data.currentPeriod > data.prevPeriod,
    [data],
  );
  const [currentLabel, prevLabel] = useMemo(
    () => getTrendTextsToDate(interval),
    [interval],
  );

  const calculateChangePercentage = (fromValue: number, toValue: number) => {
    const result = ((toValue - fromValue) / fromValue) * 100.0;
    if (isNaN(result) || fromValue === 0) return undefined;
    else return result;
  };
  const percentageChange = calculateChangePercentage(
    data.prevPeriod,
    data.currentPeriod,
  );

  const hasData = data.currentPeriod !== 0 || data.prevPeriod !== 0;

  return (
    <>
      <div hidden={!hasData}>
        <Card
          icon={<NoteIcon />}
          title="How's my document flow?"
          actions={
            <IntervalPillTabs value={interval} onChange={onIntervalChange} />
          }
        >
          <div className="mt-6 flex flex-grow flex-col justify-between">
            <div className="mt-6">
              <div className="flex items-end">
                <div>
                  <div
                    className={`text-7xl font-light ${
                      isPositiveTrend ? 'text-success' : 'text-labels'
                    }`}
                  >
                    <span>{data.currentPeriod}</span>
                  </div>
                  <span className="text-sm font-light text-text">
                    {currentLabel}
                  </span>
                </div>

                <div className="ml-4">
                  {percentageChange !== undefined && (
                    <>
                      <div
                        className={`text-4xl font-light ${
                          percentageChange !== undefined && percentageChange > 0
                            ? 'text-success'
                            : percentageChange !== undefined &&
                              percentageChange < 0
                            ? 'text-error'
                            : 'text-labels'
                        }`}
                      >
                        <span>{percentageChange.toFixed(0)}%</span>
                        {percentageChange !== 0 && (
                          <ArrowUpIcon
                            className={`ml-1 inline align-baseline ${
                              percentageChange !== undefined &&
                              percentageChange > 0
                                ? ''
                                : percentageChange !== undefined &&
                                  percentageChange < 0
                                ? 'rotate-180'
                                : 'rotate-90'
                            }`}
                          />
                        )}
                      </div>
                      <span className="text-sm font-light text-text">
                        {prevLabel}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="my-auto mt-10 flex justify-center ">
              <div className="w-full">
                <canvas ref={canvasRef} />
              </div>
            </div>

            <div className="mt-7" />

            <ChartLegends
              legends={[
                { label: 'Failed', bg: 'bg-error' },
                { label: 'In progress', bg: 'bg-warning' },
                { label: 'Delivered', bg: 'bg-success' },
              ]}
            />
          </div>
        </Card>
      </div>
      <div hidden={hasData}>
        <Card icon={<NoteIcon />} title="How's my document flow?">
          <div className="mt-10 flex h-56 flex-grow flex-col justify-between text-text">
            Thre is currently no document flow data to display.
          </div>
          <div>
            <Button
              onClick={() => navigate('/integrations/create')}
              className="mt-10 w-full"
            >
              {"Let's get started!"}
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
});
