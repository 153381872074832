import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from './buttons';
import { InputWithError } from './InputWithError';

export type AboutUserFormData = {
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
};

interface AboutUserFormProps {
  onSubmit: (data: AboutUserFormData) => void;
  isLoading: boolean;
  submitText: string;
}

export const AboutUserForm: FC<AboutUserFormProps> = observer(
  ({ onSubmit, isLoading, submitText }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<AboutUserFormData>();
    const { t } = useTranslation();

    return (
      <form
        className="flex w-full flex-col gap-5"
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <InputWithError
          required
          label="First name"
          placeholder="First name"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('firstName', { required: 'Fill in this field' })}
          error={errors.firstName?.message}
        />
        <InputWithError
          required
          label="Last name"
          placeholder="Last name"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('lastName', { required: 'Fill in this field' })}
          error={errors.lastName?.message}
        />
        <InputWithError
          label="Phone number"
          placeholder="Phone number"
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
          {...register('phoneNumber')}
          error={errors.phoneNumber?.message}
        />
        <Button className="mt-6" disabled={isLoading}>
          {submitText}
        </Button>
      </form>
    );
  },
);
