import { DialogContent, DialogOverlay } from '@reach/dialog';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import {
  ArrowUpIcon,
  ChevronDownIcon,
  FolderOpenIcon,
  Spinner,
} from '../../icons';
import { Button } from './buttons/Button';
import { InputWithError } from './InputWithError';

export interface Folder {
  name: string;
  fullPath: string;
}

interface FolderBrowserState {
  selected: Folder | null;
  setSelected: (selected: Folder | null) => void;
  handleFolderChange: (folder: Folder) => void;
  isOpen: boolean;
  toggleIsOpen: () => void;
  handleOnSave: () => void;
}

interface FolderBrowserProps {
  value: string;
  folders: Folder[];
  folderChain: Folder[];
  onSave: (folder: Folder) => void;
  onFolderChange: (folder: Folder) => void;
  onGoUp: (folder: Folder) => void;
  onFolderChainClick?: (folder: Folder) => void;
  error?: string;
  isLoading?: boolean;
  statusColor?: 'success' | 'error' | 'labels';
}

export const FolderBrowser: FC<FolderBrowserProps> = observer(
  ({
    value,
    folders,
    folderChain,
    onSave,
    onFolderChange,
    onGoUp,
    onFolderChainClick,
    error,
    isLoading = false,
    statusColor,
  }) => {
    const onClickOutsideRef = useOnClickOutside<HTMLDivElement>(() =>
      state.setSelected(null),
    );

    const state = useLocalObservable<FolderBrowserState>(() => ({
      selected: null,
      setSelected(selected) {
        state.selected = selected;
      },
      handleFolderChange(folder) {
        onFolderChange(folder);
        state.setSelected(null);
      },
      isOpen: false,
      toggleIsOpen() {
        state.isOpen = !state.isOpen;
      },
      handleOnSave() {
        const folder = folderChain[folderChain.length - 1] || {
          name: '/',
          fullPath: '/',
        };

        onSave(folder);
        state.toggleIsOpen();
      },
    }));

    return (
      <>
        <div
          className={`flex rounded-lg border bg-blocks ${
            statusColor && statusColor === 'error'
              ? 'border-' + statusColor
              : 'border-transparent'
          }`}
        >
          <div className="flex-1">
            <InputWithError
              value={value}
              disabled
              className={`placeholder:text-sky z-10 w-full rounded-lg rounded-r-none bg-blocks py-3 pl-3 ${
                statusColor ? 'text-' + statusColor : 'text-labels'
              }`}
              error={error}
            />
          </div>

          {isLoading && !state.isOpen ? (
            <span className="self-center">
              <Spinner />
            </span>
          ) : (
            <Button
              type="button"
              onClick={state.toggleIsOpen}
              className="h-12 rounded-l-none bg-transparent hover:opacity-80"
            >
              <FolderOpenIcon
                className={`w-4 ${
                  statusColor ? 'text-' + statusColor : 'text-labels'
                }`}
              />
            </Button>
          )}
        </div>
        <DialogOverlay
          className="absolute top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40"
          isOpen={state.isOpen}
        >
          <DialogContent aria-label="dialog" className="focus:outline-none">
            <div className="flex max-h-fit min-h-[24rem] w-96 flex-col justify-between rounded-lg bg-selectors p-8">
              <div>
                <div className="flex justify-between px-3 pb-6">
                  <div className="flex flex-wrap items-center text-sm font-light text-text">
                    {folderChain.map((folder, index) => {
                      const isLast = index === folderChain.length - 1;
                      return (
                        <div
                          key={folder.fullPath}
                          className="flex cursor-pointer items-center"
                        >
                          <button
                            disabled={isLoading}
                            onClick={() =>
                              onFolderChainClick && onFolderChainClick(folder)
                            }
                            className="last:font-bold last:text-titles"
                          >
                            {folder.name}
                          </button>

                          {!isLast && (
                            <ChevronDownIcon className="w-6 -rotate-90" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <button
                    disabled={folderChain.length < 1 || isLoading}
                    className="h-fit flex-grow-0 rounded-full bg-text p-1.5"
                    onClick={() => onGoUp(folderChain[folderChain.length - 1])}
                  >
                    <ArrowUpIcon className="h-4 text-selectors" />
                  </button>
                </div>
                <div ref={onClickOutsideRef} className="flex flex-col gap-1">
                  {folders.map((folder) => (
                    <div
                      className={`cursor-pointer select-none rounded p-3 transition-colors hover:bg-titles hover:text-selectors ${
                        folder.fullPath === state.selected?.fullPath
                          ? 'bg-titles text-selectors '
                          : ' text-labels odd:bg-popup'
                      }`}
                      key={folder.fullPath}
                      onClick={() => state.setSelected(folder)}
                      onDoubleClick={() => state.handleFolderChange(folder)}
                    >
                      <div className="flex items-center space-x-2 text-center">
                        <FolderOpenIcon className="w-5" />
                        <span className="text-xl font-light">
                          {folder.name}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-12 space-y-2">
                {state.selected ? (
                  <Button
                    className={`w-full bg-warning`}
                    onClick={() => state.handleFolderChange(state.selected!)}
                    disabled={isLoading}
                  >
                    Open Folder
                  </Button>
                ) : (
                  <Button
                    className={`w-full bg-success`}
                    onClick={state.handleOnSave}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}
                <Button
                  className="w-full bg-error"
                  onClick={() => {
                    state.toggleIsOpen();
                    state.setSelected(null);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </DialogContent>
        </DialogOverlay>
      </>
    );
  },
);
