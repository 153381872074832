import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeInterval } from '../../../types';
import { PillTabs } from './PillTabs';

export const IntervalPillTabs: FC<{
  value: string;
  onChange: (value: TimeInterval) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const tabs = [
    { value: TimeInterval.Day, label: t('D') },
    { value: TimeInterval.Month, label: t('M') },
    { value: TimeInterval.Year, label: t('Y') },
  ];

  return (
    <PillTabs
      tabs={tabs}
      value={value}
      onChange={(newTab) => onChange(newTab as TimeInterval)}
    />
  );
};
