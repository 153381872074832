import React, { FC } from 'react';

export const ChartLegends: FC<{ legends: { label: string; bg: string }[] }> = ({
  legends,
}) => (
  <div className="flex flex-wrap justify-center text-xs text-text">
    {legends.map((legend, index) => (
      <span key={index} className="mx-3 inline-flex items-center">
        <div className={`inline-block h-3 w-3 rounded-full ${legend.bg}`} />
        <span className="ml-1 font-light">{legend.label}</span>
      </span>
    ))}
  </div>
);
