import React, { FC, memo, SVGProps } from 'react';

interface CompanyFallbackLogoProps extends SVGProps<SVGSVGElement> {
  character: string;
}

// eslint-disable-next-line react/prop-types
export const CompanyFallbackLogo: FC<CompanyFallbackLogoProps> = memo(({ character, ...props }) => {
  const lineHeight = 0.11; // adjust this value as needed to adjust the vertical position of the text

  return (
    <svg
      viewBox="0 0 23 24"
      fill="CurrentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <text
        x="50%"
        y="50%"
        dy={`${lineHeight}em`}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontSize={16}
      >
        {character}
      </text>
    </svg>
  );
});