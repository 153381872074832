import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../../hooks/useStore';
import { ExternalSystemType } from '../../../types';

interface RegisterState {
  system: ExternalSystemType;
  notifyEmail: string;
  uuid: string;
}

export const OauthCallback: React.FC = () => {
  const { externalSystemStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authCode = searchParams.get('code');
    const oauthState = searchParams.get('state');

    const data = JSON.parse(localStorage.getItem('OAuthSystemRegister') || '');
    const registerState = data as RegisterState;

    if (!authCode || !registerState.uuid || !oauthState) return;
    if (registerState.uuid != oauthState) return;

    externalSystemStore.addSystemFortnox(authCode).then((id) => {
      localStorage.removeItem('OAuthSystemRegister');

      navigate(data.parentState.redirect.pathname, {
        state: {
          ...data.parentState,
          createdSystemId: id,
        },
      });
    });
  }, []);

  return <div className="m-auto animate-pulse">Please wait..</div>;
};
