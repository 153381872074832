import { Instance, types as t } from 'mobx-state-tree';

export const IntegrationStatsSetting = t.model({
  id: t.string,
  name: t.string,
  color: t.string,
});

export const DashboardSettings = t.model({
  defaultColors: t.array(t.string),
  integrationStatsSettings: t.array(IntegrationStatsSetting),
});

export type IntegrationStatsSettingType = Instance<
  typeof IntegrationStatsSetting
>;
