import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChevronDownIcon } from '../../icons';

type PaginationProps = {
  className?: string;
  page: number;
  numPages: number;
  onChange: (newPage: number) => void;
  hasBorder?: boolean;
};

export const Pagination = observer(
  ({
    className,
    page,
    numPages,
    onChange,
    hasBorder = false,
  }: PaginationProps) => {
    const isOnePage = [0, 1].includes(numPages);
    const isFirstPage = page === 1;
    const isLastPage = page === numPages;
    const border = hasBorder ? 'border border-solid border-popup' : '';

    return (
      <div className={`flex items-center justify-center ${className}`}>
        <button
          disabled={isFirstPage}
          onClick={() => onChange(page - 1)}
          className={`rounded-full bg-popup p-2 ${border} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-800 ${
            isFirstPage ? 'cursor-default opacity-50' : ''
          }`}
        >
          <ChevronDownIcon className="w-5 rotate-90" />
        </button>
        <div
          className={`mx-4 w-24 rounded-md bg-popup py-3 text-center ${border}`}
        >
          <span className="text-sm font-semibold text-labels">
            {isOnePage ? '1 sida' : `Sida ${page} av ${numPages}`}
          </span>
        </div>
        <button
          disabled={isLastPage}
          onClick={() => onChange(page + 1)}
          className={`rounded-full bg-popup p-2 ${border} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-800 ${
            isLastPage ? 'cursor-default opacity-50' : ''
          }`}
        >
          <ChevronDownIcon className="w-5 -rotate-90" />
        </button>
      </div>
    );
  },
);
