import { Instance, types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';

export const LogEntry = t.model({
  event: t.string,
  status: t.string,
  createdAt: IsoDate,
  message: t.string,
});

export const EntityLog = t.model({
  id: t.string,
  createdAt: IsoDate,
  entityType: t.string,
  entityId: t.string,
  logEntries: t.array(LogEntry),
});

export type EntityLogInstance = Instance<typeof EntityLog>;
export type LogEntryInstance = Instance<typeof LogEntry>;
