import React, { FC } from 'react';

type RoundButtonProps = {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  children: any;
};

export const RoundButton: FC<RoundButtonProps> = ({
  className,
  children,
  disabled,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`flex justify-center rounded-full align-middle hover:cursor-pointer ${className}`}
    >
      <span className="self-center">{children}</span>
    </button>
  );
};
