import { endOfYear, startOfYear, subYears } from 'date-fns';
import {
  DocumentFlowData,
  DocumentFlowEntry,
  DocumentFlowEntryStatus,
  TimeInterval,
} from '../types';
import { getDateTimesFromInterval } from '../utils/getDateTimesFromInterval';
import { sleep } from '../utils/sleep';

const DOCUMENT_STATUS_VALUES = Object.values(DocumentFlowEntryStatus);

const START_LAST_YEAR = subYears(startOfYear(new Date()), 1);
const END_THIS_YEAR = endOfYear(new Date());

const DATABASE: DocumentFlowEntry[] = Array.from({ length: 10000 }, () => ({
  createdAt: new Date(
    START_LAST_YEAR.getTime() +
      Math.random() * (END_THIS_YEAR.getTime() - START_LAST_YEAR.getTime()),
  ).toISOString(),
  pipelineStatus:
    DOCUMENT_STATUS_VALUES[
      Math.floor(Math.random() * DOCUMENT_STATUS_VALUES.length)
    ],
}));

export const fetchDocumentFlowData_demo = async (
  timeInterval: TimeInterval,
): Promise<DocumentFlowData> => {
  await sleep();

  const [start, end] = getDateTimesFromInterval(timeInterval);
  const startDate = new Date(start);
  const endDate = new Date(end);

  return {
    currentPeriod: Math.floor(Math.random() * 1000),
    prevPeriod: Math.floor(Math.random() * 1000),
    prevPeriodTrending: Math.random() < 0.5,
    documents: DATABASE.filter((entry) => {
      const createdAtDate = new Date(entry.createdAt);

      return startDate < createdAtDate && createdAtDate < endDate;
    }),
  };
};
