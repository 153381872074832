import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FadeInDiv,
  Label,
  NotFound,
  PopupModal,
} from '../../components/general';
import { Button } from '../../components/general/buttons';
import { useStore } from '../../hooks';
import { UserPlusIcon } from '../../icons';
import { RelationInstance } from '../../models';

const headerMapper = {
  Pending: 'Invitation',
  Revoked: 'Invitation',
  Declined: 'Invitation',
  Active: 'Client',
  Paused: 'Client',
  Archived: 'Client',
};

interface RelationActionState {
  relation: RelationInstance | null;
  isLoading: boolean;
  showConfirmWindow: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setRelation: (relation: RelationInstance | null) => void;
  setShowConfirmWindow: (showConfirmWindow: boolean) => void;
}

export const RelationActionPage = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { relationStore } = useStore();

  const state = useLocalObservable<RelationActionState>(() => ({
    relation: null,
    isLoading: true,
    showConfirmWindow: false,
    setIsLoading(isLoading) {
      state.isLoading = isLoading;
    },
    setRelation(relation: RelationInstance | null) {
      state.relation = relation;
    },
    setShowConfirmWindow(showConfirmWindow: boolean) {
      state.showConfirmWindow = showConfirmWindow;
    },
  }));

  useEffect(() => {
    const fetchRelation = async () => {
      try {
        if (id == undefined) {
          return;
        }
        state.setIsLoading(true);
        await relationStore.fetchRelation(id);
        state.setRelation(relationStore.relation);
      } finally {
        state.setIsLoading(false);
      }
    };
    fetchRelation();
  }, []);

  if (state.isLoading) {
    return null;
  }

  const onResendInvitation = () => {
    if (state.relation == null) {
      return;
    }

    relationStore
      .reinvite(state.relation.id)
      .then(() => navigate('/relations'));
  };

  const onRevokeInvitation = () => {
    if (state.relation == null) {
      return;
    }
    relationStore
      .revokeInvite(state.relation.id)
      .then(() => navigate('/relations'));
  };

  const onPauseRelation = () => {
    if (state.relation == null) {
      return;
    }
    relationStore.pause(state.relation.id).then(() => navigate('/relations'));
  };

  const onResumeRelation = () => {
    if (state.relation == null) {
      return;
    }
    relationStore.resume(state.relation.id).then(() => navigate('/relations'));
  };

  const onArchiveRelation = () => {
    if (state.relation == null) {
      return;
    }
    relationStore.archive(state.relation.id).then(() => navigate('/relations'));
  };

  const getResendRevokeButtons = () => {
    return (
      <>
        <Button
          className={
            'mt-10 w-full bg-success text-xl font-bold text-background'
          }
          onClick={onResendInvitation}
        >
          Resend invitation
        </Button>
        <Button
          className={'mt-3 w-full bg-error text-xl font-bold text-background'}
          onClick={onRevokeInvitation}
        >
          Revoke invitation
        </Button>
      </>
    );
  };

  const getResendArchiveButtons = () => {
    return (
      <>
        <Button
          className={
            'mt-10 w-full bg-success text-xl font-bold text-background'
          }
          onClick={onResendInvitation}
        >
          Resend invitation
        </Button>
        <Button
          className={'mt-3 w-full bg-error text-xl font-bold text-background'}
          onClick={() => state.setShowConfirmWindow(true)}
        >
          Delete relation
        </Button>
      </>
    );
  };

  const getArchiveButton = () => {
    return (
      <>
        <Button
          className={'mt-3 w-full bg-error text-xl font-bold text-background'}
          onClick={() => state.setShowConfirmWindow(true)}
        >
          Delete relation
        </Button>
      </>
    );
  };

  const getPauseArchiveButtons = () => {
    return (
      <>
        <Button
          className={
            'mt-10 w-full bg-success text-xl font-bold text-background'
          }
          onClick={onPauseRelation}
        >
          Pause relation
        </Button>
        <Button
          className={'mt-3 w-full bg-error text-xl font-bold text-background'}
          onClick={() => state.setShowConfirmWindow(true)}
        >
          Delete relation
        </Button>
      </>
    );
  };

  const getResumeArchiveButtons = () => {
    return (
      <>
        <Button
          className={
            'mt-10 w-full bg-success text-xl font-bold text-background'
          }
          onClick={onResumeRelation}
        >
          Resume relation
        </Button>
        <Button
          className={'mt-3 w-full bg-error text-xl font-bold text-background'}
          onClick={() => state.setShowConfirmWindow(true)}
        >
          Delete relation
        </Button>
      </>
    );
  };

  const getInputs = () => {
    return (
      <>
        <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
          Company name
        </Label>
        <input
          type="text"
          placeholder={state.relation?.companyName}
          readOnly={true}
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
        />
        <Label className="mb-2 inline-block whitespace-nowrap font-semibold text-labels">
          Organisation number
        </Label>
        <input
          type="text"
          placeholder={state.relation?.orgNo}
          readOnly={true}
          className="text-sky placeholder:text-sky z-10 w-full rounded-md bg-blocks py-3 pl-3"
        />
      </>
    );
  };

  const getButtons = () => {
    switch (state.relation?.status) {
      case 'Pending':
        return getResendRevokeButtons();
      case 'Declined':
        return getResendArchiveButtons();
      case 'Revoked':
        return getArchiveButton();
      case 'Active':
        return getPauseArchiveButtons();
      case 'Paused':
        return getResumeArchiveButtons();
      default:
        return null;
    }
  };

  return (
    <>
      {state.relation == null ? (
        <NotFound />
      ) : (
        <FadeInDiv className="mt-32 flex items-center self-center">
          <PopupModal
            className="w-[442px]"
            open={!state.showConfirmWindow}
            title={headerMapper[state.relation?.status ?? '']}
            titleIcon={<UserPlusIcon className="mr-0 h-7 w-7" />}
            canClose={true}
            onClose={() => navigate('/relations')}
          >
            <div className="flex w-full items-center">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <div className="mb-5">{getInputs()}</div>
                <div className="mb-5">{getButtons()}</div>
              </form>
            </div>
          </PopupModal>
          <PopupModal
            titleIcon={<UserPlusIcon className="mr-0 h-7 w-7" />}
            className="w-[442px]"
            title="Are you sure you want to delete this relation?"
            canClose={true}
            onClose={() => state.setShowConfirmWindow(false)}
            open={state.showConfirmWindow}
          >
            <Button
              className="w-full bg-error"
              onClick={() => {
                onArchiveRelation();
              }}
            >
              Yes, delete it forever!
            </Button>
            <Button
              onClick={() => state.setShowConfirmWindow(false)}
              className="mt-2 w-full bg-success"
            >
              Cancel
            </Button>
          </PopupModal>
        </FadeInDiv>
      )}
    </>
  );
});
