import { Instance, types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';

export const IntegrationStatEntryEvent = t.model({
  eventDateTime: IsoDate,
  fetched: t.integer,
});

export const IntegrationStatEntry = t.model({
  entryDate: IsoDate,
  totalFetched: t.integer,
  events: t.array(IntegrationStatEntryEvent),
});

export const IntegrationStatData = t.model({
  integrationId: t.string,
  entries: t.maybeNull(t.array(IntegrationStatEntry)),
});

export type IntegrationStatType = Instance<typeof IntegrationStatData>;
