import React, { FC } from 'react';
import {
  BjornLundenIcon,
  CompanyFallbackLogo,
  FortnoxIcon,
  HarvestLogoIcon,
  SftpIcon,
  TwentyFourSevenOfficeIcon,
} from '../../icons';
import { ExternalSystemType } from '../../types';

type SystemIconProps = {
  className?: string;
  externalSystemType?: ExternalSystemType;
  externalSystemName?: string;
  logoUrl?: string;
};

export const ExternalSystemIconMapper: FC<SystemIconProps> = ({
  className,
  externalSystemType,
  externalSystemName,
  logoUrl
}) => {

  if (logoUrl) {
    return (
      <div className={className}>
        <img
          className='rounded-md'
          src={logoUrl}
        />
      </div>
    )
  }

  if (externalSystemName && !['Harvest', 'HarvestFinance'].includes(externalSystemName) && externalSystemType === ExternalSystemType.HarvestFinance) {
    return (
      <CompanyFallbackLogo className={`${className}`} character={externalSystemName[0]}/>
    );
  }

  switch (externalSystemType) {
    case ExternalSystemType.Fortnox:
      return <FortnoxIcon className={className} />;
    case ExternalSystemType.SFTP:
      return <SftpIcon className={className} />;
    case ExternalSystemType.Bjornlunden:
      return <BjornLundenIcon className={className} />;
    case ExternalSystemType.TwentyFourSevenOffice:
      return <TwentyFourSevenOfficeIcon className={className} />;
    case ExternalSystemType.HarvestFinance:
      return <HarvestLogoIcon className={className} />;
    default:
      break;
  }

  return null;
};
