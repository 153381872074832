import { observer } from 'mobx-react-lite';
import React from 'react';
import { CUSTOMER_INVOICE_TYPE_TEXTS } from '../../../constants';
import { useStore } from '../../../hooks/useStore';
import { CustomerInvoiceFilterInputType } from '../../../models/finance/CustomerInvoiceFilter';
import { CustomerInvoiceFilter, CustomerInvoiceType } from '../../../types/finance';
import { FilterObj, FilterPicker } from '../../general/Filter/FilterPicker';

const CUSTOMER_INVOICE_FILTER_TEXTS = {
  [CustomerInvoiceFilter.Type]: 'Type',
  [CustomerInvoiceFilter.DocumentNumber]: 'Invoice #',
  [CustomerInvoiceFilter.CustomerNumber]: 'Customer #',
  [CustomerInvoiceFilter.Amount]: 'Amount',
};

const filterTypeItems = Object.values(CustomerInvoiceFilter).map((value) => ({
  name: CUSTOMER_INVOICE_FILTER_TEXTS[value],
  value,
  inputType: CustomerInvoiceFilterInputType(value),
}));

export const CustomerInvoiceFilterPicker = observer(() => {
  const {
    customerInvoiceStore: { invoiceFilters },
  } = useStore();

  const filters = invoiceFilters.map((filter) => ({
    filterType: {
      name: CUSTOMER_INVOICE_FILTER_TEXTS[filter.name],
      value: filter.name,
      inputType: filter.inputType,
    },
    filterValue: {
      name: filter.displayName,
      value:
        typeof filter.value === 'string'
          ? filter.value
          : filter.value?.id || '',
    },
  }));

  const getFilterValueItems = (filter: CustomerInvoiceFilter) => {
    switch (filter) {
      case CustomerInvoiceFilter.Type: {
        return Object.values(CustomerInvoiceType)
          .filter((value) => typeof value === 'number')
          .map((value) => ({
            name: CUSTOMER_INVOICE_TYPE_TEXTS[value],
            value: value,
          })) as FilterObj[];
        }
      default:
        return [];
    }
  };

  return (
    <FilterPicker
      filterTypes={Object.values(CustomerInvoiceFilter)}
      filterTypeItems={filterTypeItems}
      filters={filters}
      getFilterValueItems={getFilterValueItems}
    />
  );
});
