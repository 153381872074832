import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

interface PageTitleProps {
  title?: string;
  titleIcon?: JSX.Element;
}

export const PageTitle: FC<PageTitleProps> = observer(
  ({ title, titleIcon }) => {
    return (
      <div className="flex items-center space-x-3 text-titles">
        {titleIcon}
        <h1 className="text-4xl font-bold">{title}</h1>
      </div>
    );
  },
);
