import { types as t, Instance } from 'mobx-state-tree';
import { FilterInputType } from '../components/general/Filter';
import { INTEGRATION_ENTITY_STATUS_TEXTS, INTEGRATION_TYPE_TEXTS } from '../constants';
import { InvoicesFilter, maybeNullReference } from '../types';
import { numberToMoney } from '../utils/numberToMoney';
import { ExternalSystem } from './ExternalSystem';

const getInputType = (filter: InvoicesFilter): FilterInputType => {
  switch (filter) {
    case InvoicesFilter.Imported:
      return FilterInputType.Date;
      case InvoicesFilter.EntityType:
    case InvoicesFilter.EntityStatus:
    case InvoicesFilter.SourceSystemId:
    case InvoicesFilter.TargetSystemId:
      return FilterInputType.Select;
    default:
      return FilterInputType.Text;
  }
};

const ExternalSystemsFilter = t
  .model('ExternalSystemsFilter', {
    name: t.enumeration<InvoicesFilter>(Object.values(InvoicesFilter)),
    value: maybeNullReference(ExternalSystem),
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name || '';
    },
    get inputType() {
      return getInputType(self.name);
    },
  }));

const DefaultFilter = t
  .model('DefaultFilter', {
    name: t.enumeration<InvoicesFilter>(Object.values(InvoicesFilter)),
    value: t.string,
  })
  .views((self) => {
    return {
      get displayName(): string {
        switch (self.name) {
          case InvoicesFilter.EntityType:
            return INTEGRATION_TYPE_TEXTS[self.value];
          case InvoicesFilter.EntityStatus:
            return INTEGRATION_ENTITY_STATUS_TEXTS[self.value]; 
          case InvoicesFilter.Amount:
            return numberToMoney(self.value, 'SEK');
          case InvoicesFilter.CustomerNumber:
            return `Customer #${self.value}`;
          case InvoicesFilter.InvoiceNumber:
            return `Invoice #${self.value}`;
          default:
            return self.value;
        }
      },
      get inputType() {
        return getInputType(self.name);
      },
    };
  });

export const InvoicesFilterModel = t.union(
  {
    dispatcher(snapshot) {
      if (
        snapshot.name === InvoicesFilter.SourceSystemId ||
        snapshot.name === InvoicesFilter.TargetSystemId
      ) {
        return ExternalSystemsFilter;
      }
      return DefaultFilter;
    },
  },
  ExternalSystemsFilter,
  DefaultFilter,
);

export type InvoicesFilterInstance = Instance<typeof InvoicesFilterModel>;
