import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ExternalSystemIconMapper,
  NothingToDisplay,
  PageContainer,
  Pagination,
  SlideInPopup,
} from '../../components/general';
import { Button } from '../../components/general/buttons';
import {
  ActiveFilters,
  InvoicesFilterPicker,
} from '../../components/general/Filter';
import {
  Table,
  TableHeaderCell,
  TableRow,
} from '../../components/general/Table';
import { useStore } from '../../hooks';
import { ListIcon, RetryIcon } from '../../icons';
import { IntegrationEntityInstance } from '../../models/IntegrationEntity';
import { ExternalSystemType, PipelineStatus } from '../../types';
import { numberToMoney } from '../../utils/numberToMoney';

const colorStatusMapper = {
  Ready: 'warning',
  Running: 'warning',
  Successful: 'success',
  Error: 'error',
};

const textStatusMapper = {
  Ready: 'Waiting',
  Running: 'In progress',
  Successful: 'Successful',
  Error: 'Failed',
};

export const InvoiceListPage = observer(() => {
  const { integrationEntityStore, externalSystemStore, uiStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    pagedInvoiceEntities: invoices,
    invoicesSearchQuery: query,
    pageInfo,
    invoicesPage,
  } = integrationEntityStore;

  const systems = externalSystemStore.externalSystems;

  const getSystemById = (systemId: string) => {
    return (
      (systems?.find((x) => x.id === systemId)) || undefined
    );
  };

  const setCurrentPage = (page: number) => {
    const searchParams = new URLSearchParams(query);

    if (page === 1) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', String(page));
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const isAscending = false;
  const tableHeaders = [
    {
      title: 'Status',
      className: '',
    },
    {
      title: 'Imported',
      className: '',
    },
    {
      title: 'Type',
      className: '',
    },
    {
      title: 'Invoice #',
      className: '',
    },
    {
      title: 'Customer #',
      className: '',
    },
    {
      title: 'Amount',
      className: '',
    },
    {
      title: 'Source',
      className: '',
    },
    {
      title: 'Destination',
      className: '',
    },
  ];

  const getTableRowItems = (integrationEntity: IntegrationEntityInstance) => {
    const entityStatus = integrationEntity.status;

    const progress = (
      <div
        className={`ml-[1px] w-full bg-warning opacity-30 group-first:rounded-l-md group-last:rounded-r-md`}
      ></div>
    );

    const blueprintCreation = integrationEntity?.metadata?.find(
      (metadata) => metadata.type == 'BlueprintCreation',
    );

    const invoiceEntity = integrationEntity?.metadata?.find(
      (metadata) => metadata.type == 'InvoiceEntity',
    );

    return [
      {
        value: (
          <div className={`text-${colorStatusMapper[entityStatus]}`}>
            <div
              className={`relative w-32 rounded-md border px-2 py-1 text-left border-${colorStatusMapper[entityStatus]} text-${colorStatusMapper[entityStatus]}`}
            >
              {textStatusMapper[entityStatus]}
              {entityStatus === 'Running' && (
                <span className="absolute top-0 left-0 h-full w-full rounded-md">
                  <div className="flex h-full w-full">{progress}</div>
                </span>
              )}
              {entityStatus === PipelineStatus.Failed && (
                <div className="absolute top-0 right-0 mt-0 mb-0 flex h-full items-center justify-center pr-2">
                  <div
                    onClick={() => {
                      integrationEntityStore.retry(invoiceEntity.id);
                    }}
                    className="hover:cursor-pointer"
                  >
                    <RetryIcon className="w-5 text-warning" />
                  </div>
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        value: integrationEntity?.createdAt
          ? new Date(integrationEntity?.createdAt).toLocaleString()
          : '',
      },
      {
        value:
          blueprintCreation?.entityType &&
          (['SupplierInvoice', 'CustomerInvoice'].includes(
            blueprintCreation?.entityType,
          )
            ? 'Invoice'
            : 'Credit'),
      },
      {
        value: invoiceEntity?.invoiceNumber ?? '',
      },
      {
        value: invoiceEntity?.customerNumber ?? '',
      },
      {
        value: invoiceEntity?.amount && (
          <span className={`text-right ${invoiceEntity?.amount < 0 ? 'text-error' : ''}`}>
            {numberToMoney(invoiceEntity?.amount, 'SEK')}
          </span>
        ),
      },
      {
        value: blueprintCreation?.readerSystemId && (
          <div className="flex">
            <ExternalSystemIconMapper
            logoUrl={getSystemById(blueprintCreation?.readerSystemId)?.systemLogo || undefined}
              className="m-0 mr-2 h-6 w-6 rounded-md"
              externalSystemType={getSystemById(
                blueprintCreation?.readerSystemId,
              )?.externalSystemType as ExternalSystemType}
              externalSystemName={getSystemById(
                blueprintCreation?.readerSystemId,
              )?.name}
            />
            {
              systems?.find((x) => x.id === blueprintCreation?.readerSystemId)
                ?.name
            }
          </div>
        ),
      },
      {
        value: blueprintCreation?.writerSystemId && (
          <div className="flex">
            <ExternalSystemIconMapper
             logoUrl={getSystemById(blueprintCreation?.writerSystemId)?.systemLogo || undefined}
              className="m-0 mr-2 h-6 w-6 rounded-md"
              externalSystemType={getSystemById(
                blueprintCreation?.writerSystemId,
              )?.externalSystemType as ExternalSystemType}
              externalSystemName={getSystemById(
                blueprintCreation?.writerSystemId,
              )?.name}
            />
            {
              systems?.find((x) => x.id === blueprintCreation?.writerSystemId)
                ?.name
            }
          </div>
        ),
      },
    ];
  };

  useEffect(() => {
    integrationEntityStore.handleInvoicesSearchChange(location.search);
  }, [location.search]);

  const isLoading = invoices === null;
  const hasFilters = integrationEntityStore.invoicesFilters.length > 0;

  return (
    <PageContainer
      title="Documents"
      titleIcon={<ListIcon className="w-12" />}
      useBackground={false}
    >
      {((invoices && hasFilters) || (invoices && invoices.length > 0)) && (
        <ActiveFilters
          filters={integrationEntityStore.invoicesFilters}
          onFilterClick={uiStore.toggleIsFilterPickerOpen}
        />
      )}

      <SlideInPopup
        isOpen={uiStore.isFilterPickerOpen}
        onToggle={uiStore.toggleIsFilterPickerOpen}
        title="Filters"
      >
        <InvoicesFilterPicker />
      </SlideInPopup>
      {(invoices && hasFilters) || (invoices && invoices.length > 0) ? (
        <>
          <Table
            headerItems={tableHeaders}
            renderHeaderCell={(item, index) => (
              <TableHeaderCell
                key={index}
                className={item.className}
                title={item.title || ''}
                isAscending={isAscending}
              />
            )}
            items={invoices as IntegrationEntityInstance[]}
            renderRow={(item, index) => (
              <TableRow
                key={index}
                items={getTableRowItems(item)}
                index={index}
              />
            )}
          />
          {pageInfo && pageInfo.totalPages > 1 && (
            <Pagination
              className="mt-5"
              page={invoicesPage}
              numPages={pageInfo.totalPages}
              onChange={setCurrentPage}
            />
          )}
        </>
      ) : (
        <NothingToDisplay
          className={`mt-5 sm:mt-20 ${isLoading && 'animate-pulse'}`}
          loading={isLoading}
          text={'There are currently no documents to display'}
        >
          {!isLoading && (
            <Button onClick={() => navigate('/integrations/create')}>
              {"Let's get started!"}
            </Button>
          )}
        </NothingToDisplay>
      )}
    </PageContainer>
  );
});
