import { types as t, applySnapshot } from 'mobx-state-tree';
import { IntegrationStatData, IntegrationStatType } from '.';
import { AxiosPromise } from 'axios';
import { withRequest } from '../extensions';
import { INTEGRATIONS_API_URL } from '../constants';

const IntegrationStatArray = t.array(IntegrationStatData);

export const StatisticsStore = t
  .model('StatisticsStore', {
    integrationStatData: t.maybeNull(IntegrationStatArray),
  })
  .extend(withRequest)
  .actions((self) => {
    return {
      setIntegrationStatData: (data: IntegrationStatType[]) => {
        self.integrationStatData = IntegrationStatArray.create(data);
      },
    };
  })
  .actions((self) => {
    const { request } = self;

    return {
      getIntegrationStats: (
        integrationIds: string[],
        fromDate: Date,
        toDate: Date,
      ) => {
        const promises = [] as AxiosPromise[];

        integrationIds.forEach((integrationId) => {
          promises.push(
            request({
              method: 'GET',
              url: INTEGRATIONS_API_URL + 'api/statistics/integrations/entries',
              params: { integrationId, fromDate, toDate },
            }),
          );
        });

        if (self.integrationStatData === null) {
          self.integrationStatData = IntegrationStatArray.create();
        }

        Promise.all(promises).then((results) => {
          const fetchedStats = [] as IntegrationStatType[];

          results.forEach((result) => {
            if (result.status === 200) {
              fetchedStats.push(result.data);
            }
          });

          const doRefresh =
            self.integrationStatData?.length !== fetchedStats.length ||
            fetchedStats.some((fetchedStat) => {
              const oldStat = self.integrationStatData?.find(
                (i) => i.integrationId === fetchedStat.integrationId,
              );
              return (
                !oldStat ||
                fetchedStat.entries?.some((newEntry) => {
                  const existingDateEntry = oldStat.entries?.find(
                    (existingEntry) =>
                      existingEntry.entryDate?.getDate() ===
                      new Date(newEntry.entryDate).getDate(),
                  );
                  return (
                    !existingDateEntry ||
                    newEntry.totalFetched !== existingDateEntry.totalFetched
                  );
                })
              );
            });

          if (doRefresh) self.setIntegrationStatData(fetchedStats);
        });
      },
      reset() {
        applySnapshot(self, {});
      },
    };
  });
