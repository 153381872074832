import React, { forwardRef, InputHTMLAttributes } from 'react';
import { FormError } from './FormError';
import { Label } from './Label';

interface Props {
  error?: string | false;
  placeholder?: string;
  step?: string | number;
}
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | false;
  label?: string;
}

export const InputWithError = forwardRef<HTMLInputElement, Props>(
  ({ error, placeholder, step, className, label, required, ...props }, ref) => {
    return (
      <div>
        {label && (
          <Label
            required={required}
            className="mb-2 inline-block whitespace-nowrap font-bold text-labels"
          >
            {label}
          </Label>
        )}
        <input
          step={step}
          className={`${className} ${
            error ? 'border-error' : 'focus:border-blue-600'
          }`}
          placeholder={placeholder}
          ref={ref}
          {...props}
        />
        {error && <FormError>{error}</FormError>}
      </div>
    );
  },
);
