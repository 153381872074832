import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { INTEGRATIONS_API_URL } from '../../constants';
import { useStore } from '../../hooks';
import { Folder, FolderBrowser } from '../general/FolderBrowser';

interface SftpFolderBrowserProps {
  systemId: string;
  value: string;
  onChange: (folder: Folder) => void;
  error?: string;
  statusColor?: 'success' | 'error' | 'labels';
  isLoading?: boolean;
}

interface SftpFolderBrowserState {
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
  folders: Folder[] | null;
  setFolders: (folders: Folder[]) => void;
  goIntoFolder: (folder: Folder) => void;
  goUpFolder: (folder: Folder) => void;
  filteredFolders: Folder[];
  folderChain: Folder[];
  addToFolderChain: (folder: Folder) => void;
  removeFromFolderChain: (folder: Folder) => void;
  handleFolderChainClick: (folder: Folder) => void;
  fetchFolders: (path: string) => Promise<any>;
}

export const SftpFolderBrowser: FC<SftpFolderBrowserProps> = observer(
  ({ systemId, error, statusColor, value, isLoading = false, onChange }) => {
    const {
      authStore: { authRequest },
    } = useStore();

    const state = useLocalObservable<SftpFolderBrowserState>(() => ({
      isFetching: false,
      setIsFetching(isFetching) {
        state.isFetching = isFetching;
      },
      folders: null,
      setFolders(folders) {
        state.folders = folders;
      },
      get filteredFolders(): Folder[] {
        if (!state.folders) return [];

        return state.folders.filter(
          (folder) => !['.', '..'].includes(folder.name),
        );
      },
      async fetchFolders(path) {
        if (state.isFetching) return null;

        try {
          state.setIsFetching(true);
          return await authRequest({
            url: `${INTEGRATIONS_API_URL}api/systems/sftp/dir`,
            method: 'GET',
            params: {
              systemId: systemId,
              path,
            },
          });
        } catch (error) {
          console.error(error);
        } finally {
          state.setIsFetching(false);
        }
      },
      async goIntoFolder(folder) {
        const {
          data: { folders },
        } = await state.fetchFolders(folder.fullPath);

        state.setFolders(folders);
        state.addToFolderChain(folder);
      },
      async goUpFolder(folder) {
        const {
          data: { folders },
        } = await state.fetchFolders(`${folder.fullPath}/..`);

        state.setFolders(folders);
        state.removeFromFolderChain(folder);
      },
      folderChain: [],
      addToFolderChain(folder) {
        state.folderChain.push(folder);
      },
      removeFromFolderChain(folder) {
        const index = state.folderChain.findIndex(
          (f) => f.fullPath === folder.fullPath,
        );
        if (index >= 0) {
          state.folderChain.splice(index, 1);
        }
      },
      async handleFolderChainClick(folder) {
        const {
          data: { folders },
        } = await state.fetchFolders(folder.fullPath);

        const index = state.folderChain.findIndex(
          (f) => f.fullPath === folder.fullPath,
        );
        runInAction(() => {
          state.folderChain.splice(index + 1);
          state.setFolders(folders);
        });
      },
    }));

    useEffect(() => {
      const fetchFolders = async () => {
        const {
          data: { folders },
        } = await state.fetchFolders('/');
        state.setFolders(folders);
      };
      fetchFolders();
    }, []);

    return (
      <FolderBrowser
        statusColor={statusColor}
        value={value}
        folders={state.filteredFolders}
        folderChain={state.folderChain}
        onSave={onChange}
        onFolderChange={state.goIntoFolder}
        onGoUp={state.goUpFolder}
        onFolderChainClick={state.handleFolderChainClick}
        error={error}
        isLoading={state.isFetching || isLoading}
      />
    );
  },
);
